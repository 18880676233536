import { call, put, select, takeEvery, takeLatest } from 'redux-saga/effects';
import {
  proceedFamilyInfoError,
  proceedFamilyInfoSuccess,
  setFamilyInfoData,
  setIsInitCompleted,
} from './action';
import {
  DELETE_FAMILYINFO_REQUEST,
  GET_FAMILYINFO_REQUEST,
  SAVE_FAMILYINFO_REQUEST,
} from './actionTypes';
import { callRequestWithToken, unauthorizedHandler } from '@utils/request';
import {
  deleteFamilyInfoUrl,
  getFamilyInfoUrl,
  saveFamilyInfoUrl,
} from '@utils/apis';
import { BOOLEAN_FLAG } from '@utils/constants';
import { makeSelectRecruitmentProcessIds } from '@redux/fscApplication/eobCommonInfo/selectors';
import { makeSelectIsInitCompleted } from './selectors';

export function* getFamilyInfoAction({ data }) {
  try {
    const params = {
      userId: data.prospectId,
      recruitmentProcessId: data.id,
    };

    const res = yield callRequestWithToken({
      method: 'post',
      url: getFamilyInfoUrl,
      params,
    });

    if (res.data.success.status) {
      let count = 0;
      res?.data?.data?.personalInfoFamilyInfo?.personalInfoFamilyInfoItem?.map(
        (item) => {
          item.saveCount = 0;
          item.isDisabled = BOOLEAN_FLAG.Y;
          if (item.isAddEmergencyContact === BOOLEAN_FLAG.Y) {
            item.isDisabled = BOOLEAN_FLAG.N;
            count++;
          }
        }
      );

      if (count === 0) {
        res?.data?.data?.personalInfoFamilyInfo?.personalInfoFamilyInfoItem?.map(
          (item) => {
            item.isDisabled = BOOLEAN_FLAG.N;
          }
        );
      }

      yield put(
        setFamilyInfoData(
          res?.data?.data?.personalInfoFamilyInfo?.personalInfoFamilyInfoItem ??
            {}
        )
      );
      const isInitCompleted = yield select(makeSelectIsInitCompleted());
      if (!isInitCompleted) {
        yield put(setIsInitCompleted(true));
      }
      yield put(proceedFamilyInfoSuccess());
    }
  } catch (err) {
    const { response } = err;
    yield call(unauthorizedHandler, response);
    yield put(proceedFamilyInfoError(err.response?.data?.success?.message));
  }
}

export function* saveFamilyInfoAction({ data, index, setIsLoading }) {
  try {
    const recruitmentProcessIds = yield select(
      makeSelectRecruitmentProcessIds()
    );
    let params = {
      userId: recruitmentProcessIds.get('prospectId'),
      recruitmentProcessId: recruitmentProcessIds.get('id'),
      personalInfoFamilyInfoItem: data[index],
    };

    yield put(setFamilyInfoData(data));

    // if there is no id, need loading immediately
    if (!data[index].id) {
      setIsLoading && setIsLoading({ isImmediate: true, isLoading: true });
    } else {
      setIsLoading && setIsLoading(true);
    }

    const res = yield callRequestWithToken({
      method: 'post',
      url: saveFamilyInfoUrl,
      params,
    });

    if (res.data.success.status) {
      if (!data[index].id) {
        data[index].id = res.data.data.personalInfoFamilyInfoItemId;
        yield put(setFamilyInfoData(data));
        setIsLoading && setIsLoading(false);
      } else {
        setIsLoading && setIsLoading(false);
        if (data[index].id != res.data.data.personalInfoFamilyInfoItemId) {
          yield put(
            proceedFamilyInfoError(
              'duplicate record in DB for the record in index ' + index
            )
          );
        }
      }
    } else {
      setIsLoading && setIsLoading(false);
      yield put(proceedFamilyInfoError(res.data?.success?.message));
    }
  } catch (err) {
    const { response } = err;
    yield call(unauthorizedHandler, response);
    setIsLoading && setIsLoading(false);
    yield put(proceedFamilyInfoError(err.response?.data?.success?.message));
  }
}

export function* deleteFamilyInfoAction({ data, index, setIsLoading }) {
  try {
    const recruitmentProcessIds = yield select(
      makeSelectRecruitmentProcessIds()
    );

    const params = {
      userId: recruitmentProcessIds.get('prospectId'),
      recruitmentProcessId: recruitmentProcessIds.get('id'),
      personalInfoFamilyInfoItem: data[index],
    };
    setIsLoading && setIsLoading(true);
    const res = yield callRequestWithToken({
      method: 'post',
      url: deleteFamilyInfoUrl,
      params,
    });
    if (res.data?.success?.status) {
      setIsLoading && setIsLoading(false);
      if (data[index]) {
        data.splice(index, 1);
        yield put(setFamilyInfoData(data));
      }
      yield put(proceedFamilyInfoSuccess());
    }
  } catch (err) {
    const { response } = err;
    yield call(unauthorizedHandler, response);
    setIsLoading && setIsLoading(false);
    yield put(proceedFamilyInfoError(err.response?.data?.success?.message));
  }
}

export default function* rootSaga() {
  yield takeLatest(GET_FAMILYINFO_REQUEST, getFamilyInfoAction);
  yield takeEvery(SAVE_FAMILYINFO_REQUEST, saveFamilyInfoAction);
  yield takeLatest(DELETE_FAMILYINFO_REQUEST, deleteFamilyInfoAction);
}
