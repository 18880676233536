import { call, put, takeLatest } from 'redux-saga/effects';

import request from '@utils/request';
import {
  validateConsentDataSuccess,
  validateConsentDataError,
  sendConsentDataSuccess,
  sendConsentDataError,
} from './actions';
import {
  VALIDATE_CONSENT_DATA_REQUEST,
  SEND_CONSENT_DATA_REQUEST,
} from './actionType';

export function* validateConsentData({ i }) {
  try {
    const token = i;
    if (token) {
      const config = {
        headers: { 'X-Request-ID': token },
      };
      const consent = yield call(
        request,
        '/candidate/pdpa-consent',
        'get',
        null,
        token,
        config
      );
      const {
        data: { success, data },
      } = consent;
      const { status } = success;
      const response = data || {};
      if (status) {
        if (!data) {
          Object.assign(response, { approved: true });
        }
        yield put(validateConsentDataSuccess(response));
      } else {
        yield put(
          validateConsentDataError('Prospect is not invited, unable to proceed')
        );
      }
    } else {
      yield put(validateConsentDataError('Token not provided'));
    }
  } catch (err) {
    yield put(
      validateConsentDataError('Internal error, please try again later')
    );
  }
}

export function* sendConsentResponse(body) {
  try {
    const {
      body: { token },
    } = body;
    const {
      body: { response },
    } = body;
    if (token) {
      const config = {
        headers: { 'X-Request-ID': token },
      };
      const url =
        response === 'Yes'
          ? '/candidate/pdpa-consent/approve'
          : '/candidate/pdpa-consent/reject';
      const consent = yield call(request, url, 'post', null, token, config);
      const {
        data: { success },
      } = consent;
      const { status } = success;
      if (status) {
        yield put(sendConsentDataSuccess(status));
      } else {
        yield put(
          sendConsentDataError('Prospect is not invited, unable to proceed')
        );
      }
    } else {
      yield put(sendConsentDataError('Token not provided'));
    }
  } catch (err) {
    yield put(sendConsentDataError('Internal error, please try again later'));
  }
}

export default function* rootSaga() {
  yield takeLatest(VALIDATE_CONSENT_DATA_REQUEST, validateConsentData);
  yield takeLatest(SEND_CONSENT_DATA_REQUEST, sendConsentResponse);
}
