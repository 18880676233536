import {
  GET_CANDIDATE_REVIEW_REQUEST,
  GET_CANDIDATE_REVIEW_SUCCESS,
  GET_CANDIDATE_REVIEW_ERROR,
  SET_CANDITATE_REVIEW_DATA,
  SUBMIT_CANDIDATE_REVIEW_REQUEST,
  SUBMIT_CANDIDATE_REVIEW_SUCCESS,
  SUBMIT_CANDIDATE_REVIEW_ERROR,
  GET_FILE_REQUEST,
  GET_FILE_SUCCESS,
  GET_FILE_ERROR,
  SET_FILE_DATA,
  SAVE_CANDIDATE_REVIEW,
  SAVE_CANDIDATE_REVIEW_SUCCESS,
  SAVE_CANDIDATE_REVIEW_ERROR,
  SET_SAVE_CANDITATE_REVIEW_DATA,
  GET_ALL_COMMENTS_REQUEST,
  GET_ALL_COMMENTS_SUCCESS,
  GET_ALL_COMMENTS_ERROR,
  SET_ALL_COMMENTS_DATA,
  GET_COMMENTS_LIST_BY_IDS_REQUEST,
  GET_COMMENTS_LIST_BY_IDS_SUCCESS,
  GET_COMMENTS_LIST_BY_IDS_ERROR,
  SET_COMMENTS_LIST_BY_IDS_DATA,
  GET_REVIEW_CARD_INFOR_REQUEST,
  GET_REVIEW_CARD_INFOR_SUCCESS,
  GET_REVIEW_CARD_INFOR_ERROR,
  SET_REVIEW_CARD_INFOR_DATA,
  SET_INITIAL_STATE,
  SUBMIT_WITHDRAW_REQUEST,
  SUBMIT_WITHDRAW_SUCCESS,
  SUBMIT_WITHDRAW_ERROR,
} from './actionTypes';

export const setInitialState = (data) => ({
  type: SET_INITIAL_STATE,
  data,
});

export const getCanditateReviewRequest = (data) => ({
  type: GET_CANDIDATE_REVIEW_REQUEST,
  data,
});

export const getCanditateReviewSuccess = (data) => ({
  type: GET_CANDIDATE_REVIEW_SUCCESS,
  data,
});
export const getCanditateReviewError = (message) => ({
  type: GET_CANDIDATE_REVIEW_ERROR,
  message,
});

export const setCanditateReviewDate = (value) => ({
  type: SET_CANDITATE_REVIEW_DATA,
  value,
});

export const submitCanditateReviewRequest = (data) => ({
  type: SUBMIT_CANDIDATE_REVIEW_REQUEST,
  data,
});

export const submitCanditateReviewSuccess = (data) => ({
  type: SUBMIT_CANDIDATE_REVIEW_SUCCESS,
  data,
});
export const submitCanditateReviewError = (message) => ({
  type: SUBMIT_CANDIDATE_REVIEW_ERROR,
  message,
});

export const getFileRequest = (data) => ({
  type: GET_FILE_REQUEST,
  data,
});

export const getFileSuccess = (data) => ({
  type: GET_FILE_SUCCESS,
  data,
});
export const getFileError = (message) => ({
  type: GET_FILE_ERROR,
  message,
});

export const setFileDate = (value) => ({
  type: SET_FILE_DATA,
  value,
});

export const saveCanditateReview = (data) => ({
  type: SAVE_CANDIDATE_REVIEW,
  data,
});

export const saveCanditateReviewSuccess = (data) => ({
  type: SAVE_CANDIDATE_REVIEW_SUCCESS,
  data,
});
export const saveCanditateReviewError = (message) => ({
  type: SAVE_CANDIDATE_REVIEW_ERROR,
  message,
});
export const setSaveCanditateReviewDate = (value) => ({
  type: SET_SAVE_CANDITATE_REVIEW_DATA,
  value,
});
//Comments
export const getAllCommentsRequest = (data) => ({
  type: GET_ALL_COMMENTS_REQUEST,
  data,
});

export const getAllCommentsSuccess = (data) => ({
  type: GET_ALL_COMMENTS_SUCCESS,
  data,
});
export const getAllCommentsError = (message) => ({
  type: GET_ALL_COMMENTS_ERROR,
  message,
});

export const setAllCommentsDate = (value) => ({
  type: SET_ALL_COMMENTS_DATA,
  value,
});

//get comments by ids
export const getCommentsListByIdsRequest = (data) => ({
  type: GET_COMMENTS_LIST_BY_IDS_REQUEST,
  data,
});

export const getCommentsListByIdsSuccess = (data) => ({
  type: GET_COMMENTS_LIST_BY_IDS_SUCCESS,
  data,
});
export const getCommentsListByIdsError = (message) => ({
  type: GET_COMMENTS_LIST_BY_IDS_ERROR,
  message,
});

export const setCommentsListByIdsData = (value) => ({
  type: SET_COMMENTS_LIST_BY_IDS_DATA,
  value,
});

//getReviewCardInfo
export const getReviewCardInfoRequest = (data) => ({
  type: GET_REVIEW_CARD_INFOR_REQUEST,
  data,
});

export const getReviewCardInfoSuccess = (data) => ({
  type: GET_REVIEW_CARD_INFOR_SUCCESS,
  data,
});
export const getReviewCardInfoError = (message) => ({
  type: GET_REVIEW_CARD_INFOR_ERROR,
  message,
});

export const setReviewCardInfoData = (value) => ({
  type: SET_REVIEW_CARD_INFOR_DATA,
  value,
});
// submit withdraw

export const submitWithdrawRequest = (data, callback) => ({
  type: SUBMIT_WITHDRAW_REQUEST,
  data,
  callback,
});

export const submitWithdrawSuccess = (data) => ({
  type: SUBMIT_WITHDRAW_SUCCESS,
  data,
});
export const submitWithdrawError = (message) => ({
  type: SUBMIT_WITHDRAW_ERROR,
  message,
});
