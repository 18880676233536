export const SET_INITIAL_STATE = 'irecruit/FitCriteria/SET_INITIAL_STATE';
export const GET_FITPROPER_REQUEST =
  'irecruit/FitCriteria/GET_FITPROPER_REQUEST';
export const GET_FITPROPER_SUCCESS =
  'irecruit/FitCriteria/GET_FITPROPER_SUCCESS';
export const GET_FITPROPER_ERROR = 'irecruit/FitCriteria/GET_FITPROPER_ERROR';

export const SAVE_FITPROPER_REQUEST =
  'irecruit/FitCriteria/SAVE_FITPROPER_REQUEST';
export const SAVE_FITPROPER_ERROR = 'irecruit/FitCriteria/SAVE_FITPROPER_ERROR';
export const SAVE_FITPROPER_SUCCESS =
  'irecruit/FitCriteria/SAVE_FITPROPER_SUCCESS';

export const SET_FITPROPER_DATA = 'irecruit/FitCriteria/SET_FITPROPER_DATA';
export const SET_SAVE_FITPROPER_DATA =
  'irecruit/FitCriteria/SET_SAVE_FITPROPER_DATA';
