import { fromJS } from 'immutable';
import {
  GET_FAMILYINFO_REQUEST,
  SET_FAMILYINFO_DATA,
  SAVE_FAMILYINFO_REQUEST,
  PROCEED_FAMILYINFO_SUCCESS,
  PROCEED_FAMILYINFO_ERROR,
  DELETE_FAMILYINFO_REQUEST,
  UPDATE_FAMILYINFO_REQUEST,
  SET_INITIAL_STATE,
  SET_IS_INIT_COMPLETED,
} from './actionTypes';

const initialState = fromJS({
  familyInfoData: [],
  isInitCompleted: false,
  success: false,
  loading: false,
  error: false,
  isSubmitting: false,
  isSubmitted: false,
  message: null,
});

export default function (state = initialState, action) {
  switch (action.type) {
    case SET_INITIAL_STATE:
      return initialState;
    case SET_IS_INIT_COMPLETED:
      return state.set('isInitCompleted', action.data);
    case SET_FAMILYINFO_DATA:
      return state.set('familyInfoData', fromJS(action.data));
    case GET_FAMILYINFO_REQUEST:
      return state
        .set('loading', true)
        .set('success', false)
        .set('error', false)
        .set('message', null);
    case SAVE_FAMILYINFO_REQUEST:
      return state
        .set('loading', false)
        .set('success', false)
        .set('error', false)
        .set('isSubmitting', true)
        .set('message', null);
    case PROCEED_FAMILYINFO_SUCCESS:
      return state
        .set('loading', false)
        .set('success', true)
        .set('isSubmitting', false)
        .set('isSubmitted', true)
        .set('message', action.message);
    case PROCEED_FAMILYINFO_ERROR:
      return state
        .set('loading', false)
        .set('error', true)
        .set('isSubmitting', false)
        .set('message', action.message);
    case DELETE_FAMILYINFO_REQUEST:
      return state
        .set('loading', true)
        .set('success', false)
        .set('error', false)
        .set('message', null);

    case UPDATE_FAMILYINFO_REQUEST:
      return state
        .set('loading', true)
        .set('success', false)
        .set('error', false)
        .set('message', null);

    default:
      return state;
  }
}
