import {
  SET_USER_DATA,
  RESET_STATE,
  AFTER_LOGIN_REQUEST,
  AFTER_LOGIN_SUCCESS,
  AFTER_LOGIN_ERROR,
  GET_MAINTENANCE_ALERT_REQUEST,
  GET_MAINTENANCE_ALERT_SUCCESS,
  GET_MAINTENANCE_ALERT_ERROR,
  GET_MAINTENANCE_STATUS,
  SET_UNAUTHORIZED,
  SET_TOKEN,
} from './actionTypes';

export const setUserData = (value) => ({
  type: SET_USER_DATA,
  value,
});

export const setToken = (value) => ({
  type: SET_TOKEN,
  value,
});

export const resetState = () => ({
  type: RESET_STATE,
});

export const afterLoginRequest = () => ({
  type: AFTER_LOGIN_REQUEST,
});

export const afterLoginSuccess = (message) => ({
  type: AFTER_LOGIN_SUCCESS,
  message,
});

export const afterLoginError = (error) => ({
  type: AFTER_LOGIN_ERROR,
  error,
});

export const getMaintenanceAlertRequest = () => ({
  type: GET_MAINTENANCE_ALERT_REQUEST,
});

export const getMaintenanceAlertSuccess = (data) => ({
  type: GET_MAINTENANCE_ALERT_SUCCESS,
  data,
});

export const getMaintenanceAlertError = (error) => ({
  type: GET_MAINTENANCE_ALERT_ERROR,
  error,
});

export const getMaintenanceStatus = (status) => ({
  type: GET_MAINTENANCE_STATUS,
  status,
});

export const setUnauthorized = () => ({
  type: SET_UNAUTHORIZED,
});
