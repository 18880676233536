import { fromJS } from 'immutable';
import {
  GET_INTERVIEW_SLOT_DETAIL_REQUEST,
  GET_INTERVIEW_SLOT_DETAILL_SUCCESS,
  GET_INTERVIEW_SLOT_DETAIL_ERROR,
  SUBMIT_INTERVIEW_SLOT_REQUEST,
  SUBMIT_INTERVIEW_SLOT_SUCCESS,
  SUBMIT_INTERVIEW_SLOT_ERROR,
  SET_INITIAL_STATE,
  SET_INTERVIEW_SLOT_DATA,
  SET_INTERVIEW_STATUS_DATA,
} from './actionTypes';

const initState = fromJS({
  loading: false,
  error: false,
  success: false,
  message: null,
  interviewSlotDetailData: {},
  interviewStatus: {},
  rsvpSubmitting: false,
});

export default function (state = initState, action) {
  const { type } = action;
  switch (type) {
    case SET_INITIAL_STATE:
      return initState;
    case SET_INTERVIEW_STATUS_DATA:
      return state.set('interviewStatus', fromJS(action.value));
    case GET_INTERVIEW_SLOT_DETAIL_REQUEST: {
      return state
        .set('loading', true)
        .set('error', false)
        .set('success', false)
        .set('message', null)
        .set('interviewSlotDetailData', fromJS({}));
    }
    case GET_INTERVIEW_SLOT_DETAILL_SUCCESS: {
      return state
        .set('loading', false)
        .set('success', true)
        .set('interviewSlotDetailData', fromJS(action.data));
    }
    case GET_INTERVIEW_SLOT_DETAIL_ERROR: {
      return state
        .set('loading', false)
        .set('error', true)
        .set('message', action.message)
        .set('interviewSlotDetailData', fromJS({}));
    }
    case SET_INTERVIEW_SLOT_DATA:
      return state.set('interviewSlotDetailData', fromJS(action.value));

    case SUBMIT_INTERVIEW_SLOT_REQUEST:
      return state
        .set('loading', true)
        .set('success', false)
        .set('error', false)
        .set('message', null)
        .set('rsvpSubmitting', true);
    case SUBMIT_INTERVIEW_SLOT_SUCCESS:
      return state
        .set('loading', false)
        .set('success', true)
        .set('message', null)
        .set('rsvpSubmitting', false);
    case SUBMIT_INTERVIEW_SLOT_ERROR:
      return state
        .set('loading', false)
        .set('error', true)
        .set('message', action.message)
        .set('rsvpSubmitting', false);
    default:
      return state;
  }
}
