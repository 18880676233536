import {
  SET_EDUCATIONBACKGROUND_DATA,
  GET_EDUCATIONBACKGROUND_REQUEST,
  SAVE_EDUCATIONBACKGROUND_REQUEST,
  PROCEED_EDUCATIONBACKGROUND_SUCCESS,
  PROCEED_EDUCATIONBACKGROUND_ERROR,
  DELETE_EDUCATIONBACKGROUND_REQUEST,
  UPDATE_EDUCATIONBACKGROUND_REQUEST,
  SET_EVALUATION_DATA,
  SET_IS_INIT_COMPLETED,
  SET_INITIAL_STATE,
} from './actionTypes';

export const setInitialState = (data) => ({
  type: SET_INITIAL_STATE,
  data,
});

export const setIsInitCompleted = (data) => ({
  type: SET_IS_INIT_COMPLETED,
  data,
});

export const setEducationBackgroundData = (data) => ({
  type: SET_EDUCATIONBACKGROUND_DATA,
  data,
});

export const setEvaluationData = (data) => ({
  type: SET_EVALUATION_DATA,
  data,
});

export const getEducationBackgroundDataRequest = (data) => ({
  type: GET_EDUCATIONBACKGROUND_REQUEST,
  data,
});

export const saveEducationBackgroundDataRequest = (
  data,
  index,
  setIsLoading
) => ({
  type: SAVE_EDUCATIONBACKGROUND_REQUEST,
  data,
  index,
  setIsLoading,
});

export const proceedEducationBackgroundSuccess = (message) => ({
  type: PROCEED_EDUCATIONBACKGROUND_SUCCESS,
  message,
});

export const proceedEducationBackgroundError = (message) => ({
  type: PROCEED_EDUCATIONBACKGROUND_ERROR,
  message,
});

export const deleteEducationBackgroundRequest = (
  data,
  index,
  setIsLoading
) => ({
  type: DELETE_EDUCATIONBACKGROUND_REQUEST,
  data,
  index,
  setIsLoading,
});

export const updateInvitationDataRequest = (data) => ({
  type: UPDATE_EDUCATIONBACKGROUND_REQUEST,
  data,
});
