import { fromJS } from 'immutable';
import {
  GET_CANDIDATE_REVIEW_REQUEST,
  GET_CANDIDATE_REVIEW_SUCCESS,
  GET_CANDIDATE_REVIEW_ERROR,
  SET_CANDITATE_REVIEW_DATA,
  SUBMIT_CANDIDATE_REVIEW_REQUEST,
  SUBMIT_CANDIDATE_REVIEW_SUCCESS,
  SUBMIT_CANDIDATE_REVIEW_ERROR,
  GET_FILE_REQUEST,
  GET_FILE_SUCCESS,
  GET_FILE_ERROR,
  SET_FILE_DATA,
  SAVE_CANDIDATE_REVIEW,
  SAVE_CANDIDATE_REVIEW_SUCCESS,
  SAVE_CANDIDATE_REVIEW_ERROR,
  SET_SAVE_CANDITATE_REVIEW_DATA,
  GET_ALL_COMMENTS_REQUEST,
  GET_ALL_COMMENTS_SUCCESS,
  GET_ALL_COMMENTS_ERROR,
  SET_ALL_COMMENTS_DATA,
  GET_COMMENTS_LIST_BY_IDS_REQUEST,
  GET_COMMENTS_LIST_BY_IDS_SUCCESS,
  GET_COMMENTS_LIST_BY_IDS_ERROR,
  SET_COMMENTS_LIST_BY_IDS_DATA,
  GET_REVIEW_CARD_INFOR_REQUEST,
  GET_REVIEW_CARD_INFOR_SUCCESS,
  GET_REVIEW_CARD_INFOR_ERROR,
  SET_REVIEW_CARD_INFOR_DATA,
  SET_INITIAL_STATE,
  SUBMIT_WITHDRAW_REQUEST,
  SUBMIT_WITHDRAW_SUCCESS,
  SUBMIT_WITHDRAW_ERROR,
} from './actionTypes';

const initialState = fromJS({
  loading: false,
  error: false,
  success: false,
  message: null,
  candidateReviewData: {},
  fileData: {},
  isSubmitting: false,
  isSubmitted: false,
  isSaveing: false,
  isSaved: false,
  agreeResultData: {},
  commentsLoading: false,
  commentsSuccess: false,
  commentsError: false,
  commentsMessage: null,
  commentsData: [],
  byIdsLoading: false,
  byIdsSuccess: false,
  byIdsListData: {},
  cardInfoLoading: false,
  cardInfoSuccess: false,
  cardInfoData: {},
});

export default function (state = initialState, action) {
  switch (action.type) {
    case SET_INITIAL_STATE:
      return initialState;
    case GET_CANDIDATE_REVIEW_REQUEST:
      return state
        .set('loading', true)
        .set('success', false)
        .set('error', false)
        .set('message', null)
        .set('candidateReviewData', fromJS({}));
    case GET_CANDIDATE_REVIEW_SUCCESS:
      return state
        .set('loading', false)
        .set('success', true)
        .set('candidateReviewData', fromJS(action.data));
    case GET_CANDIDATE_REVIEW_ERROR:
      return state
        .set('loading', false)
        .set('error', true)
        .set('message', action.message)
        .set('candidateReviewData', fromJS({}));
    case SET_CANDITATE_REVIEW_DATA:
      return state.set('candidateReviewData', fromJS(action.value));
    case SUBMIT_CANDIDATE_REVIEW_REQUEST:
      return state
        .set('loading', true)
        .set('success', false)
        .set('error', false)
        .set('isSubmitting', true)
        .set('message', null);
    case SUBMIT_CANDIDATE_REVIEW_SUCCESS:
      return state
        .set('loading', false)
        .set('success', true)
        .set('isSubmitting', false)
        .set('isSubmitted', true)
        .set('message', action.message);
    case SUBMIT_CANDIDATE_REVIEW_ERROR:
      return state
        .set('loading', false)
        .set('error', true)
        .set('isSubmitting', false)
        .set('message', action.message);
    case GET_FILE_REQUEST:
      return state
        .set('loading', true)
        .set('success', false)
        .set('error', false)
        .set('message', null)
        .set('fileData', fromJS({}));
    case GET_FILE_SUCCESS:
      return state
        .set('loading', false)
        .set('success', true)
        .set('fileData', fromJS(action.data));
    case SET_FILE_DATA:
      return state.set('fileData', fromJS(action.value));
    case GET_FILE_ERROR:
      return state
        .set('loading', false)
        .set('error', true)
        .set('message', action.message)
        .set('fileData', fromJS({}));
    case SAVE_CANDIDATE_REVIEW:
      return state
        .set('loading', true)
        .set('success', false)
        .set('error', false)
        .set('isSaveing', true)
        .set('isSaved', false)
        .set('agreeResultData', fromJS({}));
    case SAVE_CANDIDATE_REVIEW_SUCCESS:
      return state
        .set('loading', false)
        .set('success', true)
        .set('isSaveing', false)
        .set('isSaved', true)
        .set('agreeResultData', fromJS(action.data));
    case SET_SAVE_CANDITATE_REVIEW_DATA:
      return state.set('agreeResultData', fromJS(action.value));
    case SAVE_CANDIDATE_REVIEW_ERROR:
      return state
        .set('loading', false)
        .set('error', true)
        .set('isSaveing', false)
        .set('message', action.message)
        .set('agreeResultData', fromJS({}));
    //comments
    case GET_ALL_COMMENTS_REQUEST:
      return state
        .set('commentsLoading', true)
        .set('commentsSuccess', false)
        .set('commentsMessage', null)
        .set('commentsData', fromJS([]))
        .set('commentsError', false);
    case GET_ALL_COMMENTS_SUCCESS:
      return state
        .set('commentsLoading', false)
        .set('commentsSuccess', true)
        .set('commentsData', fromJS(action.data));
    case GET_ALL_COMMENTS_ERROR:
      return state
        .set('commentsLoading', false)
        .set('commentsError', true)
        .set('commentsMessage', action.message)
        .set('commentsData', fromJS([]));
    case SET_ALL_COMMENTS_DATA:
      return state.set('commentsData', fromJS(action.value));
    //comments by ids
    case GET_COMMENTS_LIST_BY_IDS_REQUEST:
      return state
        .set('byIdsLoading', true)
        .set('byIdsSuccess', false)
        .set('byIdsListData', fromJS({}));
    case GET_COMMENTS_LIST_BY_IDS_SUCCESS:
      return state
        .set('byIdsLoading', false)
        .set('byIdsSuccess', true)
        .set('byIdsListData', fromJS(action.data));
    case GET_COMMENTS_LIST_BY_IDS_ERROR:
      return state.set('byIdsLoading', false).set('byIdsListData', fromJS({}));
    case SET_COMMENTS_LIST_BY_IDS_DATA:
      return state.set('byIdsListData', fromJS(action.value));
    //card info
    case GET_REVIEW_CARD_INFOR_REQUEST:
      return state
        .set('cardInfoLoading', true)
        .set('cardInfoSuccess', false)
        .set('cardInfoData', fromJS({}));
    case GET_REVIEW_CARD_INFOR_SUCCESS:
      return state
        .set('cardInfoLoading', false)
        .set('cardInfoSuccess', true)
        .set('cardInfoData', fromJS(action.data));
    case GET_REVIEW_CARD_INFOR_ERROR:
      return state
        .set('cardInfoLoading', false)
        .set('cardInfoData', fromJS({}));
    case SET_REVIEW_CARD_INFOR_DATA:
      return state.set('cardInfoData', fromJS(action.value));
    case SUBMIT_WITHDRAW_REQUEST:
      return state
        .set('loading', true)
        .set('success', false)
        .set('error', false)
        .set('message', null);
    case SUBMIT_WITHDRAW_SUCCESS:
      return state
        .set('loading', false)
        .set('success', true)
        .set('message', null);
    case SUBMIT_WITHDRAW_ERROR:
      return state
        .set('loading', false)
        .set('error', true)
        .set('message', action.message);
    default:
      return state;
  }
}
