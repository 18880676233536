import { createSelector } from 'reselect';
import includes from 'lodash/includes';

const selectFamilyInfo = (state) => state.familyInfo;

export const makeSelectFamilyInfoRequest = () =>
  createSelector(selectFamilyInfo, (familyInfoState) =>
    familyInfoState.filter((value, key) =>
      includes(
        [
          'loading',
          'error',
          'success',
          'message',
          'isSubmitting',
          'isSubmitted',
        ],
        key
      )
    )
  );

export const makeSelectFamilyInfoData = () =>
  createSelector(selectFamilyInfo, (familyInfoState) =>
    familyInfoState.get('familyInfoData')
  );
export const makeSelectIsInitCompleted = () =>
  createSelector(selectFamilyInfo, (familyInfoState) =>
    familyInfoState.get('isInitCompleted')
  );
