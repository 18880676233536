import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import RouteTracking from '@utils/appInsights/routeTracking';
import Routes from './routes';

const App = () => {
  return (
    <BrowserRouter>
      <RouteTracking>
        <Routes />
      </RouteTracking>
    </BrowserRouter>
  );
};
export default App;
