import { fromJS } from 'immutable';
import {
  GET_FINANCIAL_INFO_REQUEST,
  GET_FINANCIAL_INFO_SUCCESS,
  GET_FINANCIAL_INFO_ERROR,
  SET_FINANCIAL_INFO_REQUEST,
  SAVE_CHECK_QUESTION_REQUEST,
  SAVE_CHECK_QUESTION_SUCCESS,
  SAVE_CHECK_QUESTION_ERROR,
  DELETE_FINANCIAL_CREDITBUREAUREPORT_REQUEST,
  DELETE_FINANCIAL_CREDITBUREAUREPORT_SUCCESS,
  DELETE_FINANCIAL_CREDITBUREAUREPORT_ERROR,
  DELETE_FINANCIAL_COUNTRYOFTAXRESIDENCE_REQUEST,
  DELETE_FINANCIAL_COUNTRYOFTAXRESIDENCE_SUCCESS,
  DELETE_FINANCIAL_COUNTRYOFTAXRESIDENCE_ERROR,
  GET_ALL_COMMENTS_REQUEST,
  GET_ALL_COMMENTS_SUCCESS,
  GET_ALL_COMMENTS_ERROR,
  SET_ALL_COMMENTS_DATA,

  // add save interface
  SAVE_FINANCIAL_BANK_REQUEST,
  SAVE_FINANCIAL_BANK_SUCCESS,
  SAVE_FINANCIAL_BANK_ERROR,
  // report
  SAVE_FINANCIAL_REPORT_REQUEST,
  SAVE_FINANCIAL_REPORT_SUCCESS,
  SAVE_FINANCIAL_REPORT_ERROR,
  // report credit
  SAVE_FINANCIAL_REPORT_CREDITOR_REQUEST,
  SAVE_FINANCIAL_REPORT_CREDITOR_SUCCESS,
  SAVE_FINANCIAL_REPORT_CREDITOR_ERROR,
  // country residence
  SAVE_FINANCIAL_COUNTRY_RESIDENCE_REQUEST,
  SAVE_FINANCIAL_COUNTRY_RESIDENCE_SUCCESS,
  SAVE_FINANCIAL_COUNTRY_RESIDENCE_ERROR,
  // Fact
  SAVE_FINANCIAL_FACT_REQUEST,
  SAVE_FINANCIAL_FACT_SUCCESS,
  SAVE_FINANCIAL_FACT_ERROR,
  // document
  UPLOAD_DOCUMENTS_REQUEST,
  UPLOAD_DOCUMENTS_SUCCESS,
  UPLOAD_DOCUMENTS_ERROR,
  DELETE_DOCUMENTS_REQUEST,
  DELETE_DOCUMENTS_SUCCESS,
  DELETE_DOCUMENTS_ERROR,
  SET_IS_IMMEDIATE_LOADING,
} from './actionTypes';

const initialState = fromJS({
  financialInfoData: {},
  loading: false,
  error: false,
  isImmediateLoading: false,
  success: false,
  message: null,
  isSubmitting: false,
  isSubmitted: false,
  comments: [],
});

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_FINANCIAL_INFO_REQUEST:
      return state
        .set('loading', true)
        .set('success', false)
        .set('error', false)
        .set('message', null);
    case GET_FINANCIAL_INFO_SUCCESS:
      return state
        .set('loading', false)
        .set('success', true)
        .set('error', false)
        .set('message', null);
    case SET_IS_IMMEDIATE_LOADING:
      return state.set('isImmediateLoading', action.data);
    case GET_FINANCIAL_INFO_ERROR:
      return state
        .set('loading', false)
        .set('error', true)
        .set('isSubmitting', false)
        .set('message', action.message);
    case SET_FINANCIAL_INFO_REQUEST:
      return state
        .set('loading', false)
        .set('success', false)
        .set('error', false)
        .set('message', null)
        .set('financialInfoData', fromJS(action.data));
    case SAVE_CHECK_QUESTION_REQUEST:
      return state
        .set('loading', true)
        .set('success', false)
        .set('error', false)
        .set('isSubmitting', true)
        .set('message', null);
    case SAVE_CHECK_QUESTION_SUCCESS:
      return state
        .set('loading', false)
        .set('success', true)
        .set('isSubmitting', false)
        .set('isSubmitted', true)
        .set('message', action.message);
    case SAVE_CHECK_QUESTION_ERROR:
      return state
        .set('loading', false)
        .set('error', true)
        .set('isSubmitting', false)
        .set('message', action.message);

    case DELETE_FINANCIAL_CREDITBUREAUREPORT_REQUEST:
      return state
        .set('loading', true)
        .set('success', false)
        .set('error', false)
        .set('isSubmitting', true)
        .set('message', null);
    case DELETE_FINANCIAL_CREDITBUREAUREPORT_SUCCESS:
      return state
        .set('loading', false)
        .set('success', true)
        .set('isSubmitting', false)
        .set('isSubmitted', true)
        .set('message', action.message);
    case DELETE_FINANCIAL_CREDITBUREAUREPORT_ERROR:
      return state
        .set('loading', false)
        .set('error', true)
        .set('isSubmitting', false)
        .set('message', action.message);

    case DELETE_FINANCIAL_COUNTRYOFTAXRESIDENCE_REQUEST:
      return state
        .set('loading', true)
        .set('success', false)
        .set('error', false)
        .set('isSubmitting', true)
        .set('message', null);
    case DELETE_FINANCIAL_COUNTRYOFTAXRESIDENCE_SUCCESS:
      return state
        .set('loading', false)
        .set('success', true)
        .set('isSubmitting', false)
        .set('isSubmitted', true)
        .set('message', action.message);
    case DELETE_FINANCIAL_COUNTRYOFTAXRESIDENCE_ERROR:
      return state
        .set('loading', false)
        .set('error', true)
        .set('isSubmitting', false)
        .set('message', action.message);

    case GET_ALL_COMMENTS_REQUEST:
      return state
        .set('loading', true)
        .set('success', false)
        .set('getCommentsSuccess', false)
        .set('error', false)
        .set('isSubmitting', true)
        .set('message', null);
    case SET_ALL_COMMENTS_DATA:
      return state
        .set('loading', false)
        .set('success', false)
        .set('error', false)
        .set('message', null)
        .set('comments', fromJS(action.data));
    case GET_ALL_COMMENTS_SUCCESS:
      return state
        .set('loading', false)
        .set('success', true)
        .set('getCommentsSuccess', true)
        .set('isSubmitting', false)
        .set('isSubmitted', true)
        .set('message', action.message);
    case GET_ALL_COMMENTS_ERROR:
      return state
        .set('loading', false)
        .set('error', true)
        .set('isSubmitting', false)
        .set('message', action.message);

    // save save interface
    case SAVE_FINANCIAL_BANK_REQUEST:
      return state
        .set('loading', true)
        .set('success', false)
        .set('error', false)
        .set('isSubmitting', true)
        .set('message', null);
    case SAVE_FINANCIAL_BANK_SUCCESS:
      return state
        .set('loading', false)
        .set('success', true)
        .set('isSubmitting', false)
        .set('isSubmitted', true)
        .set('message', action.message);
    case SAVE_FINANCIAL_BANK_ERROR:
      return state
        .set('loading', false)
        .set('error', true)
        .set('isSubmitting', false)
        .set('message', action.message);
    // report
    case SAVE_FINANCIAL_REPORT_REQUEST:
      return state
        .set('loading', true)
        .set('success', true)
        .set('error', false)
        .set('isSubmitting', true)
        .set('message', null);
    case SAVE_FINANCIAL_REPORT_SUCCESS:
      return state
        .set('loading', false)
        .set('success', true)
        .set('isSubmitting', false)
        .set('isSubmitted', true)
        .set('message', action.message);
    case SAVE_FINANCIAL_REPORT_ERROR:
      return state
        .set('loading', false)
        .set('error', true)
        .set('isSubmitting', false)
        .set('message', action.message);
    // report creditor
    case SAVE_FINANCIAL_REPORT_CREDITOR_REQUEST:
      return state
        .set('loading', true)
        .set('success', false)
        .set('error', false)
        .set('isSubmitting', true)
        .set('message', null);
    case SAVE_FINANCIAL_REPORT_CREDITOR_SUCCESS:
      return state
        .set('loading', false)
        .set('success', true)
        .set('isSubmitting', false)
        .set('isSubmitted', true)
        .set('message', action.message);
    case SAVE_FINANCIAL_REPORT_CREDITOR_ERROR:
      return state
        .set('loading', false)
        .set('error', true)
        .set('isSubmitting', false)
        .set('message', action.message);
    // country residence
    case SAVE_FINANCIAL_COUNTRY_RESIDENCE_REQUEST:
      return state
        .set('loading', true)
        .set('success', false)
        .set('error', false)
        .set('isSubmitting', true)
        .set('message', null);
    case SAVE_FINANCIAL_COUNTRY_RESIDENCE_SUCCESS:
      return state
        .set('loading', false)
        .set('success', true)
        .set('isSubmitting', false)
        .set('isSubmitted', true)
        .set('message', action.message);
    case SAVE_FINANCIAL_COUNTRY_RESIDENCE_ERROR:
      return state
        .set('loading', false)
        .set('error', true)
        .set('isSubmitting', false)
        .set('message', action.message);
    // Fact
    case SAVE_FINANCIAL_FACT_REQUEST:
      return state
        .set('loading', true)
        .set('success', false)
        .set('error', false)
        .set('isSubmitting', true)
        .set('message', null);
    case SAVE_FINANCIAL_FACT_SUCCESS:
      return state
        .set('loading', false)
        .set('success', true)
        .set('isSubmitting', false)
        .set('isSubmitted', true)
        .set('message', action.message);
    case SAVE_FINANCIAL_FACT_ERROR:
      return state
        .set('loading', false)
        .set('error', true)
        .set('isSubmitting', false)
        .set('message', action.message);
    // document
    case UPLOAD_DOCUMENTS_REQUEST:
      return state
        .set('loading', true)
        .set('success', false)
        .set('error', false)
        .set('isSubmitting', true)
        .set('message', null);
    case UPLOAD_DOCUMENTS_SUCCESS:
      return state
        .set('loading', false)
        .set('success', true)
        .set('isSubmitting', false)
        .set('isSubmitted', true)
        .set('message', action.message);
    case UPLOAD_DOCUMENTS_ERROR:
      return state
        .set('loading', false)
        .set('error', true)
        .set('isSubmitting', false)
        .set('message', action.message);
    case DELETE_DOCUMENTS_REQUEST:
      return state
        .set('loading', true)
        .set('success', false)
        .set('error', false)
        .set('isSubmitting', true)
        .set('message', null);
    case DELETE_DOCUMENTS_SUCCESS:
      return state
        .set('loading', false)
        .set('success', true)
        .set('isSubmitting', false)
        .set('isSubmitted', true)
        .set('message', action.message);
    case DELETE_DOCUMENTS_ERROR:
      return state
        .set('loading', false)
        .set('error', true)
        .set('isSubmitting', false)
        .set('message', action.message);
    default:
      return state;
  }
}
