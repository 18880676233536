import { fromJS } from 'immutable';
import {
  SET_INITIAL_STATE,
  SET_IS_INIT_COMPLETED,
  GET_PERSONAL_FILE_REQUEST,
  UPLOAD_BASIC_FILE_REQUEST,
  DELETE_BASIC_FILE_REQUEST,
  UPLOAD_WORK_ASSESSMENT_FILE_REQUEST,
  DELETE_WORK_ASSESSMENT_FILE_REQUEST,
  DELETE_WORK_ASSESSMENT_FILE_BY_QUESTION_REQUEST,
  SET_BASIC_FILE_DATA,
  SET_ICON_FILE_DATA,
  GET_PERSONAL_COMMENT_REQUEST,
  SET_PERSONAL_COMMENT_DATA,
  PROCEED_PERSONAL_SUCCESS,
  PROCEED_PERSONAL_ERROR,
  SET_WORK_FILE_DATA,
} from './actionTypes';

const initialState = fromJS({
  loading: false,
  success: false,
  error: false,
  message: null,
  isInitCompleted: false,
  iconFileData: {},
  basicFileData: [],
});

export default function (state = initialState, action) {
  switch (action.type) {
    // set/roll back initial data
    case SET_INITIAL_STATE:
      return initialState;
    // get data completed
    case SET_IS_INIT_COMPLETED:
      return state.set('isInitCompleted', action.data);
    // file request
    case GET_PERSONAL_FILE_REQUEST:
      return state
        .set('loading', true)
        .set('success', false)
        .set('error', false)
        .set('message', null);
    case UPLOAD_BASIC_FILE_REQUEST:
      return state
        .set('loading', true)
        .set('success', false)
        .set('error', false)
        .set('message', null);
    case DELETE_BASIC_FILE_REQUEST:
      return state
        .set('loading', true)
        .set('success', false)
        .set('error', false)
        .set('message', null);
    case UPLOAD_WORK_ASSESSMENT_FILE_REQUEST:
      return state
        .set('loading', true)
        .set('success', false)
        .set('error', false)
        .set('message', null);
    case DELETE_WORK_ASSESSMENT_FILE_REQUEST:
      return state
        .set('loading', true)
        .set('success', false)
        .set('error', false)
        .set('message', null);
    case DELETE_WORK_ASSESSMENT_FILE_BY_QUESTION_REQUEST:
      return state
        .set('loading', true)
        .set('success', false)
        .set('error', false)
        .set('message', null);
    // set file data
    case SET_ICON_FILE_DATA:
      return state.set('iconFileData', fromJS(action.data));
    case SET_BASIC_FILE_DATA:
      return state.set('basicFileData', fromJS(action.data));
    case SET_WORK_FILE_DATA:
      return state.set('workFileData', fromJS(action.data));

    // comment request
    case GET_PERSONAL_COMMENT_REQUEST:
      return state
        .set('loading', true)
        .set('success', false)
        .set('error', false)
        .set('message', null);
    // set comment data
    case SET_PERSONAL_COMMENT_DATA:
      return state.set('personalCommentData', fromJS(action.data));
    // success & error
    case PROCEED_PERSONAL_SUCCESS:
      return state
        .set('loading', false)
        .set('success', true)
        .set('error', false)
        .set('message', action.message);
    case PROCEED_PERSONAL_ERROR:
      return state
        .set('loading', false)
        .set('success', false)
        .set('error', true)
        .set('message', action.message);
    default:
      return state;
  }
}
