import { createSelector } from 'reselect';
import includes from 'lodash/includes';

const selectEmergencyContact = (state) => state.emergencyContact;

export const makeSelectEmergencyContactRequest = () =>
  createSelector(selectEmergencyContact, (emergencyContactState) =>
    emergencyContactState.filter((value, key) =>
      includes(
        [
          'loading',
          'error',
          'success',
          'message',
          'isSubmitting',
          'isSubmitted',
        ],
        key
      )
    )
  );

export const makeSelectEmergencyContactData = () =>
  createSelector(selectEmergencyContact, (emergencyContactState) =>
    emergencyContactState.get('emergencyContactData')
  );

export const makeSelectIsInitCompleted = () =>
  createSelector(selectEmergencyContact, (emergencyContactState) =>
    emergencyContactState.get('isInitCompleted')
  );
