import {
  GET_STUDENT_CONVERSION_STATUS_REQUEST,
  GET_STUDENT_CONVERSION_STATUS_SUCCESS,
  GET_STUDENT_CONVERSION_STATUS_ERROR,
  SAVE_DRAFT_STUDENT_CONVERSION_REQUEST,
  SAVE_DRAFT_STUDENT_CONVERSION_SUCCESS,
  SAVE_DRAFT_STUDENT_CONVERSION_ERROR,
  UPLOAD_FILE_STUDENT_CONVERSION_REQUEST,
  UPLOAD_FILE_STUDENT_CONVERSION_SUCCESS,
  UPLOAD_FILE_STUDENT_CONVERSION_ERROR,
  DELETE_FILE_STUDENT_CONVERSION_REQUEST,
  DELETE_FILE_STUDENT_CONVERSION_SUCCESS,
  DELETE_FILE_STUDENT_CONVERSION_ERROR,
  GET_STUDENT_SIGNING_URL_REQUEST,
  GET_STUDENT_SIGNING_URL_SUCCESS,
  GET_STUDENT_SIGNING_URL_ERROR,
} from './actionTypes';

export const getStudentConversionStatusRequest = (data) => ({
  type: GET_STUDENT_CONVERSION_STATUS_REQUEST,
  data,
});

export const getStudentConversionStatusError = (message) => ({
  type: GET_STUDENT_CONVERSION_STATUS_ERROR,
  message,
});

export const getStudentConversionStatusSuccess = (data) => ({
  type: GET_STUDENT_CONVERSION_STATUS_SUCCESS,
  data,
});

export const saveDraftStudentConversionRequest = (data) => ({
  type: SAVE_DRAFT_STUDENT_CONVERSION_REQUEST,
  data,
});

export const saveDraftStudentConversionError = (message) => ({
  type: SAVE_DRAFT_STUDENT_CONVERSION_ERROR,
  message,
});

export const saveDraftStudentConversionSuccess = (data) => ({
  type: SAVE_DRAFT_STUDENT_CONVERSION_SUCCESS,
  data,
});

export const uploadFileStudentConversionRequest = (data) => ({
  type: UPLOAD_FILE_STUDENT_CONVERSION_REQUEST,
  data,
});

export const uploadFileStudentConversionSuccess = (data) => ({
  type: UPLOAD_FILE_STUDENT_CONVERSION_SUCCESS,
  data,
});

export const uploadFileStudentConversionError = (data) => ({
  type: UPLOAD_FILE_STUDENT_CONVERSION_ERROR,
  data,
});

export const deleteFileStudentConversionRequest = (data) => ({
  type: DELETE_FILE_STUDENT_CONVERSION_REQUEST,
  data,
});

export const deleteFileStudentConversionSuccess = (data) => ({
  type: DELETE_FILE_STUDENT_CONVERSION_SUCCESS,
  data,
});

export const deleteFileStudentConversionError = (data) => ({
  type: DELETE_FILE_STUDENT_CONVERSION_ERROR,
  data,
});

export const getStudentSigningUrl = (signType) => ({
  type: GET_STUDENT_SIGNING_URL_REQUEST,
  signType,
});

export const getStudentSigningUrlSuccess = (signUrl) => ({
  type: GET_STUDENT_SIGNING_URL_SUCCESS,
  signUrl,
});

export const getStudentSigningUrlError = (message) => ({
  type: GET_STUDENT_SIGNING_URL_ERROR,
  message,
});
