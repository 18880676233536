import {
  SET_BASICINFO_DATA,
  GET_BASICINFO_REQUEST,
  SAVE_BASICINFO_REQUEST,
  PROCEED_BASICINFO_SUCCESS,
  PROCEED_BASICINFO_ERROR,
  DELETE_BASICINFO_REQUEST,
  UPDATE_BASICINFO_REQUEST,
  GET_MY_INFO_PROFILE_REQUEST,
  GET_MY_INFO_PROFILE_SUCCESS,
  GET_MY_INFO_PROFILE_ERROR,
  SET_MY_INFO_PROFILE_DATA,
  SET_IS_INIT_COMPLETED,
  SET_INITIAL_STATE,
  GET_DATA_LIST_REQUEST,
  SET_BLACK_STATUS,
  SET_AGE_STATUS,
} from './actionTypes';

export const setInitialState = (data) => ({
  type: SET_INITIAL_STATE,
  data,
});

export const setIsInitCompleted = (data) => ({
  type: SET_IS_INIT_COMPLETED,
  data,
});

export const setBasicInfoData = (data) => ({
  type: SET_BASICINFO_DATA,
  data,
});

export const getBasicInfoDataRequest = (data, callback) => ({
  type: GET_BASICINFO_REQUEST,
  data,
  callback,
});

export const saveBasicInfoDataRequest = (
  data,
  fieldName,
  setIsLoading,
  callback
) => ({
  type: SAVE_BASICINFO_REQUEST,
  data,
  fieldName,
  setIsLoading,
  callback,
});

export const proceedBasicInfoSuccess = (message) => ({
  type: PROCEED_BASICINFO_SUCCESS,
  message,
});

export const proceedBasicInfoError = (message) => ({
  type: PROCEED_BASICINFO_ERROR,
  message,
});

export const deleteBasicInfoRequest = (data) => ({
  type: DELETE_BASICINFO_REQUEST,
  data,
});

export const updateInvitationDataRequest = (data) => ({
  type: UPDATE_BASICINFO_REQUEST,
  data,
});

//getMyInfoProfile
export const getMyInfoProfileRequest = (data) => ({
  type: GET_MY_INFO_PROFILE_REQUEST,
  data,
});

export const getMyInfoProfileSuccess = (data) => ({
  type: GET_MY_INFO_PROFILE_SUCCESS,
  data,
});
export const getMyInfoProfileError = (message) => ({
  type: GET_MY_INFO_PROFILE_ERROR,
  message,
});

export const setMyInfoProfileData = (data) => ({
  type: SET_MY_INFO_PROFILE_DATA,
  data,
});

export const getDataListRequest = (data, callback) => ({
  type: GET_DATA_LIST_REQUEST,
  data,
  callback,
});
export const setBlackStatus = (data) => ({
  type: SET_BLACK_STATUS,
  data,
});
export const setAgeStatus = (data) => ({
  type: SET_AGE_STATUS,
  data,
});
