// set/roll back initial data
export const SET_INITIAL_STATE = 'irecruit/personal/SET_INITIAL_STATE';
// get data completed
export const SET_IS_INIT_COMPLETED = 'irecruit/personal/SET_IS_INIT_COMPLETED';
// file request
export const GET_PERSONAL_FILE_REQUEST =
  'irecruit/personal/GET_PERSONAL_FILE_REQUEST';
export const UPLOAD_BASIC_FILE_REQUEST =
  'irecruit/personal/UPLOAD_BASIC_FILE_REQUEST';
export const DELETE_BASIC_FILE_REQUEST =
  'irecruit/personal/DELETE_BASIC_FILE_REQUEST';
export const UPLOAD_WORK_ASSESSMENT_FILE_REQUEST =
  'irecruit/personal/UPLOAD_WORK_ASSESSMENT_FILE_REQUEST';
export const DELETE_WORK_ASSESSMENT_FILE_REQUEST =
  'irecruit/personal/DELETE_WORK_ASSESSMENT_FILE_REQUEST';
export const DELETE_WORK_ASSESSMENT_FILE_BY_QUESTION_REQUEST =
  'irecruit/personal/DELETE_WORK_ASSESSMENT_FILE_BY_QUESTION_REQUEST';

// set file data
export const SET_ICON_FILE_DATA = 'irecruit/personal/SET_ICON_FILE_DATA';
export const SET_BASIC_FILE_DATA = 'irecruit/personal/SET_BASIC_FILE_DATA';
export const SET_WORK_FILE_DATA = 'irecruit/personal/SET_WORK_FILE_DATA';

// comment request
export const GET_PERSONAL_COMMENT_REQUEST =
  'irecruit/personal/GET_PERSONAL_COMMENT_REQUEST';
// set comment data
export const SET_PERSONAL_COMMENT_DATA =
  'irecruit/personal/SET_PERSONAL_COMMENT_DATA';

//success & error
export const PROCEED_PERSONAL_SUCCESS =
  'irecruit/personal/PROCEED_PERSONAL_SUCCESS';
export const PROCEED_PERSONAL_ERROR =
  'irecruit/personal/PROCEED_PERSONAL_ERROR';
