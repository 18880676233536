import {
  GET_EPS_DATA_REQUEST,
  GET_EPS_DATA_SUCCESS,
  GET_EPS_DATA_ERROR,
  SEND_EPS_DATA_REQUEST,
  SEND_EPS_DATA_SUCCESS,
  SEND_EPS_DATA_ERROR,
  UPLOAD_EPS_DOCUMENT_REQUEST,
  UPLOAD_EPS_DOCUMENT_SUCCESS,
  UPLOAD_EPS_DOCUMENT_ERROR,
  SEND_EPS_FOLLOWUP_DOCUMENTS_REQUEST,
  SEND_EPS_FOLLOWUP_DOCUMENTS_REQUEST_SUCCESS,
  SEND_EPS_FOLLOWUP_DOCUMENTS_REQUEST_ERROR,
  SEND_INTERVIEW_DECISION,
  SEND_INTERVIEW_DECISION_SUCCESS,
  SEND_INTERVIEW_DECISION_ERROR,
  GET_CALLIDUS_INFO_REQUEST,
  GET_CALLIDUS_INFO_ERROR,
  GET_CALLIDUS_INFO_SUCCESS,
} from './actionTypes';

export const getEPSData = () => ({
  type: GET_EPS_DATA_REQUEST,
});
export const getEPSDataSuccess = (
  edu,
  countries,
  allowances,
  occupations = [],
  eps = {},
  race,
  nationality
) => ({
  type: GET_EPS_DATA_SUCCESS,
  edu,
  countries,
  allowances,
  occupations,
  eps,
  race,
  nationality,
});
export const getEPSDataError = (error) => ({
  type: GET_EPS_DATA_ERROR,
  error,
});

export const sendEPSData = (data, flag) => ({
  type: SEND_EPS_DATA_REQUEST,
  data,
  flag,
});

export const sendEPSDataSuccess = (message, eps) => ({
  type: SEND_EPS_DATA_SUCCESS,
  message,
  eps,
});

export const sendEPSDataError = (error) => ({
  type: SEND_EPS_DATA_ERROR,
  error,
});

export const uploadDocument = (file) => ({
  type: UPLOAD_EPS_DOCUMENT_REQUEST,
  file,
});

export const uploadEPSDocumentSuccess = (message) => ({
  type: UPLOAD_EPS_DOCUMENT_SUCCESS,
  message,
});

export const uploadEPSDocumentError = (error) => ({
  type: UPLOAD_EPS_DOCUMENT_ERROR,
  error,
});

export const sendEPSFollowUpDocuments = (data) => ({
  type: SEND_EPS_FOLLOWUP_DOCUMENTS_REQUEST,
  data,
});

export const sendEPSFollowUpDocumentsSuccess = (message) => ({
  type: SEND_EPS_FOLLOWUP_DOCUMENTS_REQUEST_SUCCESS,
  message,
});

export const sendEPSFollowUpDocumentsError = (error) => ({
  type: SEND_EPS_FOLLOWUP_DOCUMENTS_REQUEST_ERROR,
  error,
});

export const sendInterviewDecision = (data, flag) => ({
  type: SEND_INTERVIEW_DECISION,
  data,
  flag,
});

export const sendInterviewDecisionSuccess = (message) => ({
  type: SEND_INTERVIEW_DECISION_SUCCESS,
  message,
});

export const sendInterviewDecisionError = (error) => ({
  type: SEND_INTERVIEW_DECISION_ERROR,
  error,
});

export const getCallidusInfoRequest = (prospectId) => ({
  type: GET_CALLIDUS_INFO_REQUEST,
  prospectId,
});

export const getCallidusInfoSuccess = (data) => ({
  type: GET_CALLIDUS_INFO_SUCCESS,
  data,
});

export const getCallidusInfoError = (error) => ({
  type: GET_CALLIDUS_INFO_ERROR,
  error,
});
