import { call, put, takeLatest } from 'redux-saga/effects';
import { callRequestWithToken, unauthorizedHandler } from '@utils/request';
import {
  saveBusinessInterestDeclarationUrl,
  getBusinessInterestDeclarationUrl,
  saveBusinessInterestDeclarationQuestionUrl,
  deleteBusinessInterestDeclarationUrl,
  submitBusinessInterestDeclarationUrl,
} from '@utils/apis';
import {
  GET_BUSINESS_REQUEST,
  SET_BUSINESS_REQUEST,
  SET_BUSINESS_QUESTION_REQUEST,
  DELETE_BUSINESS_REQUEST,
  SUBMIT_BUSINESS_REQUEST,
} from './actionType';
import {
  getBusinessData,
  businessApiError,
  businessApiSuccess,
  // getBusinessRequest,
} from './actions';

export function* getBusiness({ data }) {
  try {
    const res = yield callRequestWithToken({
      method: 'get',
      url: getBusinessInterestDeclarationUrl,
      params: data,
    });
    if (res.data.success.message === 'Success') {
      yield put(getBusinessData(res.data.data));
      yield put(businessApiSuccess('Successfully get Business data'));
    }
  } catch (err) {
    const { response } = err;
    yield call(unauthorizedHandler, response);
    yield put(businessApiError(err.response?.data?.success?.message));
  }
}

export function* setBusiness({ data, callback }) {
  try {
    const res = yield callRequestWithToken({
      method: 'put',
      url: saveBusinessInterestDeclarationUrl,
      params: data,
    });
    if (res.data.success.message === 'Success') {
      yield put(businessApiSuccess('Successfully save Business data'));
      callback && callback({ recruitmentProcessId: data.recruitmentProcessId });
    }
  } catch (err) {
    const { response } = err;
    yield call(unauthorizedHandler, response);
    yield put(businessApiError(err.response?.data?.success?.message));
  }
}

export function* deleteBusiness({ data, callback }) {
  try {
    const res = yield callRequestWithToken({
      method: 'delete',
      url: deleteBusinessInterestDeclarationUrl,
      params: data,
    });
    if (res.data.success.message === 'Success') {
      yield put(businessApiSuccess('Successfully delete Business data'));
      // yield put(
      //   getBusinessRequest({ recruitmentProcessId: data.recruitmentProcessId })
      // );
      callback && callback({ recruitmentProcessId: data.recruitmentProcessId });
    }
  } catch (err) {
    const { response } = err;
    yield call(unauthorizedHandler, response);
    yield put(businessApiError(err.response?.data?.success?.message));
  }
}

export function* setBusinessQuestion({ data }) {
  try {
    const res = yield callRequestWithToken({
      method: 'put',
      url: saveBusinessInterestDeclarationQuestionUrl,
      params: data,
    });
    if (res.data.success.message === 'Success') {
      yield put(businessApiSuccess('Successfully save Business Question data'));
    }
  } catch (err) {
    const { response } = err;
    yield call(unauthorizedHandler, response);
    yield put(businessApiError(err.response?.data?.success?.message));
  }
}
export function* submitBusiness({ data, callback }) {
  try {
    const res = yield callRequestWithToken({
      method: 'PATCH',
      url: submitBusinessInterestDeclarationUrl,
      params: data,
    });
    if (res.data.success.message === 'Success') {
      yield put(businessApiSuccess('Successfully submit Business data'));
      callback && callback();
    }
  } catch (err) {
    const { response } = err;
    yield call(unauthorizedHandler, response);
    yield put(businessApiError(err.response?.data?.success?.message));
  }
}

export default function* rootSaga() {
  yield takeLatest(GET_BUSINESS_REQUEST, getBusiness);
  yield takeLatest(SET_BUSINESS_REQUEST, setBusiness);
  yield takeLatest(SET_BUSINESS_QUESTION_REQUEST, setBusinessQuestion);
  yield takeLatest(DELETE_BUSINESS_REQUEST, deleteBusiness);
  yield takeLatest(SUBMIT_BUSINESS_REQUEST, submitBusiness);
}
