import {
  GET_BUSINESS_REQUEST,
  SET_BUSINESS_REQUEST,
  DELETE_BUSINESS_REQUEST,
  SET_BUSINESS_QUESTION_REQUEST,
  SUCCESS_BUSINESS,
  ERROR_BUSINESS,
  GET_BUSINESS_REQUEST_DATA,
  SUBMIT_BUSINESS_REQUEST,
} from './actionType';

export const getBusinessRequest = (data) => ({
  type: GET_BUSINESS_REQUEST,
  data,
});
export const setBusinessRequest = (data, callback) => ({
  type: SET_BUSINESS_REQUEST,
  data,
  callback,
});
export const setBusinessQuestionRequest = (data) => ({
  type: SET_BUSINESS_QUESTION_REQUEST,
  data,
});
export const submitBusinessRequest = (data, callback) => ({
  type: SUBMIT_BUSINESS_REQUEST,
  data,
  callback,
});
export const deleteBusinessRequest = (data, callback) => ({
  type: DELETE_BUSINESS_REQUEST,
  data,
  callback,
});
export const businessApiSuccess = (consent = {}) => ({
  type: SUCCESS_BUSINESS,
  consent,
});
export const businessApiError = (error = {}) => ({
  type: ERROR_BUSINESS,
  error,
});
export const getBusinessData = (data) => ({
  type: GET_BUSINESS_REQUEST_DATA,
  data,
});
