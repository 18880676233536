import {
  SET_FAMILYINFO_DATA,
  GET_FAMILYINFO_REQUEST,
  SAVE_FAMILYINFO_REQUEST,
  PROCEED_FAMILYINFO_SUCCESS,
  PROCEED_FAMILYINFO_ERROR,
  DELETE_FAMILYINFO_REQUEST,
  UPDATE_FAMILYINFO_REQUEST,
  SET_INITIAL_STATE,
  SET_IS_INIT_COMPLETED,
} from './actionTypes';

export const setInitialState = (data) => ({
  type: SET_INITIAL_STATE,
  data,
});

export const setIsInitCompleted = (data) => ({
  type: SET_IS_INIT_COMPLETED,
  data,
});

export const setFamilyInfoData = (data) => ({
  type: SET_FAMILYINFO_DATA,
  data,
});

export const getFamilyInfoDataRequest = (data) => ({
  type: GET_FAMILYINFO_REQUEST,
  data,
});

export const saveFamilyInfoDataRequest = (data, index, setIsLoading) => ({
  type: SAVE_FAMILYINFO_REQUEST,
  data,
  index,
  setIsLoading,
});

export const proceedFamilyInfoSuccess = (message) => ({
  type: PROCEED_FAMILYINFO_SUCCESS,
  message,
});

export const proceedFamilyInfoError = (message) => ({
  type: PROCEED_FAMILYINFO_ERROR,
  message,
});

export const deleteFamilyInfoRequest = (data, index, setIsLoading) => ({
  type: DELETE_FAMILYINFO_REQUEST,
  data,
  index,
  setIsLoading,
});

export const updateInvitationDataRequest = (data) => ({
  type: UPDATE_FAMILYINFO_REQUEST,
  data,
});
