import {
  SET_EMERGENCY_CONTACT_DATA,
  GET_EMERGENCY_CONTACT_REQUEST,
  SAVE_EMERGENCY_CONTACT_REQUEST,
  PROCEED_EMERGENCY_CONTACT_SUCCESS,
  PROCEED_EMERGENCY_CONTACT_ERROR,
  DELETE_EMERGENCY_CONTACT_REQUEST,
  UPDATE_EMERGENCY_CONTACT_REQUEST,
  SET_IS_INIT_COMPLETED,
  SET_INITIAL_STATE,
} from './actionTypes';

export const setInitialState = (data) => ({
  type: SET_INITIAL_STATE,
  data,
});

export const setIsInitCompleted = (data) => ({
  type: SET_IS_INIT_COMPLETED,
  data,
});

export const setEmergencyContactData = (data) => ({
  type: SET_EMERGENCY_CONTACT_DATA,
  data,
});

export const getEmergencyContactDataRequest = (data) => ({
  type: GET_EMERGENCY_CONTACT_REQUEST,
  data,
});

export const saveEmergencyContactDataRequest = (
  data,
  fieldName,
  setIsLoading
) => ({
  type: SAVE_EMERGENCY_CONTACT_REQUEST,
  data,
  fieldName,
  setIsLoading,
});

export const proceedEmergencyContactSuccess = (message) => ({
  type: PROCEED_EMERGENCY_CONTACT_SUCCESS,
  message,
});

export const proceedEmergencyContactError = (message) => ({
  type: PROCEED_EMERGENCY_CONTACT_ERROR,
  message,
});

export const deleteEmergencyContactRequest = (data) => ({
  type: DELETE_EMERGENCY_CONTACT_REQUEST,
  data,
});

export const updateInvitationDataRequest = (data) => ({
  type: UPDATE_EMERGENCY_CONTACT_REQUEST,
  data,
});
