export const SET_EMPLOYMENT_ASSESSMENT_DATA =
  'irecruit/employmentAssessment/SET_EMPLOYMENT_ASSESSMENT_DATA';
export const SET_IS_INIT_COMPLETED =
  'irecruit/employmentAssessment/SET_IS_INIT_COMPLETED';
export const SET_INITIAL_STATE =
  'irecruit/employmentAssessment/SET_INITIAL_STATE';

export const GET_EMPLOYMENT_ASSESSMENT_REQUEST =
  'irecruit/employmentAssessment/GET_EMPLOYMENT_ASSESSMENT_REQUEST';
export const SAVE_EMPLOYMENT_ASSESSMENT_REQUEST =
  'irecruit/employmentAssessment/SAVE_EMPLOYMENT_ASSESSMENT_REQUEST';
export const PROCEED_EMPLOYMENT_ASSESSMENT_SUCCESS =
  'irecruit/employmentAssessment/ADD_EMPLOYMENT_ASSESSMENT_SUCCESS';
export const PROCEED_EMPLOYMENT_ASSESSMENT_ERROR =
  'irecruit/employmentAssessment/ADD_EMPLOYMENT_ASSESSMENT_ERROR';

export const DELETE_EMPLOYMENT_ASSESSMENT_REQUEST =
  'irecruit/employmentAssessment/DELETE_EMPLOYMENT_ASSESSMENT_REQUEST';

export const UPDATE_EMPLOYMENT_ASSESSMENT_REQUEST =
  'irecruit/employmentAssessment/UPDATE_EMPLOYMENT_ASSESSMENT_REQUEST';
