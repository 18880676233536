import { call, put, takeLatest } from 'redux-saga/effects';
import { callRequestWithToken, unauthorizedHandler } from '@utils/request';
import { uploadFileUrl, deleteFileUrl } from '@utils/apis';
import { DELETE_FILE, UPLOAD_FILE } from './actionType';
import { commonSuccess, commonError } from './actions';
import { formatBytes } from '@utils/helper';

// handler file
export function* uploadDocumentsAction({ data, callback }) {
  const ref = data?.ref;
  const UploadFileParams = data?.UploadFileParams;
  const fileList = data?.fileList;
  try {
    const newFile = {
      id: data.id,
      uploadStatus: 'uploading',
      uploadingText: `${0} / ${formatBytes(UploadFileParams.file.size)}`,
      listFile: fileList,
    };
    ref.current.handleUpdateFile(newFile);
    const config = {
      headers: { 'Content-Type': 'multipart/form-data' },
    };
    const res = yield callRequestWithToken({
      method: 'post',
      url: uploadFileUrl,
      params: UploadFileParams,
      config,
    });
    if (res.status === 201 && res.data.success.status) {
      const resFile = res.data.data.recruitmentProcessFile;
      const fileItem = {
        name: resFile.fileName,
        size: formatBytes(resFile.fileSize * 1),
        id: resFile.id,
      };
      ref.current.handleUpdateFile({
        ...newFile,
        uploadStatus: 'completed',
        uploadingText: `${fileItem.size} / ${fileItem.size}`,
        listFile: [...fileList, fileItem],
      });
      yield put(commonSuccess('upload file Successfully!'));
      callback &&
        callback({
          recruitmentProcessId: data.UploadFileParams.recruitmentProcessId,
        });
    } else {
      yield put(commonError(res.data?.success?.message));
    }
  } catch (err) {
    const { response } = err;
    yield call(unauthorizedHandler, response);
    yield put(commonError(err.response?.data?.success?.message));
  }
}

export function* deleteDocumentsAction({ data, callback }) {
  const ref = data?.ref;
  const file = data?.file;
  try {
    const res = yield callRequestWithToken({
      method: 'post',
      url: deleteFileUrl,
      params: { id: file.id },
    });
    if (res.status === 201 && res.data.success.status) {
      ref.current.handleDeleteFile(file);
      yield put(commonSuccess('delete file Successfully!'));
      callback && callback({ recruitmentProcessId: data.recruitmentProcessId });
    } else {
      yield put(commonError(res.data?.success?.message));
    }
  } catch (err) {
    const { response } = err;
    yield call(unauthorizedHandler, response);
    yield put(commonError(err.response?.data?.success?.message));
  }
}

export default function* rootSaga() {
  yield takeLatest(UPLOAD_FILE, uploadDocumentsAction);
  yield takeLatest(DELETE_FILE, deleteDocumentsAction);
}
