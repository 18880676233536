import React from 'react';
import ReactDOM from 'react-dom';
import { pdfjs } from 'react-pdf';
import { Provider } from 'react-redux';
import { ThemeProvider } from 'styled-components';
import { Helmet } from 'react-helmet';

import GlobalStyle from '@utils/global-styles';
import { theme } from '@utils/constants';
import { appStore } from '@redux/configure-store';
import '@assets/fscApplication/css/fonts.css';

import App from './App';
import '@assets/fscApplication/css/index.scss';
import '@assets/fscApplication/mobile_css/index.scss';

//Student conversion assets
import '@assets/studentConversion/mobile_css/index.scss';

import '@utils/elk-apm';

/**
 * Set PDFjs Worker
 */
pdfjs.GlobalWorkerOptions.workerSrc = '/pdf.worker.min.js';

const environment = process.env.REACT_APP_ENVIRONMENT || 'development';

let adobeScriptSrc;
let digitalDataEnv;
if (environment === 'production') {
  adobeScriptSrc =
    '//assets.adobedtm.com/e17df9099c11/a71a12849473/launch-1542e363e664.min.js';
  digitalDataEnv = 'PROD';
} else if (environment === 'uat') {
  adobeScriptSrc =
    '//assets.adobedtm.com/e17df9099c11/a71a12849473/launch-b309b9dee539-staging.min.js';
  digitalDataEnv = 'UAT';
} else {
  adobeScriptSrc =
    '//assets.adobedtm.com/e17df9099c11/a71a12849473/launch-4a3effb21ddd-development.min.js';
  digitalDataEnv = 'DEV';
}

import '@aia-reactjs-dds/scss/complete.scss';

const root = document.getElementById('root');
ReactDOM.render(
  <React.StrictMode>
    <Provider store={appStore}>
      <Helmet>
        <script>
          {`
                var digitalData = digitalData || {};
                digitalData = {
                  lang: 'en',
                  country: 'sg',
                  env: 'irecruit-web:${digitalDataEnv}',
                  modules: 'irecruit',
                  job: 'candidate',
                }
              `}
        </script>
        <script src='https://code.jquery.com/jquery-3.7.1.min.js'></script>
        <script src='https://aia-dfs.originally.us/web-p3/feedbackSdk.min.js'></script>
        <script src={adobeScriptSrc} async />
      </Helmet>
      <ThemeProvider theme={theme}>
        <App />
        <GlobalStyle />
      </ThemeProvider>
    </Provider>
  </React.StrictMode>,
  root
);
