export const SET_INITIAL_STATE = 'irecruit/review/SET_INITIAL_STATE';

export const GET_CANDIDATE_REVIEW_REQUEST =
  'irecruit/review/GET_CANDIDATE_REVIEW_REQUEST';

export const GET_CANDIDATE_REVIEW_SUCCESS =
  'irecruit/review/PROCEED_CANDIDATE_REVIEW_SUCCESS';

export const GET_CANDIDATE_REVIEW_ERROR =
  'irecruit/review/PROCEED_CANDIDATE_REVIEW_ERROR';

export const SUBMIT_CANDIDATE_REVIEW_REQUEST =
  'irecruit/review/SUBMIT_CANDIDATE_REVIEW_REQUEST';

export const SUBMIT_CANDIDATE_REVIEW_SUCCESS =
  'irecruit/review/SUBMIT_CANDIDATE_REVIEW_SUCCESS';

export const SUBMIT_CANDIDATE_REVIEW_ERROR =
  'irecruit/review/SUBMIT_CANDIDATE_REVIEW_ERROR';

export const SET_CANDITATE_REVIEW_DATA =
  'irecruit/review/SET_CANDITATE_REVIEW_DATA';

export const GET_FILE_REQUEST = 'irecruit/review/GET_FILE_REQUEST';

export const GET_FILE_SUCCESS = 'irecruit/review/GET_FILE_SUCCESS';

export const GET_FILE_ERROR = 'irecruit/review/GET_FILE_ERROR';

export const SET_FILE_DATA = 'irecruit/review/SET_FILE_DATA';

export const SAVE_CANDIDATE_REVIEW = 'irecruit/review/SAVE_CANDIDATE_REVIEW';

export const SAVE_CANDIDATE_REVIEW_SUCCESS =
  'irecruit/review/SAVE_CANDIDATE_REVIEW_SUCCESS';

export const SAVE_CANDIDATE_REVIEW_ERROR =
  'irecruit/review/SAVE_CANDIDATE_REVIEW_ERROR';

export const SET_SAVE_CANDITATE_REVIEW_DATA =
  'irecruit/review/SET_SAVE_CANDITATE_REVIEW_DATA';

export const GET_ALL_COMMENTS_REQUEST =
  'irecruit/review/GET_ALL_COMMENTS_REQUEST';

export const GET_ALL_COMMENTS_SUCCESS =
  'irecruit/review/GET_ALL_COMMENTS_SUCCESS';

export const GET_ALL_COMMENTS_ERROR = 'irecruit/review/GET_ALL_COMMENTS_ERROR';

export const SET_ALL_COMMENTS_DATA = 'irecruit/review/SET_ALL_COMMENTS_DATA';

//comments by ids
export const GET_COMMENTS_LIST_BY_IDS_REQUEST =
  'irecruit/review/GET_COMMENTS_LIST_BY_IDS_REQUEST';

export const GET_COMMENTS_LIST_BY_IDS_SUCCESS =
  'irecruit/review/GET_COMMENTS_LIST_BY_IDS_SUCCESS';

export const GET_COMMENTS_LIST_BY_IDS_ERROR =
  'irecruit/review/GET_COMMENTS_LIST_BY_IDS_ERROR';

export const SET_COMMENTS_LIST_BY_IDS_DATA =
  'irecruit/review/SET_COMMENTS_LIST_BY_IDS_DATA';

//getReviewCardInfo
export const GET_REVIEW_CARD_INFOR_REQUEST =
  'irecruit/review/GET_REVIEW_CARD_INFOR_REQUEST';

export const GET_REVIEW_CARD_INFOR_SUCCESS =
  'irecruit/review/GET_REVIEW_CARD_INFOR_SUCCESS';

export const GET_REVIEW_CARD_INFOR_ERROR =
  'irecruit/review/GET_REVIEW_CARD_INFOR_ERROR';

export const SET_REVIEW_CARD_INFOR_DATA =
  'irecruit/review/SET_REVIEW_CARD_INFOR_DATA';

//  overview withdraw
export const SUBMIT_WITHDRAW_REQUEST =
  'irecruit/review/SUBMIT_WITHDRAW_REQUEST';
export const SUBMIT_WITHDRAW_SUCCESS =
  'irecruit/review/SUBMIT_WITHDRAW_SUCCESS';
export const SUBMIT_WITHDRAW_ERROR = 'irecruit/review/SUBMIT_WITHDRAW_ERROR';
