import {
  SET_EDUCATION_DATA,
  GET_EDUCATION_REQUEST,
  SAVE_EDUCATION_REQUEST,
  PROCEED_EDUCATION_SUCCESS,
  PROCEED_EDUCATION_ERROR,
  UPLOAD_DOCUMENTS_REQUEST,
  DELETE_DOCUMENTS_REQUEST,
} from './actionTypes';

export const setEducationData = (data) => ({
  type: SET_EDUCATION_DATA,
  data,
});

export const getEducationRequest = (data) => ({
  type: GET_EDUCATION_REQUEST,
  data,
});

export const saveEducationRequest = (data, callback) => ({
  type: SAVE_EDUCATION_REQUEST,
  data,
  callback,
});

export const proceedEducationSuccess = (message) => ({
  type: PROCEED_EDUCATION_SUCCESS,
  message,
});

export const proceedEducationError = (message) => ({
  type: PROCEED_EDUCATION_ERROR,
  message,
});

export const uploadDocumentsRequest = (data) => ({
  type: UPLOAD_DOCUMENTS_REQUEST,
  data,
});

export const deleteDocumentsRequest = (data) => ({
  type: DELETE_DOCUMENTS_REQUEST,
  data,
});
