import { call, put, takeLatest } from 'redux-saga/effects';
import {
  GET_STUDENT_CONVERSION_STATUS_REQUEST,
  SAVE_DRAFT_STUDENT_CONVERSION_REQUEST,
  UPLOAD_FILE_STUDENT_CONVERSION_REQUEST,
  DELETE_FILE_STUDENT_CONVERSION_REQUEST,
  GET_STUDENT_SIGNING_URL_REQUEST,
} from './actionTypes';
import {
  getStudentConversionStatusSuccess,
  getStudentConversionStatusError,
  saveDraftStudentConversionSuccess,
  saveDraftStudentConversionError,
  uploadFileStudentConversionSuccess,
  uploadFileStudentConversionError,
  deleteFileStudentConversionSuccess,
  deleteFileStudentConversionError,
  getStudentSigningUrlSuccess,
  getStudentSigningUrlError,
} from './actions';
import {
  callRequestWithToken,
  unauthorizedHandler,
  updateStudentConversionState,
} from '@utils/request';
import {
  getStudentConversionUrl,
  saveDraftStudentConversionUrl,
  uploadFileStudentConversionUrl,
  deleteFileStudentConversionUrl,
} from '@utils/apis';

export function* getStudentConversionStatusAction({ data }) {
  try {
    const params = {
      //epsApplicationId
      id: data,
    };
    const response = yield callRequestWithToken({
      method: 'get',
      url: getStudentConversionUrl,
      params,
    });
    if (response.status === 200) {
      yield put(getStudentConversionStatusSuccess(response.data?.data));
    } else {
      yield put(
        getStudentConversionStatusError('Token not provide to get data')
      );
    }
  } catch (err) {
    const { response } = err;
    yield call(unauthorizedHandler, response);
  }
}

export function* saveDraftStudentConversionAction({ data: combinedData }) {
  try {
    const params = {
      questionId: combinedData?.questionId,
      answer: combinedData?.value,
    };

    const response = yield callRequestWithToken({
      method: 'put',
      url: saveDraftStudentConversionUrl,
      params,
    });

    if (response.status === 200) {
      yield put(
        saveDraftStudentConversionSuccess(response.data?.data?.epsScheme)
      );
      yield call(updateStudentConversionState, response);
    } else {
      yield put(
        saveDraftStudentConversionError('Token not provide to get data')
      );
    }
  } catch (err) {
    const { response } = err;
    yield call(unauthorizedHandler, response);
  }
}

export function* uploadFileStudentConversionAction({ data: combinedData }) {
  try {
    const params = {
      //studentConversionApplicationId
      id: combinedData?.studentConversionApplicationId,
      category: combinedData?.category,
      file: combinedData?.file,
    };

    // need to specify formdata
    const config = {
      headers: { 'Content-Type': 'multipart/form-data' },
    };

    const response = yield callRequestWithToken({
      method: 'post',
      url: uploadFileStudentConversionUrl,
      params,
      config,
    });

    if (response.status === 200) {
      yield put(uploadFileStudentConversionSuccess(response.data?.data));
      yield call(updateStudentConversionState, response);
    } else {
      yield put(
        uploadFileStudentConversionError('Token not provide to get data')
      );
    }
  } catch (err) {
    const { response } = err;
    yield call(unauthorizedHandler, response);
  }
}

export function* deleteFileStudentConversionAction({ data: combinedData }) {
  let fileId = combinedData?.fileId;
  try {
    const params = {
      // fileId
      id: fileId,
    };

    const response = yield callRequestWithToken({
      method: 'delete',
      url: deleteFileStudentConversionUrl,
      params,
    });

    if (response.status === 204) {
      yield put(deleteFileStudentConversionSuccess(fileId));
      yield call(updateStudentConversionState, response);
    } else {
      yield put(
        deleteFileStudentConversionError('Token not provide to get data')
      );
    }
  } catch (err) {
    const { response } = err;
    yield call(unauthorizedHandler, response);
  }
}

export function* getStudentSigningUrlAction({ signType }) {
  try {
    const response = yield callRequestWithToken({
      method: 'get',
      url: `/student-conversion/remote-signing/generate-candidate-link/${signType}`,
      params: null,
    });

    if (response.status === 200) {
      sessionStorage.removeItem('pendingKofax');
      yield put(getStudentSigningUrlSuccess(response.data.data));
    } else {
      sessionStorage.removeItem('pendingKofax');
      yield put(getStudentSigningUrlError('Token not provide to get data'));
    }
  } catch (err) {
    const { response } = err;
    yield call(unauthorizedHandler, response);
    yield put(getStudentSigningUrlError(err.response?.data?.success?.message));
  }
}

export default function* rootSaga() {
  yield takeLatest(
    GET_STUDENT_CONVERSION_STATUS_REQUEST,
    getStudentConversionStatusAction
  );
  yield takeLatest(
    SAVE_DRAFT_STUDENT_CONVERSION_REQUEST,
    saveDraftStudentConversionAction
  );
  yield takeLatest(
    UPLOAD_FILE_STUDENT_CONVERSION_REQUEST,
    uploadFileStudentConversionAction
  );
  yield takeLatest(
    DELETE_FILE_STUDENT_CONVERSION_REQUEST,
    deleteFileStudentConversionAction
  );
  yield takeLatest(GET_STUDENT_SIGNING_URL_REQUEST, getStudentSigningUrlAction);
}
