export const SET_BACKGROUND_DATA = 'irecruit/assessment/SET_BACKGROUND_DATA';

export const GET_BACKGROUND_REQUEST =
  'irecruit/assessment/GET_BACKGROUND_REQUEST';

export const SAVE_BACKGROUND_REQUEST =
  'irecruit/assessment/SAVE_BACKGROUND_REQUEST';

export const PROCEED_BACKGROUND_SUCCESS =
  'irecruit/assessment/PROCEED_BACKGROUND_SUCCESS';
export const PROCEED_BACKGROUND_ERROR =
  'irecruit/assessment/PROCEED_BACKGROUND_ERROR';
