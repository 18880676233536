import { call, put, takeLatest } from 'redux-saga/effects';
import { callRequestWithToken, unauthorizedHandler } from '@utils/request';
import { getFitInformationUrl } from '@utils/apis';

import {
  setFitProperData,
  getFitProperSuccess,
  getFitProperError,
} from './actions';
import { GET_FITPROPER_REQUEST } from './actionType';

export function* getFitProper({ data }) {
  try {
    const res = yield callRequestWithToken({
      method: 'post',
      url: getFitInformationUrl,
      params: data,
    });
    if (res.data.success.message === 'Success') {
      yield put(setFitProperData(res.data.data.fitProperResult));
      yield put(getFitProperSuccess('Successfully get fitProper data'));
    }
  } catch (err) {
    const { response } = err;
    yield call(unauthorizedHandler, response);
    yield put(getFitProperError(err?.response?.data?.success?.message));
  }
}

export default function* rootSaga() {
  yield takeLatest(GET_FITPROPER_REQUEST, getFitProper);
}
