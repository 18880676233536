export const SET_INITIAL_STATE = 'irecruit/fscSignDoc/SET_INITIAL_STATE';

export const SAVE_FSC_SIGN_DOC_DATA =
  'irecruit/fscSignDoc/SAVE_FSC_SIGN_DOC_DATA';

export const GET_FSC_SIGN_DOC_DATA_REQUEST =
  'irecruit/fscSignDoc/GET_FSC_SIGN_DOC_DATA_REQUEST';
export const SAVE_FILE_REVIEW_FLAG_REQUEST =
  'irecruit/fscSignDoc/SAVE_FILE_REVIEW_FLAG_REQUEST';
export const GENERATE_CANDIDATE_SIGN_LINK_REQUEST =
  'irecruit/fscSignDoc/GENERATE_CANDIDATE_SIGN_LINK_REQUEST';
export const SUBMIT_SIGN_REQUEST = 'irecruit/fscSignDoc/SUBMIT_SIGN_REQUEST';

export const GENERATE_CANDIDATE_SIGN_LINK_SUCCESS =
  'irecruit/fscSignDoc/GENERATE_CANDIDATE_SIGN_LINK_SUCCESS';
export const PROCEED_FSC_SIGN_DOC_SUCCESS =
  'irecruit/fscSignDoc/PROCEED_FSC_SIGN_DOC_SUCCESS';
export const SUBMIT_SIGN_SUCCESS = 'irecruit/fscSignDoc/SUBMIT_SIGN_SUCCESS';
export const PROCEED_FSC_SIGN_DOC_ERROR =
  'irecruit/fscSignDoc/PROCEED_FSC_SIGN_DOC_ERROR';
