import { call, put, select, takeLatest } from 'redux-saga/effects';
import {
  setIsInitCompleted,
  setBasicFileData,
  setIconFileData,
  proceedPersonalError,
  proceedPersonalSuccess,
  setWorkFileData,
} from './action';
import {
  GET_PERSONAL_FILE_REQUEST,
  UPLOAD_BASIC_FILE_REQUEST,
  DELETE_BASIC_FILE_REQUEST,
  UPLOAD_WORK_ASSESSMENT_FILE_REQUEST,
  DELETE_WORK_ASSESSMENT_FILE_REQUEST,
  DELETE_WORK_ASSESSMENT_FILE_BY_QUESTION_REQUEST,
} from './actionTypes';
import { deleteFileLisURL, deleteFileUrl, getFileListUrl } from '@utils/apis';
import {
  callRequestWithToken,
  unauthorizedHandler,
  requestDownloadFileWithToken,
  requestUploadFileWithToken,
} from '@utils/request';
import {
  makeSelectIsInitCompleted,
  makeSelectBasicFileData,
  makeSelectWorkFileData,
} from './selectors';
import { formatBytes } from '@utils/helper';
import { PAGE_NAME } from '@utils/constants';
import { cloneDeep } from 'lodash';
import { PERSONAL_INFO_DOCUMENTS } from '@pages/fscApplication/PersonalInformation/business/constants';

export function* getPersonalFileAction({ data }) {
  try {
    const params = {
      recruitmentProcessId: data.id,
      pageName: PAGE_NAME.PERSONAL_INFORMATION.pageKey,
    };
    const res = yield callRequestWithToken({
      method: 'post',
      url: getFileListUrl,
      params,
    });

    if (res.data.success.status) {
      const fileList = res.data.data.fileList;
      fileList.forEach((documentInfo) => {
        documentInfo.name = documentInfo.fileName;
        documentInfo.size = formatBytes(documentInfo.fileSize * 1);
      });

      // filter with section name after file table enhance in backend
      // Photo
      const photoFileList = fileList.filter(
        (item) =>
          item.question === PERSONAL_INFO_DOCUMENTS.BASIC_INFO_SECTION.PHOTO
      );
      if (photoFileList.length > 0) {
        const resPhoto = yield requestDownloadFileWithToken({
          fileId: photoFileList[0].id,
          userId: data.id,
        });
        if (resPhoto.data) {
          const filePath = URL.createObjectURL(resPhoto.data);
          const PhotoFile = {
            file: photoFileList[0],
            document: resPhoto.data,
            photoImg: filePath,
          };
          yield put(setIconFileData(PhotoFile));
        }
      }
      // Basic
      const basicInfoFileList = fileList.filter(
        (item) =>
          item.question ===
            PERSONAL_INFO_DOCUMENTS.BASIC_INFO_SECTION.NATIONALITY ||
          item.question === PERSONAL_INFO_DOCUMENTS.BASIC_INFO_SECTION.MY_INFO
      );
      yield put(setBasicFileData(basicInfoFileList));
      // employmentAssessment
      const workFileList = fileList.filter(
        (item) =>
          item.question ===
            PERSONAL_INFO_DOCUMENTS.WORK_EXPERIENCE_SECTION.ASSESSMENT_1 ||
          item.question ===
            PERSONAL_INFO_DOCUMENTS.WORK_EXPERIENCE_SECTION.ASSESSMENT_2 ||
          item.question ===
            PERSONAL_INFO_DOCUMENTS.WORK_EXPERIENCE_SECTION.ASSESSMENT_3
      );
      yield put(setWorkFileData(workFileList));

      // isInitCompleted
      const isInitCompleted = yield select(makeSelectIsInitCompleted());
      if (!isInitCompleted) {
        yield put(setIsInitCompleted(true));
      }
      yield put(proceedPersonalSuccess());
    } else {
      yield put(proceedPersonalError(res.data?.success?.message));
    }
  } catch (err) {
    const { response } = err;
    yield call(unauthorizedHandler, response);
    yield put(proceedPersonalError(err.response?.data?.success?.message));
  }
}

export function* uploadBasicFileAction({ data, additionalData, callback }) {
  try {
    const res = yield requestUploadFileWithToken(data);
    if (res.data.success.status) {
      const fileInfo = res.data.data.recruitmentProcessFile;
      fileInfo.name = fileInfo.fileName;
      fileInfo.size = formatBytes(fileInfo.fileSize * 1);

      if (data.question === PERSONAL_INFO_DOCUMENTS.BASIC_INFO_SECTION.PHOTO) {
        const photoFile = {
          file: fileInfo,
          document: additionalData,
          photoImg: additionalData,
        };
        yield put(setIconFileData(photoFile));
        yield put(proceedPersonalSuccess());
        callback && callback(true);
      } else if (
        data.question === PERSONAL_INFO_DOCUMENTS.BASIC_INFO_SECTION.NATIONALITY
      ) {
        const basicFileList = yield select(makeSelectBasicFileData());
        const newFileList = cloneDeep(basicFileList.toJS());
        newFileList.push(fileInfo);
        yield put(setBasicFileData(newFileList));
        yield put(proceedPersonalSuccess());
        callback && callback(true, additionalData, newFileList);
      }
    } else {
      yield put(proceedPersonalError(res.data?.success?.message));
      callback && callback(false, additionalData);
    }
  } catch (err) {
    const { response } = err;
    yield call(unauthorizedHandler, response);
    yield put(proceedPersonalError(err.response?.data?.success?.message));
    callback && callback(false, additionalData);
  }
}

export function* deleteBasicFileAction({ data, additionalData, callback }) {
  try {
    const res = yield callRequestWithToken({
      method: 'post',
      url: deleteFileUrl,
      params: data,
    });
    if (res.data.success.status) {
      const basicFileList = yield select(makeSelectBasicFileData());
      let newFileList = cloneDeep(basicFileList.toJS());
      newFileList = newFileList.filter((item) => item.id !== data.id);
      yield put(setBasicFileData(newFileList));
      yield put(proceedPersonalSuccess());
      callback && callback(true, additionalData);
    } else {
      yield put(proceedPersonalError(res.data?.success?.message));
      callback && callback(false, additionalData);
    }
  } catch (err) {
    const { response } = err;
    yield call(unauthorizedHandler, response);
    yield put(proceedPersonalError(err.response?.data?.success?.message));
    callback && callback(false, additionalData);
  }
}
// Employment history assessment
export function* uploadWorkAssessmentFileAction({
  data,
  additionalData,
  callback,
}) {
  try {
    const res = yield requestUploadFileWithToken(data);
    if (res.data.success.status) {
      const fileInfo = res.data.data.recruitmentProcessFile;
      fileInfo.name = fileInfo.fileName;
      fileInfo.size = formatBytes(fileInfo.fileSize * 1);

      const workFileList = yield select(makeSelectWorkFileData());
      const newFileList = cloneDeep(workFileList.toJS());
      newFileList.push(fileInfo);
      yield put(setWorkFileData(newFileList));
      yield put(proceedPersonalSuccess());
      callback && callback(true, additionalData, fileInfo);
    } else {
      yield put(proceedPersonalError(res.data?.success?.message));
      callback && callback(false, additionalData);
    }
  } catch (err) {
    const { response } = err;
    yield call(unauthorizedHandler, response);
    yield put(proceedPersonalError(err.response?.data?.success?.message));
    callback && callback(false, additionalData);
  }
}

export function* deleteWorkAssessmentFileAction({
  data,
  additionalData,
  callback,
}) {
  try {
    const res = yield callRequestWithToken({
      method: 'post',
      url: deleteFileUrl,
      params: data,
    });
    if (res.data.success.status) {
      const workFileList = yield select(makeSelectWorkFileData());
      let newFileList = cloneDeep(workFileList.toJS());
      newFileList = newFileList.filter((item) => item.id !== data.id);
      yield put(setWorkFileData(newFileList));
      yield put(proceedPersonalSuccess());
      callback && callback(true, additionalData);
    } else {
      yield put(proceedPersonalError(res.data?.success?.message));
      callback && callback(false, additionalData);
    }
  } catch (err) {
    const { response } = err;
    yield call(unauthorizedHandler, response);
    yield put(proceedPersonalError(err.response?.data?.success?.message));
    callback && callback(false);
  }
}

export function* deleteWorkAssessmentFileByQuestionAction({ data }) {
  try {
    const res = yield callRequestWithToken({
      method: 'post',
      url: deleteFileLisURL,
      params: data,
    });
    if (res.data.success.status) {
      const workFileList = yield select(makeSelectWorkFileData());
      let newFileList = cloneDeep(workFileList.toJS());
      newFileList = newFileList.filter(
        (item) => item.question !== data.question
      );
      yield put(setWorkFileData(newFileList));
      yield put(proceedPersonalSuccess());
    } else {
      yield put(proceedPersonalError(res.data?.success?.message));
    }
  } catch (err) {
    const { response } = err;
    yield call(unauthorizedHandler, response);
    yield put(proceedPersonalError(err.response?.data?.success?.message));
  }
}

export default function* rootSaga() {
  yield takeLatest(GET_PERSONAL_FILE_REQUEST, getPersonalFileAction);
  yield takeLatest(UPLOAD_BASIC_FILE_REQUEST, uploadBasicFileAction);
  yield takeLatest(DELETE_BASIC_FILE_REQUEST, deleteBasicFileAction);
  yield takeLatest(
    UPLOAD_WORK_ASSESSMENT_FILE_REQUEST,
    uploadWorkAssessmentFileAction
  );
  yield takeLatest(
    DELETE_WORK_ASSESSMENT_FILE_REQUEST,
    deleteWorkAssessmentFileAction
  );
  yield takeLatest(
    DELETE_WORK_ASSESSMENT_FILE_BY_QUESTION_REQUEST,
    deleteWorkAssessmentFileByQuestionAction
  );
}
