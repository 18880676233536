import { fromJS } from 'immutable';
import {
  GET_EMERGENCY_CONTACT_REQUEST,
  SET_EMERGENCY_CONTACT_DATA,
  SAVE_EMERGENCY_CONTACT_REQUEST,
  PROCEED_EMERGENCY_CONTACT_SUCCESS,
  PROCEED_EMERGENCY_CONTACT_ERROR,
  DELETE_EMERGENCY_CONTACT_REQUEST,
  UPDATE_EMERGENCY_CONTACT_REQUEST,
  SET_IS_INIT_COMPLETED,
  SET_INITIAL_STATE,
} from './actionTypes';

const initialState = fromJS({
  emergencyContactData: {},
  isInitCompleted: false,
  success: false,
  loading: false,
  error: false,
  isSubmitting: false,
  isSubmitted: false,
  message: null,
});

export default function (state = initialState, action) {
  switch (action.type) {
    case SET_INITIAL_STATE:
      return initialState;
    case SET_IS_INIT_COMPLETED:
      return state.set('isInitCompleted', action.data);
    case SET_EMERGENCY_CONTACT_DATA:
      return state.set('emergencyContactData', fromJS(action.data));
    case GET_EMERGENCY_CONTACT_REQUEST:
      return state
        .set('loading', true)
        .set('success', false)
        .set('error', false)
        .set('message', null);
    case SAVE_EMERGENCY_CONTACT_REQUEST:
      return state
        .set('loading', false)
        .set('success', false)
        .set('error', false)
        .set('isSubmitting', true)
        .set('message', null);
    case PROCEED_EMERGENCY_CONTACT_SUCCESS:
      return state
        .set('loading', false)
        .set('success', true)
        .set('isSubmitting', false)
        .set('isSubmitted', true)
        .set('message', action.message);
    case PROCEED_EMERGENCY_CONTACT_ERROR:
      return state
        .set('loading', false)
        .set('error', true)
        .set('isSubmitting', false)
        .set('message', action.message);
    case DELETE_EMERGENCY_CONTACT_REQUEST:
      return state
        .set('loading', true)
        .set('success', false)
        .set('error', false)
        .set('message', null);

    case UPDATE_EMERGENCY_CONTACT_REQUEST:
      return state
        .set('loading', true)
        .set('success', false)
        .set('error', false)
        .set('message', null);

    default:
      return state;
  }
}
