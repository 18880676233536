// common
export const uploadFileUrl = {
  method: 'post',
  url: '/recruitment-process/uploadFile',
};
export const downloadFileUrl = {
  method: 'get',
  url: '/recruitment-process/downloadFile/:id',
};
export const deleteFileUrl = {
  method: 'delete',
  url: '/recruitment-process/deleteFile/:id',
};
export const getFileListUrl = {
  method: 'post',
  url: '/recruitment-process/fileList',
};
export const deleteFileLisURL = {
  method: 'delete',
  url: '/recruitment-process/:id/deleteFileListBySection',
};

export const deleteByQuestionListURL = {
  method: 'delete',
  url: '/recruitment-process/:id/deleteFileListByQuestionList',
};

export const getHomeScreenApplicationStatusUrl = {
  method: 'get',
  url: '/recruitment-process/homeScreenApplicationStatus/:prospectId',
};
export const saveHomeScreenApplicationStatusUrl = {
  method: 'put',
  url: '/recruitment-process/:recruitmentProcessId/homeScreenApplicationStatus',
};

export const caseNumberUrl = {
  method: 'post',
  url: '/recruitment-process/:recruitmentProcessId/case-number',
};
// comments
export const getCommentsList = {
  method: 'post',
  url: '/recruitment-process/commentsList',
};
export const getAllComments = {
  method: 'get',
  url: '/recruitment-process/:recruitmentProcessId/allComments',
};

// application status
export const saveStepBarUrl = {
  method: 'put',
  url: '/recruitment-process/stepBar',
};
export const getStepBarUrl = {
  method: 'get',
  url: '/recruitment-process/:recruitmentProcessId/stepBar',
};

// Assessment
// job source
export const getJobSourceUrl = {
  method: 'get',
  url: '/recruitment-process/:recruitmentProcessId/assessment/job-source',
};
export const saveJobSourceUrl = {
  method: 'put',
  url: '/recruitment-process/:recruitmentProcessId/assessment/job-source',
};

// background
export const getBackgroundUrl = {
  method: 'get',
  url: '/recruitment-process/:recruitmentProcessId/assessment/background',
};

export const saveBackgroundUrl = {
  method: 'put',
  url: '/recruitment-process/:recruitmentProcessId/assessment/background',
};

// education
export const getEducationUrl = {
  method: 'get',
  url: '/recruitment-process/:recruitmentProcessId/assessment/education',
};

export const saveEducationUrl = {
  method: 'put',
  url: '/recruitment-process/:recruitmentProcessId/eduToReefApplication',
};

// certification
export const getCertificationUrl = {
  method: 'get',
  url: '/recruitment-process/:recruitmentProcessId/assessment/certification',
};

export const saveCertificationUrl = {
  method: 'put',
  url: '/recruitment-process/:recruitmentProcessId/assessment/certification',
};

//basic information
export const getBasicInfoUrl = {
  method: 'get',
  url: '/recruitment-process/:recruitmentProcessId/personal/basicInfo',
};
export const saveBasicInfoUrl = {
  method: 'put',
  url: '/recruitment-process/:recruitmentProcessId/personal/basicInfo',
};
//family information
export const getFamilyInfoUrl = {
  method: 'get',
  url: '/recruitment-process/:recruitmentProcessId/personal/familyInfo',
};
export const saveFamilyInfoUrl = {
  method: 'put',
  url: '/recruitment-process/:recruitmentProcessId/personal/familyInfo',
};

export const deleteFamilyInfoUrl = {
  method: 'delete',
  url: '/recruitment-process/personal/deletedFamilyInfo/:personalInfoFamilyInfoItemId',
};

export const getEmergencyContactUrl = {
  method: 'get',
  url: '/recruitment-process/:recruitmentProcessId/personal/emergencyContact',
};
export const saveEmergencyContactUrl = {
  method: 'put',
  url: '/recruitment-process/:recruitmentProcessId/personal/emergencyContact',
};

export const queryDataListNricUrl = {
  method: 'post',
  url: '/recruitment-process/personal/query-data-list-nric',
};

//education background
export const getEducationBackgroundUrl = {
  method: 'get',
  url: '/recruitment-process/:recruitmentProcessId/personal/educationBackground',
};
export const saveEducationBackgroundUrl = {
  method: 'put',
  url: '/recruitment-process/:recruitmentProcessId/personal/educationBackground',
};
export const deleteEducationBackgroundUrl = {
  method: 'delete',
  url: '/recruitment-process/:recruitmentProcessId/personal/deletedEducationBackground/:personalInfoEducationBackgroundItemId',
};

//employment assessment
export const getEmploymentAssessmentUrl = {
  method: 'get',
  url: '/recruitment-process/:recruitmentProcessId/personal/workExperience',
};
export const saveEmploymentAssessmentUrl = {
  method: 'put',
  url: '/recruitment-process/:recruitmentProcessId/personal/workExperience',
};
//employment history
export const getEmploymentHistoryUrl = {
  method: 'get',
  url: '/recruitment-process/:recruitmentProcessId/personal/employmentHistory',
};
export const saveEmploymentHistoryUrl = {
  method: 'put',
  url: '/recruitment-process/:recruitmentProcessId/personal/employmentHistory',
};
export const deleteEmploymentHistoryUrl = {
  method: 'delete',
  url: '/recruitment-process/:recruitmentProcessId/personal/deletedEmploymentHistory/:personalInfoEmploymentHistoryItemId',
};

//employment gap
export const saveEmploymentGapUrl = {
  method: 'put',
  url: '/recruitment-process/personal/employmentGap',
};
//get my info profile
export const getCandidateReview = {
  method: 'get',
  url: '/recruitment-process/:recruitmentProcessId/review',
};
//convert sing pass data
export const convertSingPassData = {
  method: 'put',
  url: '/recruitment-process/personal/basicInfo/get-singpass-conversion-data',
};
export const saveCandidateReview = {
  method: 'put',
  url: '/recruitment-process/:recruitmentProcessId/candidateReview/candidateReview',
};

export const submitCandidateReview = {
  method: 'patch',
  url: '/recruitment-process/:recruitmentProcessId/candidateReview/candidateReviewSubmmit',
};

export const getFilePreview = {
  method: 'get',
  url: '/recruitment-process/fileQuestion/filePreview/:fileId',
};
export const getReviewCardInfo = {
  method: 'get',
  url: '/recruitment-process/:recruitmentProcessId/leaderReview/reviewCardInfo',
};

// financial information url
export const getFinancialInformationUrl = {
  method: 'get',
  url: '/recruitment-process/:recruitmentProcessId/financialInfo/financialInformation',
};

// add save interface
export const saveFinancialBankUrl = {
  method: 'put',
  url: '/recruitment-process/:recruitmentProcessId/financialInfo/financialBank',
};

export const saveFinancialReportUrl = {
  method: 'put',
  url: '/recruitment-process/:recruitmentProcessId/financialInfo/financialReport',
};

export const saveFinancialReportCreditortUrl = {
  method: 'put',
  url: '/recruitment-process/:recruitmentProcessId/financialInfo/financialReportCreditor',
};

export const saveFinancialCountryResidenceUrl = {
  method: 'put',
  url: '/recruitment-process/:recruitmentProcessId/financialInfo/financialCountryResidence',
};

export const saveFinancialFACTUrl = {
  method: 'put',
  url: '/recruitment-process/:recruitmentProcessId/financialInfo/financialFACT',
};

export const saveFinancialQuestionUrl = {
  method: 'put',
  url: '/recruitment-process/:recruitmentProcessId/fileQuestion/question',
};

export const saveCheckQuestionUrl = {
  method: 'put',
  url: '/recruitment-process/:recruitmentProcessId/fileQuestion/checkboxQuestion',
};

export const deleteFinancialCreditBureauReportUrl = {
  method: 'delete',
  url: '/recruitment-process/financialInfo/deleteFinancialCreditBureauReport/:creatorId',
};

export const deleteFinancialCountryOfTaxResidencetUrl = {
  method: 'delete',
  url: '/recruitment-process/financialInfo/deleteFinancialCountryOfTaxResidence/:countryTaxResidenceId',
};

//fit-proper-criteria
export const getFitInformationUrl = {
  method: 'get',
  url: '/recruitment-process/:recruitmentProcessId/fitProperCriteria/fitProper',
};

export const saveFitInformationUrl = {
  method: 'put',
  url: '/recruitment-process/:recruitmentProcessId/fileQuestion/question',
};

export const finishFitInformationUrl = {
  method: 'post',
  url: '/recruitment-process/:recruitmentProcessId/jobApplication/:jobApplicationId/fitProperCriteria/fitProperCriteriaNextStep',
};

export const getInvitedSlotDetailUrl = {
  method: 'get',
  url: '/bdmReview/interviewRSVP/interviewSlot/:recruitmentProcessId',
};
export const submitInterviewSlotUrl = {
  method: 'post',
  url: '/bdmReview/interviewRSVP/interviewSlot/',
};
// business-interest-declaration
export const getBusinessInterestDeclarationUrl = {
  method: 'get',
  url: '/recruitment-process/:recruitmentProcessId/business-interest-declaration',
};
export const saveBusinessInterestDeclarationUrl = {
  method: 'put',
  url: '/recruitment-process/:recruitmentProcessId/business-interest-declaration',
};
export const saveBusinessInterestDeclarationQuestionUrl = {
  method: 'put',
  url: '/recruitment-process/business-interest-declaration/:id',
};
export const deleteBusinessInterestDeclarationUrl = {
  method: 'delete',
  url: '/recruitment-process/:recruitmentProcessId/business-interest-declaration',
};
export const submitBusinessInterestDeclarationUrl = {
  method: 'PATCH',
  url: '/recruitment-process/:recruitmentProcessId/business-interest-declaration',
};

// candidate sign
export const getBDMFscStatusUrl = {
  method: 'get',
  url: '/bdmReview/fsc-status/:recruitmentProcessId',
};

export const getCandidateRemoteSignUrl = {
  method: 'get',
  url: '/recruitment-process/:recruitmentProcessId/remote-sign',
};

export const saveCandidateSignStatusFlagUrl = {
  method: 'patch',
  url: '/recruitment-process/:recruitmentProcessId/remote-sign',
};

export const generateCandidateSignLinkUrl = {
  method: 'post',
  url: '/recruitment-process/:recruitmentProcessId/generate-candidate-sign-link',
};

// overview withdraw
export const submitWithdrawUrl = {
  method: 'delete',
  url: '/recruitment-process/:recruitmentProcessId/withdraw-application',
};

export const getStudentConversionUrl = {
  method: 'get',
  url: '/student-conversion/:id',
};

export const saveDraftStudentConversionUrl = {
  method: 'put',
  url: '/student-conversion/save-draft',
};

export const uploadFileStudentConversionUrl = {
  method: 'post',
  url: '/student-conversion/file',
};

export const deleteFileStudentConversionUrl = {
  method: 'delete',
  url: '/student-conversion/file/:id',
};
