export const SET_EDUCATIONBACKGROUND_DATA =
  'irecruit/educationBackground/SET_EDUCATIONBACKGROUND_DATA';
export const SET_IS_INIT_COMPLETED =
  'irecruit/educationBackground/SET_IS_INIT_COMPLETED';
export const SET_INITIAL_STATE =
  'irecruit/educationBackground/SET_INITIAL_STATE';

export const SET_EVALUATION_DATA =
  'irecruit/educationBackground/SET_EVALUATION_DATA';

export const GET_EDUCATIONBACKGROUND_REQUEST =
  'irecruit/educationBackground/GET_EDUCATIONBACKGROUND_REQUEST';
export const SAVE_EDUCATIONBACKGROUND_REQUEST =
  'irecruit/educationBackground/SAVE_EDUCATIONBACKGROUND_REQUEST';
export const PROCEED_EDUCATIONBACKGROUND_SUCCESS =
  'irecruit/educationBackground/ADD_EDUCATIONBACKGROUND_SUCCESS';
export const PROCEED_EDUCATIONBACKGROUND_ERROR =
  'irecruit/educationBackground/ADD_EDUCATIONBACKGROUND_ERROR';

export const DELETE_EDUCATIONBACKGROUND_REQUEST =
  'irecruit/educationBackground/DELETE_EDUCATIONBACKGROUND_REQUEST';

export const UPDATE_EDUCATIONBACKGROUND_REQUEST =
  'irecruit/educationBackground/UPDATE_EDUCATIONBACKGROUND_REQUEST';
