import { call, put, takeLatest, select } from 'redux-saga/effects';
import request, { unauthorizedHandler } from '@utils/request';
import {
  afterLoginSuccess,
  afterLoginError,
  getMaintenanceAlertSuccess,
  getMaintenanceStatus,
  getMaintenanceAlertError,
} from './actions';
import {
  AFTER_LOGIN_REQUEST,
  GET_MAINTENANCE_ALERT_REQUEST,
} from './actionTypes';

import { makeSelectToken } from './selectors';

import { MAINTENANCE_STATUS } from '../../utils/constants';

export function* afterLoginAction() {
  try {
    let token = yield select(makeSelectToken());

    if (token) {
      const res = yield call(
        request,
        '/candidate/first-login',
        'post',
        null,
        token
      );
      if (res.data.success.status) {
        yield put(afterLoginSuccess('success trigger after login'));
      } else {
        yield put(afterLoginError('Error request trigger after login'));
      }
    } else {
      yield put(afterLoginError('token not provided'));
    }
  } catch (err) {
    const { response, message } = err;
    yield call(unauthorizedHandler, response);
    yield put(afterLoginError(message));
  }
}

function* getMaintenanceAlertAction() {
  try {
    let token = yield select(makeSelectToken());

    if (token) {
      const res = yield call(
        request,
        '/system/maintenance-schedule',
        'get',
        null,
        token
      );
      if (res) {
        if (res.data.data) {
          yield put(getMaintenanceAlertSuccess(res.data.data));
        } else {
          yield put(getMaintenanceAlertSuccess({}));
        }

        yield put(getMaintenanceStatus(MAINTENANCE_STATUS.NORMAL));
      } else {
        yield put(getMaintenanceAlertError('Unable to get Maintenance Alert'));
        yield put(getMaintenanceStatus(MAINTENANCE_STATUS.ERROR));
      }
    }
  } catch (err) {
    if (err.response?.status === 503) {
      const { message } = err.response.data.success;
      if (message.match(/Server Maintenance/i)) {
        yield put(getMaintenanceStatus(MAINTENANCE_STATUS.ERROR));
        yield put(getMaintenanceAlertError(message));
      }
    } else {
      yield call(unauthorizedHandler, err.response);
      yield put(
        getMaintenanceAlertError('Token not provided get Maintenance Alert')
      );
    }
  }
}

export default function* rootSaga() {
  yield takeLatest(AFTER_LOGIN_REQUEST, afterLoginAction);
  yield takeLatest(GET_MAINTENANCE_ALERT_REQUEST, getMaintenanceAlertAction);
}
