import {
  GET_SIGNING_URL_REQUEST,
  GET_SIGNING_URL_SUCCESS,
  GET_SIGNING_URL_ERROR,
  POST_SIGNER_STATUS_REQUEST,
  POST_SIGNER_STATUS_ERROR,
  POST_SIGNER_STATUS_SUCCESS,
} from './actionTypes';

export const getSigningUrl = (signType) => ({
  type: GET_SIGNING_URL_REQUEST,
  signType,
});

export const getSigningUrlSuccess = (signUrl) => ({
  type: GET_SIGNING_URL_SUCCESS,
  signUrl,
});

export const getSigningUrlError = (message) => ({
  type: GET_SIGNING_URL_ERROR,
  message,
});

export const postSignerStatusRequest = (epsId) => ({
  type: POST_SIGNER_STATUS_REQUEST,
  epsId,
});

export const postSignerStatusSuccess = (status) => ({
  type: POST_SIGNER_STATUS_SUCCESS,
  status,
});

export const postSignerStatusError = (message) => ({
  type: POST_SIGNER_STATUS_ERROR,
  message,
});
