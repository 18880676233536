import { fromJS } from 'immutable';
import { MAINTENANCE_STATUS } from '../../utils/constants';
import {
  SET_USER_DATA,
  RESET_STATE,
  AFTER_LOGIN_REQUEST,
  AFTER_LOGIN_SUCCESS,
  AFTER_LOGIN_ERROR,
  GET_MAINTENANCE_ALERT_SUCCESS,
  GET_MAINTENANCE_STATUS,
  GET_MAINTENANCE_ALERT_REQUEST,
  GET_MAINTENANCE_ALERT_ERROR,
  SET_UNAUTHORIZED,
  SET_TOKEN,
} from './actionTypes';

const initialState = fromJS({
  userData: {},
  token: null,
  loading: false,
  error: false,
  success: false,
  message: null,
  maintenanceAlert: {},
  maintenanceStatus: MAINTENANCE_STATUS.INITIAL,
  unauthorized: false,
});

export default function (state = initialState, action) {
  switch (action.type) {
    case SET_USER_DATA:
      return state.set('userData', fromJS(action.value));
    case SET_TOKEN:
      return state.set('token', action.value);
    case RESET_STATE:
      return initialState;
    case AFTER_LOGIN_REQUEST:
    case GET_MAINTENANCE_ALERT_REQUEST:
      return state
        .set('loading', true)
        .set('success', false)
        .set('error', false)
        .set('message', null);
    case AFTER_LOGIN_SUCCESS:
      return state
        .set('loading', false)
        .set('success', true)
        .set('message', action.message);
    case AFTER_LOGIN_ERROR:
    case GET_MAINTENANCE_ALERT_ERROR:
      return state
        .set('loading', false)
        .set('error', true)
        .set('message', action.error);
    case GET_MAINTENANCE_ALERT_SUCCESS:
      return state
        .set('loading', false)
        .set('success', true)
        .set('maintenanceAlert', fromJS(action.data));
    case GET_MAINTENANCE_STATUS:
      return state
        .set('loading', false)
        .set('success', action.status === MAINTENANCE_STATUS.NORMAL)
        .set('maintenanceStatus', action.status);
    case SET_UNAUTHORIZED:
      return state.set('unauthorized', true);
    default:
      return state;
  }
}
