export const GET_FINANCIAL_INFO_REQUEST =
  'irecruit/financialInfo/GET_FINANCIAL_INFO_REQUEST';

export const GET_FINANCIAL_INFO_SUCCESS =
  'irecruit/financialInfo/GET_FINANCIAL_INFO_SUCCESS';

export const SET_IS_IMMEDIATE_LOADING =
  'irecruit/financialInfo/SET_IS_IMMEDIATE_LOADING';

export const GET_FINANCIAL_INFO_ERROR =
  'irecruit/financialInfo/GET_FINANCIAL_INFO_ERROR';

export const SET_FINANCIAL_INFO_REQUEST =
  'irecruit/financialInfo/SET_FINANCIAL_INFO_REQUEST';

export const SAVE_CHECK_QUESTION_REQUEST =
  'irecruit/financialInfo/SAVE_CHECK_QUESTION_REQUEST';

export const SAVE_CHECK_QUESTION_SUCCESS =
  'irecruit/financialInfo/SAVE_CHECK_QUESTION_SUCCESS';

export const SAVE_CHECK_QUESTION_ERROR =
  'irecruit/financialInfo/SAVE_CHECK_QUESTION_ERROR';

export const DELETE_FINANCIAL_CREDITBUREAUREPORT_REQUEST =
  'irecruit/financialInfo/DELETE_FINANCIAL_CREDITBUREAUREPORT_REQUEST';

export const DELETE_FINANCIAL_CREDITBUREAUREPORT_SUCCESS =
  'irecruit/financialInfo/DELETE_FINANCIAL_CREDITBUREAUREPORT_SUCCESS';

export const DELETE_FINANCIAL_CREDITBUREAUREPORT_ERROR =
  'irecruit/financialInfo/DELETE_FINANCIAL_CREDITBUREAUREPORT_ERROR';

export const DELETE_FINANCIAL_COUNTRYOFTAXRESIDENCE_REQUEST =
  'irecruit/financialInfo/DELETE_FINANCIAL_COUNTRYOFTAXRESIDENCE_REQUEST';

export const DELETE_FINANCIAL_COUNTRYOFTAXRESIDENCE_SUCCESS =
  'irecruit/financialInfo/DELETE_FINANCIAL_COUNTRYOFTAXRESIDENCE_SUCCESS';

export const DELETE_FINANCIAL_COUNTRYOFTAXRESIDENCE_ERROR =
  'irecruit/financialInfo/DELETE_FINANCIAL_COUNTRYOFTAXRESIDENCE_ERROR';

export const GET_ALL_COMMENTS_REQUEST =
  'irecruit/financialInfo/GET_ALL_COMMENTS_REQUEST';

export const SET_ALL_COMMENTS_DATA =
  'irecruit/financialInfo/SET_ALL_COMMENTS_DATA';

export const GET_ALL_COMMENTS_SUCCESS =
  'irecruit/financialInfo/GET_ALL_COMMENTS_SUCCESS';

export const GET_ALL_COMMENTS_ERROR =
  'irecruit/financialInfo/GET_ALL_COMMENTS_ERROR';

// add save interface
// /financialInfo/saveFinancialBank
export const SAVE_FINANCIAL_BANK_REQUEST =
  'irecruit/financialInfo/SAVE_FINANCIAL_BANK_REQUEST';

export const SAVE_FINANCIAL_BANK_SUCCESS =
  'irecruit/financialInfo/SAVE_FINANCIAL_BANK_SUCCESS';

export const SAVE_FINANCIAL_BANK_ERROR =
  'irecruit/financialInfo/SAVE_FINANCIAL_BANK_ERROR';

//  /financialInfo/saveFinancialReport
export const SAVE_FINANCIAL_REPORT_REQUEST =
  'irecruit/financialInfo/SAVE_FINANCIAL_REPORT_REQUEST';

export const SAVE_FINANCIAL_REPORT_SUCCESS =
  'irecruit/financialInfo/SAVE_FINANCIAL_REPORT_SUCCESS';

export const SAVE_FINANCIAL_REPORT_ERROR =
  'irecruit/financialInfo/SAVE_FINANCIAL_REPORT_ERROR';

// credit report
export const SAVE_FINANCIAL_REPORT_CREDITOR_REQUEST =
  'irecruit/financialInfo/SAVE_FINANCIAL_REPORT_CREDITOR_REQUEST';

export const SAVE_FINANCIAL_REPORT_CREDITOR_SUCCESS =
  'irecruit/financialInfo/SAVE_FINANCIAL_REPORT_CREDITOR_SUCCESS';

export const SAVE_FINANCIAL_REPORT_CREDITOR_ERROR =
  'irecruit/financialInfo/SAVE_FINANCIAL_REPORT_CREDITOR_ERROR';

// /financialInfo/saveFinancialCountryResidence
export const SAVE_FINANCIAL_COUNTRY_RESIDENCE_REQUEST =
  'irecruit/financialInfo/SAVE_FINANCIAL_COUNTRY_RESIDENCE_REQUEST';

export const SAVE_FINANCIAL_COUNTRY_RESIDENCE_SUCCESS =
  'irecruit/financialInfo/SAVE_FINANCIAL_COUNTRY_RESIDENCE_SUCCESS';

export const SAVE_FINANCIAL_COUNTRY_RESIDENCE_ERROR =
  'irecruit/financialInfo/SAVE_FINANCIAL_COUNTRY_RESIDENCE_ERROR';

// saveFinancialFACT
export const SAVE_FINANCIAL_FACT_REQUEST =
  'irecruit/financialInfo/SAVE_FINANCIAL_FACT_REQUEST';

export const SAVE_FINANCIAL_FACT_SUCCESS =
  'irecruit/financialInfo/SAVE_FINANCIAL_FACT_SUCCESS';

export const SAVE_FINANCIAL_FACT_ERROR =
  'irecruit/financialInfo/SAVE_FINANCIAL_FACT_ERROR';

//  handle file
export const UPLOAD_DOCUMENTS_REQUEST =
  'irecruit/financialInfo/UPLOAD_DOCUMENTS_REQUEST';

export const UPLOAD_DOCUMENTS_SUCCESS =
  'irecruit/financialInfo/UPLOAD_DOCUMENTS_SUCCESS';

export const UPLOAD_DOCUMENTS_ERROR =
  'irecruit/financialInfo/UPLOAD_DOCUMENTS_ERROR';

export const DELETE_DOCUMENTS_REQUEST =
  'irecruit/financialInfo/DELETE_DOCUMENTS_REQUEST';

export const DELETE_DOCUMENTS_SUCCESS =
  'irecruit/financialInfo/DELETE_DOCUMENTS_SUCCESS';

export const DELETE_DOCUMENTS_ERROR =
  'irecruit/financialInfo/DELETE_DOCUMENTS_ERROR';
