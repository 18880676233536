import { createSelector } from 'reselect';
import includes from 'lodash/includes';

const selectEobCommonInfo = (state) => state.eobCommonInfo;

export const makeSelectRequest = () =>
  createSelector(selectEobCommonInfo, (eobCommonInfoState) =>
    eobCommonInfoState.filter((value, key) =>
      includes(
        [
          'loading',
          'error',
          'success',
          'message',
          'isSubmitting',
          'isSubmitted',
        ],
        key
      )
    )
  );

export const makeSelectStepStatus = () =>
  createSelector(selectEobCommonInfo, (eobCommonInfoState) =>
    eobCommonInfoState.get('stepStatus')
  );

export const makeSelectHomeScreenApplicationStatus = () =>
  createSelector(selectEobCommonInfo, (eobCommonInfoState) =>
    eobCommonInfoState.get('homeScreenApplicationStatus')
  );

export const makeSelectRecruitmentProcessIds = () =>
  createSelector(selectEobCommonInfo, (eobCommonInfoState) =>
    eobCommonInfoState
      .get('homeScreenApplicationStatus')
      .filter((value, key) => includes(['id', 'prospectId', 'caseNumber'], key))
  );

export const makeSelectDisabled = () =>
  createSelector(selectEobCommonInfo, (eobCommonInfoState) =>
    eobCommonInfoState.get('dsiabled')
  );

export const makeStepStatusSuccess = () =>
  createSelector(selectEobCommonInfo, (eobCommonInfoState) =>
    eobCommonInfoState.get('stepStatusSuccess')
  );

export const makeSelectStepBarRequest = () =>
  createSelector(selectEobCommonInfo, (eobCommonInfoState) =>
    eobCommonInfoState.get('stepBarStatus')
  );

export const makeSelectCommonInfoLoading = () =>
  createSelector(
    selectEobCommonInfo,
    (eobCommonInfoState) =>
      eobCommonInfoState.get('loading') ||
      eobCommonInfoState.getIn(['stepBarStatus', 'loading'])
  );

export const makeSelectApplicationType = () =>
  createSelector(selectEobCommonInfo, (eobCommonInfoState) =>
    eobCommonInfoState.getIn(
      ['homeScreenApplicationStatus', 'applicationType'],
      ''
    )
  );
