import {
  GET_UPCOMING_DATA_REQUEST,
  GET_UPCOMING_DATA_SUCCESS,
  GET_UPCOMING_DATA_ERROR,
  GET_HAPPENING_DATA_REQUEST,
  GET_HAPPENING_DATA_SUCCESS,
  GET_HAPPENING_DATA_ERROR,
  ACCEPT_EVENT_REQUEST,
  ACCEPT_EVENT_SUCCESS,
  ACCEPT_EVENT_ERROR,
  DECLINE_EVENT_REQUEST,
  DECLINE_EVENT_SUCCESS,
  DECLINE_EVENT_ERROR,
} from './actionTypes';

export const getUpcomingData = () => ({
  type: GET_UPCOMING_DATA_REQUEST,
});

export const getUpcomingDataSuccess = (upcoming) => ({
  type: GET_UPCOMING_DATA_SUCCESS,
  upcoming,
});

export const getUpcomingDataError = (message) => ({
  type: GET_UPCOMING_DATA_ERROR,
  message,
});

export const getHappeningData = () => ({
  type: GET_HAPPENING_DATA_REQUEST,
});

export const getHappeningDataSuccess = (happening) => ({
  type: GET_HAPPENING_DATA_SUCCESS,
  happening,
});

export const getHappeningDataError = (message) => ({
  type: GET_HAPPENING_DATA_ERROR,
  message,
});

export const acceptEvent = (id) => ({
  type: ACCEPT_EVENT_REQUEST,
  id,
});

export const acceptEventSuccess = (message) => ({
  type: ACCEPT_EVENT_SUCCESS,
  message,
});

export const acceptEventError = (message) => ({
  type: ACCEPT_EVENT_ERROR,
  message,
});

export const declineEvent = (id, comment) => ({
  type: DECLINE_EVENT_REQUEST,
  id,
  comment,
});

export const declineEventSuccess = (message) => ({
  type: DECLINE_EVENT_SUCCESS,
  message,
});

export const declineEventError = (message) => ({
  type: DECLINE_EVENT_ERROR,
  message,
});
