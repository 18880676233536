import { fromJS } from 'immutable';
import {
  PROCEED_EOB_COMMON_INFO_ERROR,
  PROCEED_EOB_COMMON_INFO_SUCCESS,
  SET_HOME_SCREEN_APPLICATION_STATUS,
  SET_STEP_STATUS,
  SET_DISABLED,
  PROCEED_STEP_STATUS_SUCCESS,
  SAVE_STEP_BAR_REQUEST,
  PROCEED_STEP_BAR_ERROR,
  PROCEED_STEP_BAR_SUCCESS,
  GET_STEP_BAR_REQUEST,
  GET_HOME_SCREEN_APPLICATION_STATUS,
  SAVE_HOME_SCREEN_APPLICATION_STATUS,
} from './actionTypes';

const initialState = fromJS({
  stepList: [],
  stepStatus: {},
  homeScreenApplicationStatus: {},
  disabled: true,
  loading: false,
  error: false,
  success: false,
  stepStatusSuccess: false,
  message: null,
  isSubmitting: false,
  isSubmitted: false,
  stepBarStatus: {
    loading: false,
    error: false,
    success: false,
    message: null,
  },
});

export default function (state = initialState, action) {
  switch (action.type) {
    case SET_STEP_STATUS:
      return state.set('stepStatus', fromJS(action.data));
    case SET_HOME_SCREEN_APPLICATION_STATUS:
      return state.set('homeScreenApplicationStatus', fromJS(action.data));
    case SET_DISABLED:
      return state.set('disabled', action.data);
    case GET_HOME_SCREEN_APPLICATION_STATUS:
      return state
        .set('loading', true)
        .set('success', false)
        .set('error', false)
        .set('isSubmitting', false)
        .set('isSubmitted', false)
        .set('message', null);
    case SAVE_HOME_SCREEN_APPLICATION_STATUS:
      return state
        .set('loading', true)
        .set('success', false)
        .set('error', false)
        .set('isSubmitting', false)
        .set('isSubmitted', false)
        .set('message', null);
    case PROCEED_EOB_COMMON_INFO_SUCCESS:
      return state
        .set('loading', false)
        .set('success', true)
        .set('error', false)
        .set('isSubmitting', false)
        .set('isSubmitted', true)
        .set('message', action.message);
    case PROCEED_EOB_COMMON_INFO_ERROR:
      return state
        .set('loading', false)
        .set('success', false)
        .set('error', true)
        .set('isSubmitting', false)
        .set('message', action.message);
    case PROCEED_STEP_STATUS_SUCCESS:
      return state.set('stepStatusSuccess', action.data);
    case SAVE_STEP_BAR_REQUEST:
      return state
        .setIn(['stepBarStatus', 'loading'], true)
        .setIn(['stepBarStatus', 'success'], false)
        .setIn(['stepBarStatus', 'error'], false);
    case GET_STEP_BAR_REQUEST:
      return state
        .setIn(['stepBarStatus', 'loading'], true)
        .setIn(['stepBarStatus', 'success'], false)
        .setIn(['stepBarStatus', 'error'], false);
    case PROCEED_STEP_BAR_ERROR:
      return state
        .setIn(['stepBarStatus', 'loading'], false)
        .setIn(['stepBarStatus', 'success'], false)
        .setIn(['stepBarStatus', 'error'], true)
        .setIn(['stepBarStatus', 'message'], action.message);
    case PROCEED_STEP_BAR_SUCCESS:
      return state
        .setIn(['stepBarStatus', 'loading'], false)
        .setIn(['stepBarStatus', 'success'], true)
        .setIn(['stepBarStatus', 'error'], false)
        .setIn(['stepBarStatus', 'message'], action.message);

    default:
      return state;
  }
}
