import {
  GET_INTERVIEW_SLOT_DETAIL_REQUEST,
  GET_INTERVIEW_SLOT_DETAILL_SUCCESS,
  GET_INTERVIEW_SLOT_DETAIL_ERROR,
  SUBMIT_INTERVIEW_SLOT_REQUEST,
  SUBMIT_INTERVIEW_SLOT_SUCCESS,
  SUBMIT_INTERVIEW_SLOT_ERROR,
  SET_INTERVIEW_SLOT_DATA,
  SET_INITIAL_STATE,
  SET_INTERVIEW_STATUS_DATA,
} from './actionTypes';

export const setInitialRSVPState = (data) => ({
  type: SET_INITIAL_STATE,
  data,
});

export const setInterviewStatus = (value) => ({
  type: SET_INTERVIEW_STATUS_DATA,
  value,
});

export const getInvitedSlotRequest = (data) => ({
  type: GET_INTERVIEW_SLOT_DETAIL_REQUEST,
  data,
});

export const getInvitedSlotSuccess = (data) => ({
  type: GET_INTERVIEW_SLOT_DETAILL_SUCCESS,
  data,
});
export const getInvitedSlotError = (message) => ({
  type: GET_INTERVIEW_SLOT_DETAIL_ERROR,
  message,
});

export const setInvitedSlotDate = (value) => ({
  type: SET_INTERVIEW_SLOT_DATA,
  value,
});

export const submitInvitedSlotRequest = (data) => ({
  type: SUBMIT_INTERVIEW_SLOT_REQUEST,
  data,
});

export const submitInvitedSlotSuccess = (data) => ({
  type: SUBMIT_INTERVIEW_SLOT_SUCCESS,
  data,
});
export const submitInvitedSlotError = (message) => ({
  type: SUBMIT_INTERVIEW_SLOT_ERROR,
  message,
});
