import {
  GET_FINANCIAL_INFO_REQUEST,
  GET_FINANCIAL_INFO_SUCCESS,
  GET_FINANCIAL_INFO_ERROR,
  SET_FINANCIAL_INFO_REQUEST,
  SAVE_CHECK_QUESTION_REQUEST,
  SAVE_CHECK_QUESTION_SUCCESS,
  SAVE_CHECK_QUESTION_ERROR,
  DELETE_FINANCIAL_CREDITBUREAUREPORT_REQUEST,
  DELETE_FINANCIAL_CREDITBUREAUREPORT_SUCCESS,
  DELETE_FINANCIAL_CREDITBUREAUREPORT_ERROR,
  DELETE_FINANCIAL_COUNTRYOFTAXRESIDENCE_REQUEST,
  DELETE_FINANCIAL_COUNTRYOFTAXRESIDENCE_SUCCESS,
  DELETE_FINANCIAL_COUNTRYOFTAXRESIDENCE_ERROR,
  GET_ALL_COMMENTS_REQUEST,
  GET_ALL_COMMENTS_SUCCESS,
  GET_ALL_COMMENTS_ERROR,
  SET_ALL_COMMENTS_DATA,

  // add save interface
  SAVE_FINANCIAL_BANK_REQUEST,
  SAVE_FINANCIAL_BANK_SUCCESS,
  SAVE_FINANCIAL_BANK_ERROR,

  // report
  SAVE_FINANCIAL_REPORT_REQUEST,
  SAVE_FINANCIAL_REPORT_SUCCESS,
  SAVE_FINANCIAL_REPORT_ERROR,

  // report credit
  SAVE_FINANCIAL_REPORT_CREDITOR_REQUEST,
  SAVE_FINANCIAL_REPORT_CREDITOR_SUCCESS,
  SAVE_FINANCIAL_REPORT_CREDITOR_ERROR,

  // country residence
  SAVE_FINANCIAL_COUNTRY_RESIDENCE_REQUEST,
  SAVE_FINANCIAL_COUNTRY_RESIDENCE_SUCCESS,
  SAVE_FINANCIAL_COUNTRY_RESIDENCE_ERROR,

  // fact
  SAVE_FINANCIAL_FACT_REQUEST,
  SAVE_FINANCIAL_FACT_SUCCESS,
  SAVE_FINANCIAL_FACT_ERROR,

  // handle file
  UPLOAD_DOCUMENTS_REQUEST,
  UPLOAD_DOCUMENTS_SUCCESS,
  UPLOAD_DOCUMENTS_ERROR,
  DELETE_DOCUMENTS_REQUEST,
  DELETE_DOCUMENTS_SUCCESS,
  DELETE_DOCUMENTS_ERROR,
  SET_IS_IMMEDIATE_LOADING,
} from './actionTypes';

export const getFinancialInfoDataRequest = (data) => ({
  type: GET_FINANCIAL_INFO_REQUEST,
  data,
});
export const getFinancialInfoDataSuccess = (message) => ({
  type: GET_FINANCIAL_INFO_SUCCESS,
  message,
});

export const setIsImmediateLoading = (data) => ({
  type: SET_IS_IMMEDIATE_LOADING,
  data,
});

export const getFinancialInfoDataError = (message) => ({
  type: GET_FINANCIAL_INFO_ERROR,
  message,
});

export const setFinancialInfoDataRequest = (data) => ({
  type: SET_FINANCIAL_INFO_REQUEST,
  data,
});

// add save interface
export const saveFinancialBankDataRequest = (data) => ({
  type: SAVE_FINANCIAL_BANK_REQUEST,
  data,
});

export const saveFinancialBankDataSuccess = (message) => ({
  type: SAVE_FINANCIAL_BANK_SUCCESS,
  message,
});

export const saveFinancialBankDataError = (message) => ({
  type: SAVE_FINANCIAL_BANK_ERROR,
  message,
});
//  add save report interface
export const saveFinancialReportDataRequest = (data) => ({
  type: SAVE_FINANCIAL_REPORT_REQUEST,
  data,
});

export const saveFinancialReportDataSuccess = (message) => ({
  type: SAVE_FINANCIAL_REPORT_SUCCESS,
  message,
});

export const saveFinancialReportDataError = (message) => ({
  type: SAVE_FINANCIAL_REPORT_ERROR,
  message,
});

// report credit
export const saveFinancialReporCreditortDataRequest = (data) => ({
  type: SAVE_FINANCIAL_REPORT_CREDITOR_REQUEST,
  data,
});

export const saveFinancialReporCreditortDataSuccess = (message) => ({
  type: SAVE_FINANCIAL_REPORT_CREDITOR_SUCCESS,
  message,
});

export const saveFinancialReporCreditortDataError = (message) => ({
  type: SAVE_FINANCIAL_REPORT_CREDITOR_ERROR,
  message,
});

// country residence
export const saveFinancialCountryResidenceDataRequest = (data) => ({
  type: SAVE_FINANCIAL_COUNTRY_RESIDENCE_REQUEST,
  data,
});

export const saveFinancialCountryResidenceDataSuccess = (message) => ({
  type: SAVE_FINANCIAL_COUNTRY_RESIDENCE_SUCCESS,
  message,
});

export const saveFinancialCountryResidenceDataError = (message) => ({
  type: SAVE_FINANCIAL_COUNTRY_RESIDENCE_ERROR,
  message,
});

// Fact
export const saveFinancialFactDataRequest = (data) => ({
  type: SAVE_FINANCIAL_FACT_REQUEST,
  data,
});

export const saveFinancialFactDataSuccess = (message) => ({
  type: SAVE_FINANCIAL_FACT_SUCCESS,
  message,
});

export const saveFinancialFactDataError = (message) => ({
  type: SAVE_FINANCIAL_FACT_ERROR,
  message,
});

//===============================================================

export const saveCheckQuestionRequest = (data) => ({
  type: SAVE_CHECK_QUESTION_REQUEST,
  data,
});
export const saveCheckQuestionSuccess = (message) => ({
  type: SAVE_CHECK_QUESTION_SUCCESS,
  message,
});

export const saveCheckQuestionError = (message) => ({
  type: SAVE_CHECK_QUESTION_ERROR,
  message,
});

export const deleteFinancialCreditBureauReportRequest = (data) => ({
  type: DELETE_FINANCIAL_CREDITBUREAUREPORT_REQUEST,
  data,
});

export const deleteFinancialCreditBureauReportSuccess = (message) => ({
  type: DELETE_FINANCIAL_CREDITBUREAUREPORT_SUCCESS,
  message,
});

export const deleteFinancialCreditBureauReportError = (message) => ({
  type: DELETE_FINANCIAL_CREDITBUREAUREPORT_ERROR,
  message,
});

export const deleteFinancialCountryOfTaxResidenceRequest = (data) => ({
  type: DELETE_FINANCIAL_COUNTRYOFTAXRESIDENCE_REQUEST,
  data,
});

export const deleteFinancialCountryOfTaxResidenceSuccess = (message) => ({
  type: DELETE_FINANCIAL_COUNTRYOFTAXRESIDENCE_SUCCESS,
  message,
});

export const deleteFinancialCountryOfTaxResidenceError = (message) => ({
  type: DELETE_FINANCIAL_COUNTRYOFTAXRESIDENCE_ERROR,
  message,
});

export const getCommentsDataRequest = (data) => ({
  type: GET_ALL_COMMENTS_REQUEST,
  data,
});
export const setCommentsData = (data) => ({
  type: SET_ALL_COMMENTS_DATA,
  data,
});
export const getCommentsDataSuccess = (message) => ({
  type: GET_ALL_COMMENTS_SUCCESS,
  message,
});
export const getCommentsDataError = (message) => ({
  type: GET_ALL_COMMENTS_ERROR,
  message,
});

// handle file
export const uploadDocumentsRequest = (data) => ({
  type: UPLOAD_DOCUMENTS_REQUEST,
  data,
});

export const uploadDocumentsSuccess = (message) => ({
  type: UPLOAD_DOCUMENTS_SUCCESS,
  message,
});
export const uploadDocumentsError = (message) => ({
  type: UPLOAD_DOCUMENTS_ERROR,
  message,
});

export const deleteDocumentsRequest = (data) => ({
  type: DELETE_DOCUMENTS_REQUEST,
  data,
});

export const deleteDocumentsSuccess = (message) => ({
  type: DELETE_DOCUMENTS_SUCCESS,
  message,
});
export const deleteDocumentsError = (message) => ({
  type: DELETE_DOCUMENTS_ERROR,
  message,
});
