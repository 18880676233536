import {
  GET_FITPROPER_REQUEST,
  SET_FITPROPER_DATA,
  GET_FITPROPER_SUCCESS,
  GET_FITPROPER_ERROR,
  SET_SAVE_FITPROPER_DATA,
  SAVE_FITPROPER_REQUEST,
  SAVE_FITPROPER_SUCCESS,
  SAVE_FITPROPER_ERROR,
  SET_INITIAL_STATE,
} from './actionType';

export const setInitialState = (data) => ({
  type: SET_INITIAL_STATE,
  data,
});
export const setFitProperData = (data) => ({
  type: SET_FITPROPER_DATA,
  data,
});
export const getFitProperRequest = (data) => ({
  type: GET_FITPROPER_REQUEST,
  data,
});
export const getFitProperSuccess = (consent = {}) => ({
  type: GET_FITPROPER_SUCCESS,
  consent,
});
export const getFitProperError = (error) => ({
  type: GET_FITPROPER_ERROR,
  error,
});

export const setSaveFitProperData = (data) => ({
  type: SET_SAVE_FITPROPER_DATA,
  data,
});
export const saveFitProperRequest = (data) => ({
  type: SAVE_FITPROPER_REQUEST,
  data,
});
export const saveFitProperError = (error) => ({
  type: SAVE_FITPROPER_ERROR,
  error,
});
export const saveFitProperSuccess = (error) => ({
  type: SAVE_FITPROPER_SUCCESS,
  error,
});
