import React from 'react';
import loadable from '@loadable/component';

const FscApplication = loadable(() => import('@pages/fscApplication'));
const Welcome = loadable(() => import('@pages/fscApplication/Welcome'));
const JobSource = loadable(() =>
  import('@pages/fscApplication/assessment/JobSource')
);
const Background = loadable(() =>
  import('@pages/fscApplication/assessment/Background')
);
const Education = loadable(() =>
  import('@pages/fscApplication/assessment/Education')
);
const Certification = loadable(() =>
  import('@pages/fscApplication/assessment/Certification')
);
const PersonalInformation = loadable(() =>
  import('@pages/fscApplication/PersonalInformation')
);
const FinancialInformation = loadable(() =>
  import('@pages/fscApplication/FinancialInformation')
);
const Criteria = loadable(() => import('@pages/fscApplication/Criteria'));
const Review = loadable(() => import('@pages/fscApplication/Review'));
const SubmitSuccess = loadable(() =>
  import('@pages/fscApplication/Review/SubmitSuccess')
);
const SupportDocuments = loadable(() =>
  import('@pages/fscApplication/Review/attachments/SupportDocuments')
);
const PreviewDocument = loadable(() =>
  import('@pages/fscApplication/PreviewDocument')
);
const RSVP = loadable(() => import('@pages/fscApplication/RSVP'));
// const WithdrawSuccess = loadable(() =>
//   import('@pages/fscApplication/RSVP/WithdrawnSuccess')
// );
const SignPage = loadable(() => import('@pages/fscApplication/SignPage'));
const SignReviewDocument = loadable(() =>
  import('@pages/fscApplication/SignPage/ReviewDocuments')
);
const SignSubmitSuccess = loadable(() =>
  import('@pages/fscApplication/SignPage/SignSubmitSuccess')
);
const BusinessInterestDeclaration = loadable(() =>
  import('@pages/fscApplication/Criteria/BusinessInterestDeclaration')
);

export const FscWelcome = (props) => (
  <FscApplication {...props} component={Welcome} />
);
export const FscJobSource = (props) => (
  <FscApplication {...props} component={JobSource} />
);
export const FscBackground = (props) => (
  <FscApplication {...props} component={Background} />
);
export const FscEducation = (props) => (
  <FscApplication {...props} component={Education} />
);
export const FscCertification = (props) => (
  <FscApplication {...props} component={Certification} />
);
export const FscPersonalInformation = (props) => (
  <FscApplication {...props} component={PersonalInformation} />
);
export const FscFinancialInformation = (props) => (
  <FscApplication {...props} component={FinancialInformation} />
);
export const FscCriteria = (props) => (
  <FscApplication {...props} component={Criteria} />
);
export const FscReview = (props) => (
  <FscApplication {...props} component={Review} />
);
export const FscSubmitSuccess = (props) => (
  <FscApplication {...props} component={SubmitSuccess} />
);
export const FscSupportDocuments = (props) => (
  <FscApplication {...props} component={SupportDocuments} />
);
export const FscPreviewDocument = (props) => (
  <FscApplication {...props} component={PreviewDocument} />
);
export const FscOverview = (props) => (
  <FscApplication {...props} component={Review} />
);
export const FscRSVP = (props) => (
  <FscApplication {...props} component={RSVP} />
);
// export const FscRSVPWithdrawSuccess = (props) => (
//   <FscApplication {...props} component={WithdrawSuccess} />
// );
export const FscSignPage = (props) => (
  <FscApplication {...props} component={SignPage} />
);
export const FscSignReviewDocument = (props) => (
  <FscApplication {...props} component={SignReviewDocument} />
);
export const FscSignSubmitSuccess = (props) => (
  <FscApplication {...props} component={SignSubmitSuccess} />
);
export const FscBusinessInterestDeclaration = (props) => (
  <FscApplication {...props} component={BusinessInterestDeclaration} />
);
