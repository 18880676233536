import {
  SET_INITIAL_STATE,
  SAVE_FSC_SIGN_DOC_DATA,
  GET_FSC_SIGN_DOC_DATA_REQUEST,
  SAVE_FILE_REVIEW_FLAG_REQUEST,
  GENERATE_CANDIDATE_SIGN_LINK_REQUEST,
  SUBMIT_SIGN_REQUEST,
  GENERATE_CANDIDATE_SIGN_LINK_SUCCESS,
  SUBMIT_SIGN_SUCCESS,
  PROCEED_FSC_SIGN_DOC_SUCCESS,
  PROCEED_FSC_SIGN_DOC_ERROR,
} from './actionTypes';

export const setInitialState = (data) => ({
  type: SET_INITIAL_STATE,
  data,
});

export const saveFscSignDocData = (data) => ({
  type: SAVE_FSC_SIGN_DOC_DATA,
  data,
});

export const getFscSignDocDataRequest = (data) => ({
  type: GET_FSC_SIGN_DOC_DATA_REQUEST,
  data,
});

export const saveFileReviewFlagRequest = (data) => ({
  type: SAVE_FILE_REVIEW_FLAG_REQUEST,
  data,
});

export const generateCandidateSignLinkRequest = (data) => ({
  type: GENERATE_CANDIDATE_SIGN_LINK_REQUEST,
  data,
});

export const generateCandidateSignLinkSuccess = (data) => ({
  type: GENERATE_CANDIDATE_SIGN_LINK_SUCCESS,
  data,
});

export const submitSignRequest = (data) => ({
  type: SUBMIT_SIGN_REQUEST,
  data,
});

export const submitSignSuccess = (data) => ({
  type: SUBMIT_SIGN_SUCCESS,
  data,
});

export const proceedFscSignDocSuccess = (data) => ({
  type: PROCEED_FSC_SIGN_DOC_SUCCESS,
  data,
});

export const proceedFscSignDocError = (message) => ({
  type: PROCEED_FSC_SIGN_DOC_ERROR,
  message,
});
