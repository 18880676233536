export const GET_DRAFT_DATA_REQUEST = 'irecruit/reef/GET_DRAFT_DATA_REQUEST';
export const GET_DRAFT_DATA_SUCCESS = 'irecruit/reef/GET_DRAFT_DATA_SUCCESS';
export const GET_DRAFT_DATA_ERROR = 'irecruit/reef/GET_DRAFT_DATA_ERROR';

export const SAVE_DRAFT_DATA_REQUEST = 'irecruit/reef/SAVE_DRAFT_DATA_REQUEST';
export const SAVE_DRAFT_DATA_SUCCESS = 'irecruit/reef/SAVE_DRAFT_DATA_SUCCESS';
export const SAVE_DRAFT_DATA_ERROR = 'irecruit/reef/SAVE_DRAFT_DATA_ERROR';

export const SUBMIT_REEF_DATA_REQUEST =
  'irecruit/reef/SUBMIT_REEF_DATA_REQUEST';
export const SUBMIT_REEF_DATA_REQUEST_SUCCESS =
  'irecruit/reef/SUBMIT_REEF_DATA_REQUEST_SUCCESS';
export const SUBMIT_REEF_DATA_REQUEST_ERROR =
  'irecruit/reef/SUBMIT_REEF_DATA_REQUEST_ERROR';
