import { call, put, select, takeLatest } from 'redux-saga/effects';
import {
  proceedEmergencyContactError,
  proceedEmergencyContactSuccess,
  setEmergencyContactData,
  setIsInitCompleted,
} from './action';
import {
  GET_EMERGENCY_CONTACT_REQUEST,
  SAVE_EMERGENCY_CONTACT_REQUEST,
} from './actionTypes';
import { callRequestWithToken, unauthorizedHandler } from '@utils/request';
import { getEmergencyContactUrl, saveEmergencyContactUrl } from '@utils/apis';
import { makeSelectRecruitmentProcessIds } from '@redux/fscApplication/eobCommonInfo/selectors';
import { makeSelectIsInitCompleted } from './selectors';

export function* getEmergencyContactAction({ data }) {
  try {
    const params = {
      userId: data.prospectId,
      recruitmentProcessId: data.id,
    };

    const res = yield callRequestWithToken({
      method: 'post',
      url: getEmergencyContactUrl,
      params,
    });

    if (res.data.success.status) {
      yield put(
        setEmergencyContactData(res.data.data.personalInfoEmergencyContact)
      );
      const isInitCompleted = yield select(makeSelectIsInitCompleted());
      if (!isInitCompleted) {
        yield put(setIsInitCompleted(true));
      }
      yield put(proceedEmergencyContactSuccess());
    }
  } catch (err) {
    const { response } = err;
    yield call(unauthorizedHandler, response);
    yield put(
      proceedEmergencyContactError(err.response?.data?.success?.message)
    );
  }
}

export function* saveEmergencyContactAction({ data, fieldName, setIsLoading }) {
  try {
    const recruitmentProcessIds = yield select(
      makeSelectRecruitmentProcessIds()
    );

    let params = {};
    if (fieldName === 'add' || fieldName === 'contactNumber') {
      params = {
        userId: recruitmentProcessIds.get('prospectId'),
        recruitmentProcessId: recruitmentProcessIds.get('id'),
        personalInfoEmergencyContact: data,
      };
    } else {
      params = {
        userId: recruitmentProcessIds.get('prospectId'),
        recruitmentProcessId: recruitmentProcessIds.get('id'),
        personalInfoEmergencyContact: {
          id: data.id,
          [fieldName]: data[fieldName],
        },
      };
    }
    setIsLoading && setIsLoading(true);
    const res = yield callRequestWithToken({
      method: 'post',
      url: saveEmergencyContactUrl,
      params,
    });
    setIsLoading && setIsLoading(false);
    if (res.data.success.status) {
      yield put(setEmergencyContactData(data));
    }
  } catch (err) {
    const { response } = err;
    yield call(unauthorizedHandler, response);
    setIsLoading && setIsLoading(false);
    yield put(
      proceedEmergencyContactError(err.response?.data?.success?.message)
    );
  }
}

export default function* rootSaga() {
  yield takeLatest(GET_EMERGENCY_CONTACT_REQUEST, getEmergencyContactAction);
  yield takeLatest(SAVE_EMERGENCY_CONTACT_REQUEST, saveEmergencyContactAction);
}
