import { call, put, takeLatest, all, select } from 'redux-saga/effects';

import request, { unauthorizedHandler } from '@utils/request';
import { makeSelectToken } from '@redux/app/selectors';

import {
  getEPSDataSuccess,
  getEPSDataError,
  sendEPSDataError,
  sendEPSDataSuccess,
  uploadEPSDocumentSuccess,
  uploadEPSDocumentError,
  sendEPSFollowUpDocumentsSuccess,
  sendEPSFollowUpDocumentsError,
  sendInterviewDecisionError,
  sendInterviewDecisionSuccess,
  getCallidusInfoSuccess,
  getCallidusInfoError,
} from './actions';
import {
  GET_EPS_DATA_REQUEST,
  SEND_EPS_DATA_REQUEST,
  UPLOAD_EPS_DOCUMENT_REQUEST,
  SEND_EPS_FOLLOWUP_DOCUMENTS_REQUEST,
  SEND_INTERVIEW_DECISION,
  GET_CALLIDUS_INFO_REQUEST,
} from './actionTypes';
import { setUserData } from '../app/actions';

export function* getEPSData() {
  try {
    const useMock = process.env.REACT_APP_ENVIRONMENT === 'local' || false;

    if (useMock) {
      const epsMock = require('../../server/mock/eps-application.json');

      const eduMock = require('../../server/mock/educational-qualification.json');
      const countriesMock = require('../../server/mock/countries.json');
      const allowancesMock = require('../../server/mock/allowances.json');
      const occupationMock = require('../../server/mock/occupation.json');
      const userMock = require('../../server/mock/candidate.json');

      yield put(
        getEPSDataSuccess(
          eduMock.data,
          countriesMock.data,
          allowancesMock.data,
          occupationMock.data,
          epsMock.data
        )
      );
      yield put(setUserData(userMock.data.data || {}));
    } else {
      let token = yield select(makeSelectToken());

      if (token) {
        const eps = yield call(
          request,
          '/candidate/eps-application',
          'get',
          null,
          token
        );
        const {
          data: { success, data },
        } = eps;
        const { status } = success;
        if (status && data !== null) {
          const {
            scheme: { id },
          } = data;

          let epsApplicationId = {
            epsApplicationId: data.id,
          };

          const [
            edu,
            countries,
            allowances,
            occupation,
            user,
            race,
            nationality,
          ] = yield all([
            call(
              request,
              '/resources/education-qualification',
              'get',
              null,
              token
            ),
            call(request, '/resources/country', 'get', null, token),
            call(
              request,
              `/resources/eps-scheme/${id}/allowance`,
              'get',
              null,
              token
            ),
            call(request, '/resources/occupation', 'get', null, token),
            call(request, '/candidate', 'get', null, token),
            call(request, '/resources/race', 'get', null, token),
            call(request, '/resources/nationality', 'get', null, token),
          ]);
          yield put(
            getEPSDataSuccess(
              edu.data.data,
              countries.data.data,
              allowances.data.data,
              occupation.data.data,
              data,
              race.data.data,
              nationality.data.data
            )
          );
          const parsedData = {
            ...user.data.data,
            ...epsApplicationId,
          };
          yield put(setUserData(parsedData || {}));
        } else {
          yield put(
            getEPSDataError('Prospect is not invited, unable to proceed')
          );
        }
      } else {
        yield put(getEPSDataError('Token not provided'));
      }
    }
  } catch (err) {
    const { response } = err;
    yield call(unauthorizedHandler, response);
    yield put(getEPSDataError('Failed get EPS data'));
  }
}

export function* sendEPSData({ data, flag }) {
  try {
    let token = yield select(makeSelectToken());

    const url =
      flag === 'submit'
        ? 'candidate/eps-application/submit'
        : 'candidate/eps-application/save-draft';

    if (token) {
      const res = yield call(request, url, 'post', data, token);

      if (res.status === 200 && res.data.success.status) {
        yield put(
          sendEPSDataSuccess(res.data.success.message, res.data.data || {})
        );
      } else if (!res.data.success.status && res.data.success.message) {
        yield put(sendEPSDataError(res.data.success.message));
      } else {
        yield put(sendEPSDataError('Failed sending EPS Application'));
      }
    } else {
      yield put(sendEPSDataError('Token not provided'));
    }
  } catch (err) {
    const errorAction = flag === 'submit' ? 'submitting' : 'saving';
    const errorMessage = `Failed in ${errorAction} your EPS/FAS Application`;
    const { response } = err;
    yield call(unauthorizedHandler, response);
    yield put(sendEPSDataError(errorMessage));
  }
}

export function* interviewDecision({ data, flag }) {
  try {
    let token = yield select(makeSelectToken());

    let url = '';

    if (flag === 'accept') {
      url = 'candidate/eps-application/interview-schedule/accept';
    } else if (flag === 'decline') {
      url = 'candidate/eps-application/interview-schedule/decline';
    }

    if (token) {
      const res = yield call(request, url, 'post', data, token);

      if (res.status === 200 && res.data.success.status) {
        yield put(sendInterviewDecisionSuccess(res.data.success.message || ''));
      } else if (!res.data.success.status && res.data.success.message) {
        yield put(sendInterviewDecisionError(res.data.success.message));
      } else {
        yield put(
          sendInterviewDecisionError('Failed sending interview information')
        );
      }
    } else {
      yield put(sendInterviewDecisionError('Token not provided'));
    }
  } catch (err) {
    const errorMsg = err.response?.data?.success?.message;
    const { response } = err;
    yield call(unauthorizedHandler, response);
    yield put(sendInterviewDecisionError(errorMsg));
  }
}

export function* uploadDocument({ file }) {
  try {
    let token = yield select(makeSelectToken());

    const url = 'candidate/eps-application/document';

    if (token) {
      const res = yield call(request, url, 'post', { file }, token);
      if (res.status === 200 && res.data.success.status) {
        yield put(uploadEPSDocumentSuccess(res.data.success.message));
      } else if (!res.data.success.status && res.data.success.message) {
        yield put(uploadEPSDocumentError(res.data.success.message));
      } else {
        yield put(uploadEPSDocumentError('Error in uploading your file'));
      }
    } else {
      yield put(uploadEPSDocumentError('Token not provided'));
    }
  } catch (err) {
    const { response } = err;
    yield call(unauthorizedHandler, response);
    yield put(uploadEPSDocumentError('Error in uploading your file'));
  }
}

export function* sendFollowUpDocuments({ data }) {
  try {
    let token = yield select(makeSelectToken());

    if (token) {
      const res = yield call(
        request,
        'candidate/eps-application/submit-follow-up-docs',
        'post',
        data,
        token
      );

      if (res.status === 200 && res.data.success.status) {
        yield put(sendEPSFollowUpDocumentsSuccess(res.data.success.message));
      } else if (!res.data.success.status && res.data.success.message) {
        yield put(sendEPSFollowUpDocumentsError(res.data.success.message));
      } else {
        yield put(
          sendEPSFollowUpDocumentsError(
            'Failed sending EPS Application Follow Up documents'
          )
        );
      }
    } else {
      yield put(sendEPSDataError('Token not provided'));
    }
  } catch (err) {
    const errorMessage = 'Failed sending EPS Application Follow Up documents';
    const { response } = err;
    yield call(unauthorizedHandler, response);
    yield put(sendEPSDataError(errorMessage));
  }
}

export function* getCallidusInfoAction({ prospectId }) {
  try {
    let token = yield select(makeSelectToken());

    if (token) {
      const res = yield call(
        request,
        `resources/eps-application-case-key/${prospectId}`,
        'get',
        null,
        token
      );
      if (res.data.success.status) {
        yield put(getCallidusInfoSuccess(res.data.data));
      } else {
        yield put(
          getCallidusInfoError('Token not provided to get Callidus Info data')
        );
      }
    }
  } catch (err) {
    const { response } = err;
    yield call(unauthorizedHandler, response);
    if (err?.message) {
      // skip message alert if case key not found
      if (!err?.message.match(/code 417/g)) {
        yield put(
          getCallidusInfoError(
            `Failed get Callidus Info data : ${err?.message}`
          )
        );
      }
    }
  }
}

export default function* rootSaga() {
  yield takeLatest(GET_EPS_DATA_REQUEST, getEPSData);
  yield takeLatest(SEND_EPS_DATA_REQUEST, sendEPSData);
  yield takeLatest(UPLOAD_EPS_DOCUMENT_REQUEST, uploadDocument);
  yield takeLatest(SEND_EPS_FOLLOWUP_DOCUMENTS_REQUEST, sendFollowUpDocuments);
  yield takeLatest(SEND_INTERVIEW_DECISION, interviewDecision);
  yield takeLatest(GET_CALLIDUS_INFO_REQUEST, getCallidusInfoAction);
}
