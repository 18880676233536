import { call, put, takeLatest, all, select } from 'redux-saga/effects';
import request, { unauthorizedHandler } from '@utils/request';
import { makeSelectToken } from '@redux/app/selectors';

import {
  getDraftDataSuccess,
  getDraftDataError,
  saveDraftSuccess,
  saveDraftError,
  submitFormSuccess,
  submitFormError,
} from './actions';
import {
  GET_DRAFT_DATA_REQUEST,
  SAVE_DRAFT_DATA_REQUEST,
  SUBMIT_REEF_DATA_REQUEST,
} from './actionTypes';
import { setUserData } from '../app/actions';

function* getDraftData() {
  try {
    let token = yield select(makeSelectToken());

    if (token) {
      const res = yield call(request, '/candidate/reef', 'get', null, token);
      if (res.data.data) {
        const [countries, educ, user, institution] = yield all([
          call(request, '/resources/country', 'get', null, token),
          call(
            request,
            '/resources/reef-education-qualification',
            'get',
            null,
            token
          ),
          call(request, '/candidate', 'get', null, token),
          call(
            request,
            '/resources/private-education-institution',
            'get',
            null,
            token
          ),
        ]);

        yield put(
          getDraftDataSuccess(
            res.data.data,
            countries.data.data,
            educ.data.data,
            institution.data.data
          )
        );
        yield put(setUserData(user.data.data || {}));
      } else {
        yield put(getDraftDataError('Failed get REEF data'));
      }
    } else {
      yield put(getDraftDataError('Token not provided'));
    }
  } catch (err) {
    const { response } = err;
    yield call(unauthorizedHandler, response);
    yield put(getDraftDataError('Failed get REEF data'));
  }
}

function* saveDraftData({ data }) {
  try {
    let token = yield select(makeSelectToken());

    if (token) {
      const config = {
        headers: { 'Content-Type': 'application/json' },
      };

      const res = yield call(
        request,
        '/candidate/reef/save-draft',
        'post',
        data,
        token,
        config
      );
      if (res.data.success.status) {
        yield put(saveDraftSuccess(res.data.success.message));
      } else {
        yield put(saveDraftError('Failed send REEF form'));
      }
    } else {
      yield put(saveDraftError('Token not provided'));
    }
  } catch (err) {
    const { response } = err;
    yield call(unauthorizedHandler, response);
    yield put(saveDraftError('Failed send REEF form'));
  }
}

function* submitReefData({ data }) {
  try {
    let token = yield select(makeSelectToken());

    if (token) {
      const config = {
        headers: { 'Content-Type': 'application/json' },
      };

      const res = yield call(
        request,
        '/candidate/reef/submit',
        'post',
        data,
        token,
        config
      );
      if (res.data.success.status) {
        yield put(submitFormSuccess(res.data.success.message));
      } else {
        yield put(submitFormError('Failed send REEF form'));
      }
    } else {
      yield put(submitFormError('Token not provided'));
    }
  } catch (err) {
    const { response } = err;
    yield call(unauthorizedHandler, response);
    yield put(submitFormError('Failed send REEF form'));
  }
}

export default function* rootSaga() {
  yield takeLatest(GET_DRAFT_DATA_REQUEST, getDraftData);
  yield takeLatest(SAVE_DRAFT_DATA_REQUEST, saveDraftData);
  yield takeLatest(SUBMIT_REEF_DATA_REQUEST, submitReefData);
}
