export const SET_JOB_SOURCE_DATA = 'irecruit/assessment/SET_JOB_SOURCE_DATA';

export const GET_JOB_SOURCE_REQUEST =
  'irecruit/assessment/GET_JOB_SOURCE_REQUEST';

export const SAVE_JOB_SOURCE_REQUEST =
  'irecruit/assessment/SAVE_JOB_SOURCE_REQUEST';

export const PROCEED_JOB_SOURCE_SUCCESS =
  'irecruit/assessment/PROCEED_JOB_SOURCE_SUCCESS';
export const PROCEED_JOB_SOURCE_ERROR =
  'irecruit/assessment/PROCEED_JOB_SOURCE_ERROR';
