import { call, put, select, takeLatest } from 'redux-saga/effects';
import {
  callRequestWithToken,
  unauthorizedHandler,
} from '../../../../utils/request';
import {
  GET_EDUCATION_REQUEST,
  SAVE_EDUCATION_REQUEST,
  UPLOAD_DOCUMENTS_REQUEST,
  DELETE_DOCUMENTS_REQUEST,
} from './actionTypes';
import {
  proceedEducationSuccess,
  proceedEducationError,
  setEducationData,
} from './actions';
import {
  getEducationUrl,
  saveEducationUrl,
  getCommentsList,
  uploadFileUrl,
  deleteFileUrl,
  deleteByQuestionListURL,
  getFileListUrl,
} from '@utils/apis';
import { filter, keys, remove } from 'lodash';
import {
  EDUCATION_DOCUMENT_NAME_DIPLOMA,
  REEF_DOCUMENT_NAME,
} from '@pages/fscApplication/assessment/Education/business/constants';
import { setCertificationData } from '../certification/actions';
import { BOOLEAN_FLAG, PAGE_NAME } from '@utils/constants';
import { formatBytes } from '@utils/helper';
import { makeSelectRecruitmentProcessIds } from '@redux/fscApplication/eobCommonInfo/selectors';

export function* getEducationAction({ data }) {
  try {
    // get fields data
    const res = yield callRequestWithToken({
      method: 'post',
      url: getEducationUrl,
      params: data,
    });
    if (res.status === 200 && res.data.success.status) {
      const educationData = res.data.data.assessmentEducation;
      educationData.isFirstLoad = true;

      // get documents data
      const requestData = {
        recruitmentProcessId: data.recruitmentProcessId,
        pageName: PAGE_NAME.EDUCATION.pageKey,
      };
      const documentRes = yield callRequestWithToken({
        method: 'post',
        url: getFileListUrl,
        params: requestData,
      });
      if (documentRes.status === 201 && documentRes.data.success.status) {
        const documents = documentRes.data.data.fileList;

        const documentIds = [];
        documents.forEach((documentInfo) => {
          documentInfo.name = documentInfo.fileName;
          documentInfo.size = formatBytes(documentInfo.fileSize * 1);
          documentIds.push(documentInfo.id);
        });

        // get comments data
        const commentsRes = yield callRequestWithToken({
          method: 'post',
          url: getCommentsList,
          params: { fids: [educationData.id, ...documentIds] },
        });
        if (commentsRes.status === 200 && commentsRes.data.success.status) {
          educationData.comments =
            commentsRes.data.data.commentsList[educationData.id];

          educationData.documents = {};
          keys(REEF_DOCUMENT_NAME).forEach((key) => {
            const fileInfos = filter(documents, {
              question: key,
            });
            fileInfos.forEach((fileInfo) => {
              fileInfo.comments =
                commentsRes.data.data.commentsList[fileInfo.id];
            });
            educationData.documents[key] = fileInfos;
          });
          yield put(proceedEducationSuccess());
          yield put(setEducationData(educationData));
        } else if (
          !commentsRes.data.success.status &&
          commentsRes.data.success.message
        ) {
          yield put(proceedEducationError(commentsRes.data.success.message));
        } else {
          yield put(proceedEducationError('documentRes.data.success.message'));
        }
      } else if (
        !documentRes.data.success.status &&
        documentRes.data.success.message
      ) {
        yield put(proceedEducationError(documentRes.data.success.message));
      } else {
        yield put(proceedEducationError('commentsRes.data.success.message'));
      }
    } else if (!res.data.success.status && res.data.success.message) {
      yield put(proceedEducationError(res.data.success.message));
    } else {
      yield put(proceedEducationError('res.data.success.message'));
    }
  } catch (err) {
    const { response } = err;
    yield call(unauthorizedHandler, response);
    yield put(proceedEducationError(err.response?.data?.success?.message));
  }
}

export function* saveEducationAction({ data: combinedData, callback }) {
  try {
    const recruitmentProcessIds = yield select(
      makeSelectRecruitmentProcessIds()
    );

    let data = combinedData?.cloneData;
    const originalData = combinedData?.educationData?.toJS();

    const requestData = {
      recruitmentProcessId: recruitmentProcessIds.get('id'),
      assessmentEducation: {},
    };

    let needDeleteDocuments = false;
    if (
      data.fieldName === 'isHaveQualifications' &&
      data.value === BOOLEAN_FLAG.Y
    ) {
      data.isLocalResident = '';
      data.isHaveAdvisoryServices = '';
      data.companyName = '';
      data.companyStartDate = '';
      data.companyEndDate = '';
      data.selectHighestQualification = '';
      data.selectTypeOfStudy = '';
      data.certificateName = '';
      data.nameOfInstitute = '';
      data.educationStartDate = '';
      data.educationEndDate = '';
      data.durationOfStudy = '';
      data.numberOfStudyHours = '';
      data.institutionStudyCountry = '';
      data.isOriginalCertificate = '';
      data.status = '';
      needDeleteDocuments = true;
    } else if (data.fieldName === 'selectHighestQualification') {
      data.durationOfStudy = '';
      data.numberOfStudyHours = '';
      requestData.assessmentEducation.durationOfStudy = '';
      requestData.assessmentEducation.numberOfStudyHours = '';
      needDeleteDocuments = data[data.fieldName] === 'education_3';
    } else if (
      data.fieldName === 'isHaveAdvisoryServices' &&
      data.value === BOOLEAN_FLAG.N
    ) {
      data.companyName = '';
      data.companyStartDate = '';
      data.companyEndDate = '';
      requestData.assessmentEducation.companyName = '';
      requestData.assessmentEducation.companyStartDate = '';
      requestData.assessmentEducation.companyEndDate = '';
    } else if (data.fieldName === 'isOriginalCertificate') {
      needDeleteDocuments = true;
    }
    data[data.fieldName] = data.value;
    requestData.assessmentEducation[data.fieldName] = data.value;
    yield put(setEducationData(data));

    const res = yield callRequestWithToken({
      method: 'post',
      url: saveEducationUrl,
      params: requestData,
    });
    if (res.data.success.status) {
      if (needDeleteDocuments) {
        data.documents = {};
        yield put(setEducationData(data));

        const requestData = {
          pageName: PAGE_NAME.EDUCATION.pageKey,
          // section: 'education',
          questions:
            data.fieldName === 'selectHighestQualification'
              ? EDUCATION_DOCUMENT_NAME_DIPLOMA
              : keys(REEF_DOCUMENT_NAME),
          // item: documentName,
          id: recruitmentProcessIds.get('id'),
        };

        const deleteDocumentsRes = yield callRequestWithToken({
          method: 'post',
          url: deleteByQuestionListURL,
          params: requestData,
        });
        if (
          deleteDocumentsRes.status === 201 &&
          deleteDocumentsRes.data.success.status
        ) {
          yield put(proceedEducationSuccess());
        } else if (
          !deleteDocumentsRes.data.success.status &&
          deleteDocumentsRes.data.success.message
        ) {
          yield put(
            proceedEducationError(deleteDocumentsRes.data.success.message)
          );
          yield put(setEducationData(originalData));
        } else {
          yield put(
            proceedEducationError('deleteDocumentsRes.data.success.message')
          );
          yield put(setEducationData(originalData));
        }
      } else {
        yield put(proceedEducationSuccess());
        callback && callback();
      }
    } else if (!res.data.success.status && res.data.success.message) {
      yield put(proceedEducationError(res.data.success.message));
      yield put(setEducationData(originalData));
    } else {
      yield put(proceedEducationError('res.data.success.message'));
      yield put(setEducationData(originalData));
    }
  } catch (err) {
    const { response } = err;
    yield call(unauthorizedHandler, response);
    yield put(proceedEducationError(err.response?.data?.success?.message));
  }
}

export function* uploadDocumentsAction({ data: combinedData }) {
  try {
    const recruitmentProcessIds = yield select(
      makeSelectRecruitmentProcessIds()
    );

    const ref = combinedData?.ref;
    const buttonId = combinedData?.id;
    const uploadFile = combinedData?.uploadFile;
    let cloneData = {};
    if (keys(REEF_DOCUMENT_NAME).includes(buttonId)) {
      cloneData = combinedData?.educationData?.toJS();
    } else {
      cloneData = combinedData?.certificationData?.toJS();
    }

    const originalData = cloneData;
    const fileSizeString = formatBytes(uploadFile.size);
    const fileInfoList =
      cloneData.documents && cloneData.documents[buttonId]
        ? cloneData.documents[buttonId]
        : [];

    const newFile = {
      id: buttonId,
      uploadStatus: 'uploading',
      uploadingText: `${0} / ${fileSizeString}`,
      listFile: [...fileInfoList],
    };
    ref.current.handleUpdateFile(newFile);

    const requestData = {
      recruitmentProcessId: recruitmentProcessIds.get('id'),
      pageName: keys(REEF_DOCUMENT_NAME).includes(buttonId)
        ? PAGE_NAME.EDUCATION.pageKey
        : PAGE_NAME.CMFAS_CERTIFICATION.pageKey,
      question: buttonId,
      // item: buttonId,
      file: uploadFile,
    };
    const config = {
      headers: { 'Content-Type': 'multipart/form-data' },
    };
    const res = yield callRequestWithToken({
      method: 'post',
      url: uploadFileUrl,
      params: requestData,
      config,
    });

    if (res.status === 201 && res.data.success.status) {
      const resFile = res.data.data.recruitmentProcessFile;
      const fileItem = {
        name: resFile.fileName,
        size: formatBytes(resFile.fileSize * 1),
        id: resFile.id,
      };
      fileInfoList.push(fileItem);

      ref.current.handleUpdateFile({
        ...newFile,
        uploadStatus: 'completed',
        uploadingText: `${fileSizeString} / ${fileSizeString}`,
        listFile: [...fileInfoList],
      });
      cloneData.documents[buttonId] = fileInfoList;
      if (keys(REEF_DOCUMENT_NAME).includes(buttonId)) {
        yield put(setEducationData(cloneData));
      } else {
        yield put(setCertificationData(cloneData));
      }
      yield put(proceedEducationSuccess());
    } else if (!res.data.success.status && res.data.success.message) {
      yield put(proceedEducationError(res.data.success.message));
      ref.current.handleUpdateFile({
        ...newFile,
        uploadStatus: 'error',
        uploadingText: 'Upload failed.',
        listFile: [...fileInfoList],
      });
      if (keys(REEF_DOCUMENT_NAME).includes(buttonId)) {
        yield put(setEducationData(originalData));
      } else {
        yield put(setCertificationData(originalData));
      }
    } else {
      yield put(proceedEducationError('res.data.success.message'));
      ref.current.handleUpdateFile({
        ...newFile,
        uploadStatus: 'error',
        uploadingText: `${fileSizeString} / ${fileSizeString}`,
        listFile: [...fileInfoList],
      });
      if (keys(REEF_DOCUMENT_NAME).includes(buttonId)) {
        yield put(setEducationData(originalData));
      } else {
        yield put(setCertificationData(originalData));
      }
    }
  } catch (err) {
    const { response } = err;
    yield call(unauthorizedHandler, response);
    yield put(proceedEducationError(err.response?.data?.success?.message));
  }
}

export function* deleteDocumentsAction({ data: combinedData }) {
  try {
    const ref = combinedData?.ref;
    const fileInfo = combinedData?.fileInfo;
    const buttonId = combinedData?.id;
    let cloneData = combinedData?.educationData?.toJS();
    if (keys(REEF_DOCUMENT_NAME).includes(buttonId)) {
      cloneData = combinedData?.educationData?.toJS();
    } else {
      cloneData = combinedData?.certificationData?.toJS();
    }
    const fileInfoList =
      cloneData.documents && cloneData.documents[buttonId]
        ? cloneData.documents[buttonId]
        : [];

    const requestData = {
      id: fileInfo.id,
    };
    const res = yield callRequestWithToken({
      method: 'post',
      url: deleteFileUrl,
      params: requestData,
    });

    if (res.status === 201 && res.data.success.status) {
      ref.current.handleDeleteFile(fileInfo);
      remove(fileInfoList, fileInfo);
      cloneData.documents[buttonId] = fileInfoList;
      if (keys(REEF_DOCUMENT_NAME).includes(buttonId)) {
        yield put(setEducationData(cloneData));
      } else {
        yield put(setCertificationData(cloneData));
      }
    } else if (!res.data.success.status && res.data.success.message) {
      yield put(proceedEducationError(res.data.success.message));
    } else {
      yield put(proceedEducationError('res.data.success.message'));
    }
  } catch (err) {
    const { response } = err;
    yield call(unauthorizedHandler, response);
    yield put(proceedEducationError(err.response?.data?.success?.message));
  }
}

export default function* rootSaga() {
  yield takeLatest(GET_EDUCATION_REQUEST, getEducationAction);
  yield takeLatest(SAVE_EDUCATION_REQUEST, saveEducationAction);
  yield takeLatest(UPLOAD_DOCUMENTS_REQUEST, uploadDocumentsAction);
  yield takeLatest(DELETE_DOCUMENTS_REQUEST, deleteDocumentsAction);
}
