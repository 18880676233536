export const GET_EPS_DATA_REQUEST = 'irecruit/eps/GET_EPS_DATA_REQUEST';
export const GET_EPS_DATA_SUCCESS = 'irecruit/eps/GET_EPS_DATA_SUCCESS';
export const GET_EPS_DATA_ERROR = 'irecruit/eps/GET_EPS_DATA_ERROR';

export const SEND_EPS_DATA_REQUEST = 'irecruit/eps/SEND_EPS_DATA_REQUEST';
export const SEND_EPS_DATA_SUCCESS = 'irecruit/eps/SEND_EPS_DATA_SUCCESS';
export const SEND_EPS_DATA_ERROR = 'irecruit/eps/SEND_EPS_DATA_ERROR';

export const UPLOAD_EPS_DOCUMENT_REQUEST =
  'irecruit/eps/UPLOAD_EPS_DOCUMENT_REQUEST';
export const UPLOAD_EPS_DOCUMENT_SUCCESS =
  'irecruit/eps/UPLOAD_EPS_DOCUMENT_SUCCESS';
export const UPLOAD_EPS_DOCUMENT_ERROR =
  'irecruit/eps/UPLOAD_EPS_DOCUMENT_ERROR';

export const SEND_EPS_FOLLOWUP_DOCUMENTS_REQUEST =
  'irecruit/eps/SEND_EPS_FOLLOWUP_DOCUMENTS_REQUEST';
export const SEND_EPS_FOLLOWUP_DOCUMENTS_REQUEST_SUCCESS =
  'irecruit/eps/SEND_EPS_FOLLOWUP_DOCUMENTS_REQUEST_SUCCESS';
export const SEND_EPS_FOLLOWUP_DOCUMENTS_REQUEST_ERROR =
  'irecruit/eps/SEND_EPS_FOLLOWUP_DOCUMENTS_REQUEST_ERROR';

export const SEND_INTERVIEW_DECISION = 'irecruit/eps/SEND_INTERVIEW_DECISION';
export const SEND_INTERVIEW_DECISION_SUCCESS =
  'irecruit/eps/SEND_INTERVIEW_DECISION_SUCCESS';
export const SEND_INTERVIEW_DECISION_ERROR =
  'irecruit/eps/SEND_INTERVIEW_DECISION_ERROR';

export const GET_CALLIDUS_INFO_REQUEST =
  'irecruit/eps/GET_CALLIDUS_INFO_REQUEST';
export const GET_CALLIDUS_INFO_SUCCESS =
  'irecruit/eps/GET_CALLIDUS_INFO_SUCCESS';
export const GET_CALLIDUS_INFO_ERROR = 'irecruit/eps/GET_CALLIDUS_INFO_ERROR';
