import { fromJS } from 'immutable';
import {
  GET_MYINFO_INITIAL_DATA_REQUEST,
  GET_MYINFO_INITIAL_DATA_SUCCESS,
  GET_MYINFO_INITIAL_DATA_ERROR,
  PROCEED_MYINFO_REQUEST,
  PROCEED_MYINFO_SUCCESS,
  PROCEED_MYINFO_ERROR,
} from './actionTypes';

const initialState = fromJS({
  loading: false,
  error: false,
  success: false,
  message: null,
  myInfoData: {},
  isSubmitting: false,
  isSubmitted: false,
});

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_MYINFO_INITIAL_DATA_REQUEST:
      return state
        .set('loading', true)
        .set('success', false)
        .set('error', false)
        .set('message', null)
        .set('myInfoData', fromJS({}));
    case GET_MYINFO_INITIAL_DATA_SUCCESS:
      return state
        .set('loading', false)
        .set('success', true)
        .set('myInfoData', fromJS(action.data));
    case GET_MYINFO_INITIAL_DATA_ERROR:
      return state
        .set('loading', false)
        .set('error', true)
        .set('message', action.message)
        .set('myInfoData', fromJS({}));

    case PROCEED_MYINFO_REQUEST:
      return state
        .set('loading', true)
        .set('success', false)
        .set('error', false)
        .set('isSubmitting', true)
        .set('message', null);
    case PROCEED_MYINFO_SUCCESS:
      return state
        .set('loading', false)
        .set('success', true)
        .set('isSubmitting', false)
        .set('isSubmitted', true)
        .set('message', action.message);
    case PROCEED_MYINFO_ERROR:
      return state
        .set('loading', false)
        .set('error', true)
        .set('isSubmitting', false)
        .set('message', action.message);
    default:
      return state;
  }
}
