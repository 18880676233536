import { createSelector } from 'reselect';
import includes from 'lodash/includes';

const selectPersonalCommonData = (state) => state.personalCommon;

export const makeSelectPersonalRequest = () =>
  createSelector(selectPersonalCommonData, (personalCommonState) =>
    personalCommonState.filter((value, key) =>
      includes(['loading', 'error', 'success', 'message', 'isSubmitting'], key)
    )
  );

// basic file
export const makeSelectIconFileData = () =>
  createSelector(selectPersonalCommonData, (personalCommonState) =>
    personalCommonState.get('iconFileData')
  );
export const makeSelectBasicFileData = () =>
  createSelector(selectPersonalCommonData, (personalCommonState) =>
    personalCommonState.get('basicFileData')
  );
export const makeSelectWorkFileData = () =>
  createSelector(selectPersonalCommonData, (personalCommonState) =>
    personalCommonState.get('workFileData')
  );
// comments
export const makeSelectPersonalCommentData = () =>
  createSelector(selectPersonalCommonData, (personalCommonState) =>
    personalCommonState.get('personalCommentData')
  );

export const makeSelectIsInitCompleted = () =>
  createSelector(selectPersonalCommonData, (personalCommonState) =>
    personalCommonState.get('isInitCompleted')
  );
