import {
  GET_MYINFO_INITIAL_DATA_REQUEST,
  GET_MYINFO_INITIAL_DATA_SUCCESS,
  GET_MYINFO_INITIAL_DATA_ERROR,
  PROCEED_MYINFO_REQUEST,
  PROCEED_MYINFO_SUCCESS,
  PROCEED_MYINFO_ERROR,
} from './actionTypes';

export const getMyinfoInitialDataRequest = (data) => ({
  type: GET_MYINFO_INITIAL_DATA_REQUEST,
  data,
});

export const getMyinfoInitialDataSuccess = (data) => ({
  type: GET_MYINFO_INITIAL_DATA_SUCCESS,
  data,
});

export const getMyinfoInitialDataError = (message) => ({
  type: GET_MYINFO_INITIAL_DATA_ERROR,
  message,
});

export const proceedMyinfoRequest = (data) => ({
  type: PROCEED_MYINFO_REQUEST,
  data,
});

export const proceedMyinfoSuccess = (message) => ({
  type: PROCEED_MYINFO_SUCCESS,
  message,
});

export const proceedMyinfoError = (message) => ({
  type: PROCEED_MYINFO_ERROR,
  message,
});
