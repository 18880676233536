import { init as initApm } from '@elastic/apm-rum';

let elkApm = null;

if (process.env.REACT_APP_APM_ELK_URL) {
  elkApm = initApm({
    serviceName: 'irecruit-prospect-portal-webapp',
    serverUrl: process.env.REACT_APP_APM_ELK_URL,
    serviceVersion: '0.1.0',
    environment: process.env.REACT_APP_ENVIRONMENT,
    centralConfig: false,
  });
}
export default elkApm;
