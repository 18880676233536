import { fromJS } from 'immutable';
import {
  GET_EMPLOYMENTHISTORY_REQUEST,
  SET_EMPLOYMENTHISTORY_DATA,
  SET_EMPLOYMENTGAP_DATA,
  SAVE_EMPLOYMENTHISTORY_REQUEST,
  SAVE_EMPLOYMENTGAP_REQUEST,
  PROCEED_EMPLOYMENTHISTORY_SUCCESS,
  PROCEED_EMPLOYMENTHISTORY_ERROR,
  DELETE_EMPLOYMENTHISTORY_REQUEST,
  UPDATE_EMPLOYMENTHISTORY_REQUEST,
  SET_INITIAL_STATE,
  SET_IS_INIT_COMPLETED,
} from './actionTypes';

const initialState = fromJS({
  employmentHistoryData: [],
  isInitCompleted: false,
  employmentGapData: [],
  success: false,
  loading: false,
  error: false,
  isSubmitting: false,
  isSubmitted: false,
  message: null,
});

export default function (state = initialState, action) {
  switch (action.type) {
    case SET_INITIAL_STATE:
      return initialState;
    case SET_IS_INIT_COMPLETED:
      return state.set('isInitCompleted', action.data);
    case SET_EMPLOYMENTHISTORY_DATA:
      return state.set('employmentHistoryData', fromJS(action.data));
    case SET_EMPLOYMENTGAP_DATA:
      return state.set('employmentGapData', fromJS(action.data));
    case GET_EMPLOYMENTHISTORY_REQUEST:
      return state
        .set('loading', true)
        .set('success', false)
        .set('error', false)
        .set('message', null);
    case SAVE_EMPLOYMENTHISTORY_REQUEST:
      return state
        .set('success', false)
        .set('error', false)
        .set('isSubmitting', true)
        .set('message', null);
    case SAVE_EMPLOYMENTGAP_REQUEST:
      return state
        .set('loading', true)
        .set('success', false)
        .set('error', false)
        .set('isSubmitting', true)
        .set('message', null);
    case PROCEED_EMPLOYMENTHISTORY_SUCCESS:
      return state
        .set('loading', false)
        .set('success', true)
        .set('isSubmitting', false)
        .set('isSubmitted', true)
        .set('message', action.message);
    case PROCEED_EMPLOYMENTHISTORY_ERROR:
      return state
        .set('loading', false)
        .set('error', true)
        .set('isSubmitting', false)
        .set('message', action.message);
    case DELETE_EMPLOYMENTHISTORY_REQUEST:
      return state
        .set('loading', true)
        .set('success', false)
        .set('error', false)
        .set('message', null);

    case UPDATE_EMPLOYMENTHISTORY_REQUEST:
      return state
        .set('loading', true)
        .set('success', false)
        .set('error', false)
        .set('message', null);

    default:
      return state;
  }
}
