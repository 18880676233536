export const GET_INTERVIEW_SLOT_DETAIL_REQUEST =
  'irecruit/bdmInterviewRSVP/GET_INTERVIEW_SLOT_DETAIL_REQUEST';
export const GET_INTERVIEW_SLOT_DETAILL_SUCCESS =
  'irecruit/bdmInterviewRSVP/GET_INTERVIEW_SLOT_DETAILL_SUCCESS';
export const GET_INTERVIEW_SLOT_DETAIL_ERROR =
  'irecruit/bdmInterviewRSVP/GET_INTERVIEW_SLOT_DETAIL_ERROR';

export const SUBMIT_INTERVIEW_SLOT_REQUEST =
  'irecruit/bdmInterviewRSVP/SUBMIT_INTERVIEW_SLOT_REQUEST';
export const SUBMIT_INTERVIEW_SLOT_SUCCESS =
  'irecruit/bdmInterviewRSVP/SUBMIT_INTERVIEW_SLOT_SUCCESS';
export const SUBMIT_INTERVIEW_SLOT_ERROR =
  'irecruit/bdmInterviewRSVP/SUBMIT_INTERVIEW_SLOT_ERROR';

export const SET_INTERVIEW_SLOT_DATA =
  'irecruit/bdmInterviewRSVP/SET_INTERVIEW_SLOT_DATA';

export const SET_INITIAL_STATE = 'irecruit/bdmInterviewRSVP/SET_INITIAL_STATE';

export const SET_INTERVIEW_STATUS_DATA =
  'irecruit/bdmInterviewRSVP/SET_INTERVIEW_STATUS_DATA';
