import { call, put, takeLatest, select } from 'redux-saga/effects';
import request, { unauthorizedHandler } from '@utils/request';
import { makeSelectToken } from '@redux/app/selectors';

import {
  getSigningUrlSuccess,
  getSigningUrlError,
  postSignerStatusSuccess,
  postSignerStatusError,
} from './actions';

import {
  GET_SIGNING_URL_REQUEST,
  POST_SIGNER_STATUS_REQUEST,
} from './actionTypes';

function* getSigningUrl({ signType }) {
  try {
    let token = yield select(makeSelectToken());

    if (token) {
      const res = yield call(
        request,
        `/candidate/remote-signing/generate-link/${signType}`,
        'post',
        null,
        token
      );
      if (res.data.success.status) {
        sessionStorage.removeItem('pendingKofax');
        yield put(getSigningUrlSuccess(res.data.data));
      } else {
        sessionStorage.removeItem('pendingKofax');
        yield put(getSigningUrlError('Failed get contract data'));
      }
    } else {
      yield put(getSigningUrlError('Token not provided'));
    }
  } catch (err) {
    const { response } = err;
    yield call(unauthorizedHandler, response);
    yield put(getSigningUrlError(err.response?.data?.success?.message));
  }
}

export function* postSignerStatusAction({ epsId }) {
  try {
    let token = yield select(makeSelectToken());

    if (token) {
      const result = yield call(
        request,
        `/candidate/remote-signing/${epsId}/set-signature-status/signer-app`,
        'post',
        null,
        token
      );
      if (result?.data?.success?.status === true) {
        yield put(postSignerStatusSuccess(true));
      } else {
        yield put(postSignerStatusError('Failed post the signature status'));
      }
    }
  } catch (err) {
    const { response } = err;
    yield call(unauthorizedHandler, response);
    yield put(postSignerStatusError('Failed post the signature status'));
  }
}

export default function* rootSaga() {
  yield takeLatest(GET_SIGNING_URL_REQUEST, getSigningUrl);
  yield takeLatest(POST_SIGNER_STATUS_REQUEST, postSignerStatusAction);
}
