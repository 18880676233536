import Immutable, { fromJS } from 'immutable';
import {
  GET_STUDENT_CONVERSION_STATUS_REQUEST,
  GET_STUDENT_CONVERSION_STATUS_SUCCESS,
  GET_STUDENT_CONVERSION_STATUS_ERROR,
  SAVE_DRAFT_STUDENT_CONVERSION_REQUEST,
  SAVE_DRAFT_STUDENT_CONVERSION_SUCCESS,
  SAVE_DRAFT_STUDENT_CONVERSION_ERROR,
  UPLOAD_FILE_STUDENT_CONVERSION_REQUEST,
  UPLOAD_FILE_STUDENT_CONVERSION_SUCCESS,
  UPLOAD_FILE_STUDENT_CONVERSION_ERROR,
  DELETE_FILE_STUDENT_CONVERSION_REQUEST,
  DELETE_FILE_STUDENT_CONVERSION_SUCCESS,
  DELETE_FILE_STUDENT_CONVERSION_ERROR,
  GET_STUDENT_SIGNING_URL_REQUEST,
  GET_STUDENT_SIGNING_URL_SUCCESS,
  GET_STUDENT_SIGNING_URL_ERROR,
} from './actionTypes';

const initialState = fromJS({
  studentConversionStatus: {},
  signUrl: '',
  loading: false,
  error: false,
  success: false,
  message: null,
});

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_STUDENT_CONVERSION_STATUS_REQUEST:
    case SAVE_DRAFT_STUDENT_CONVERSION_REQUEST:
    case UPLOAD_FILE_STUDENT_CONVERSION_REQUEST:
    case DELETE_FILE_STUDENT_CONVERSION_REQUEST:
    case GET_STUDENT_SIGNING_URL_REQUEST:
      return state
        .set('loading', true)
        .set('success', false)
        .set('error', false)
        .set('message', null);
    case GET_STUDENT_CONVERSION_STATUS_SUCCESS:
      return state
        .set('loading', false)
        .set('success', true)
        .set('studentConversionStatus', fromJS(action.data));
    case SAVE_DRAFT_STUDENT_CONVERSION_SUCCESS:
      return state
        .set('loading', false)
        .set('success', true)
        .set(
          'studentConversionStatus',
          state
            .get('studentConversionStatus')
            .set('epsScheme', fromJS(action.data))
        );
    case UPLOAD_FILE_STUDENT_CONVERSION_SUCCESS: {
      const newFile = action.data;

      return state
        .set('loading', false)
        .set('success', true)
        .updateIn(['studentConversionStatus', 'files'], (files) =>
          files.push(newFile)
        );
    }
    case DELETE_FILE_STUDENT_CONVERSION_SUCCESS: {
      const deletedFileId = action.data;

      // returns immutable currentFiles list
      let currentFiles = state.getIn(['studentConversionStatus', 'files']);

      // filter out the deleted file from currentList in array format
      currentFiles = currentFiles
        ?.toJS()
        .filter((file) => file.id !== deletedFileId);

      // convert currentFiles back to immutable list format
      currentFiles = Immutable.List(currentFiles);

      return state
        .set('loading', false)
        .set('success', true)
        .setIn(['studentConversionStatus', 'files'], currentFiles);
    }
    case GET_STUDENT_SIGNING_URL_SUCCESS:
      return state
        .set('loading', false)
        .set('success', true)
        .set('signUrl', fromJS(action.signUrl));
    case GET_STUDENT_CONVERSION_STATUS_ERROR:
    case SAVE_DRAFT_STUDENT_CONVERSION_ERROR:
    case UPLOAD_FILE_STUDENT_CONVERSION_ERROR:
    case DELETE_FILE_STUDENT_CONVERSION_ERROR:
    case GET_STUDENT_SIGNING_URL_ERROR:
      return state
        .set('loading', false)
        .set('error', true)
        .set('message', action.message)
        .set('studentConversionStatus', fromJS({}));
    default:
      return state;
  }
}
