import { call, put, select, takeLatest } from 'redux-saga/effects';
import {
  callRequestWithToken,
  unauthorizedHandler,
} from '../../../utils/request';
import { formatBytes } from '@utils/helper';

import {
  getFinancialInformationUrl,
  saveCheckQuestionUrl,
  deleteFinancialCreditBureauReportUrl,
  deleteFinancialCountryOfTaxResidencetUrl,
  getAllComments,
  // add save interface
  saveFinancialBankUrl,
  // add report
  saveFinancialReportUrl,
  // add report credit
  saveFinancialReportCreditortUrl,
  // add country residence
  saveFinancialCountryResidenceUrl,
  // add fact
  saveFinancialFACTUrl,
  // handle file
  uploadFileUrl,
  deleteFileUrl,
} from '../../../utils/apis';

import {
  GET_FINANCIAL_INFO_REQUEST,
  SAVE_CHECK_QUESTION_REQUEST,
  DELETE_FINANCIAL_CREDITBUREAUREPORT_REQUEST,
  DELETE_FINANCIAL_COUNTRYOFTAXRESIDENCE_REQUEST,
  GET_ALL_COMMENTS_REQUEST,
  // add save interface
  SAVE_FINANCIAL_BANK_REQUEST,
  // add report interface
  SAVE_FINANCIAL_REPORT_REQUEST,
  // add report credit
  SAVE_FINANCIAL_REPORT_CREDITOR_REQUEST,
  // add country residence
  SAVE_FINANCIAL_COUNTRY_RESIDENCE_REQUEST,
  // add fact
  SAVE_FINANCIAL_FACT_REQUEST,
  // handle file
  UPLOAD_DOCUMENTS_REQUEST,
  DELETE_DOCUMENTS_REQUEST,
} from './actionTypes';
import {
  getFinancialInfoDataSuccess,
  getFinancialInfoDataError,
  setFinancialInfoDataRequest,
  setCommentsData,
  getCommentsDataSuccess,
  getCommentsDataError,
  saveCheckQuestionSuccess,
  saveCheckQuestionError,
  // add save interface
  saveFinancialBankDataSuccess,
  saveFinancialBankDataError,
  // add report
  saveFinancialReportDataSuccess,
  saveFinancialReportDataError,
  // add report credit
  saveFinancialReporCreditortDataSuccess,
  saveFinancialReporCreditortDataError,
  // add country residence
  saveFinancialCountryResidenceDataSuccess,
  saveFinancialCountryResidenceDataError,
  // add fact
  saveFinancialFactDataSuccess,
  saveFinancialFactDataError,
  // delete credit
  deleteFinancialCreditBureauReportError,
  // delete country residence
  deleteFinancialCountryOfTaxResidenceError,
  // handle file
  uploadDocumentsSuccess,
  uploadDocumentsError,
  deleteDocumentsSuccess,
  deleteDocumentsError,
  setIsImmediateLoading,
} from './actions';
import { makeSelectFinancialInfo } from './selectors';
import { FINANCIALFLIEDNAME } from '@pages/fscApplication/FinancialInformation/business/constants';

// get financial information
export function* getFinancialInfoAction({ data }) {
  try {
    const storeData = yield select(makeSelectFinancialInfo());
    let financialData = storeData?.toJS();

    if (financialData?.creditBureauReportResult) {
      delete financialData.creditBureauReportResult;
    }
    yield put(setFinancialInfoDataRequest(financialData));

    const res = yield callRequestWithToken({
      method: 'post',
      url: getFinancialInformationUrl,
      params: data,
    });
    if (res.status === 200 && res.data.success.message === 'Success') {
      const taxData = res.data.data.financialResult.countryTaxResidenceResult;
      if (!taxData.countryArray || !taxData.countryArray.length) {
        taxData.countryArray = [
          {
            id: '',
            answer: '',
            countryTaxResidence: '',
            detail: '',
            taxIdentificationNumber: '',
          },
        ];
      }
      res.data.data.financialResult.isDataLoadCompleted = true;
      yield put(setFinancialInfoDataRequest(res.data.data.financialResult));
      yield put(getFinancialInfoDataSuccess('Successfully get success data'));
    }
  } catch (err) {
    const { response } = err;
    yield call(unauthorizedHandler, response);
    yield put(getFinancialInfoDataError(err.response?.data?.success?.message));
  }
}
//  add save bank interface
export function* saveFinancialBankAction({ data }) {
  try {
    const res = yield callRequestWithToken({
      method: 'post',
      url: saveFinancialBankUrl,
      params: data,
    });
    if (res.status === 200 && res.data.success.message === 'Success') {
      const storeData = yield select(makeSelectFinancialInfo());
      let newFinancialData = storeData.toJS();
      newFinancialData.bankInfoResult[data.fieldName] = data.fieldValue;
      if (!data.id || data.id !== res.data.data.result.id) {
        newFinancialData.bankInfoResult.id = res.data.data.result.id;
      }
      yield put(setFinancialInfoDataRequest(newFinancialData));
      yield put(saveFinancialBankDataSuccess('Save data success!'));
    }
  } catch (err) {
    const { response } = err;
    yield call(unauthorizedHandler, response);
    yield put(saveFinancialBankDataError(err.response?.data?.success?.message));
  }
}
//  add save report
export function* saveFinancialReportAction({ data }) {
  try {
    const res = yield callRequestWithToken({
      method: 'post',
      url: saveFinancialReportUrl,
      params: data,
    });
    if (res.status === 200 && res.data.success.message === 'Success') {
      const storeData = yield select(makeSelectFinancialInfo());
      let newFinancialData = storeData.toJS();
      if (!newFinancialData.creditBureauReportResult.id) {
        newFinancialData.creditBureauReportResult.id =
          res.data.data?.result?.id ?? '';
      }
      if (data.fieldName === 'answer') {
        newFinancialData.creditBureauReportResult.answer = data.fieldValue;
      }
      if (data.fieldName === 'reportDate') {
        newFinancialData.creditBureauReportResult.reportDate = data.fieldValue;
      }
      yield put(setFinancialInfoDataRequest(newFinancialData));
      yield put(saveFinancialReportDataSuccess('Save data success!'));
    }
  } catch (err) {
    const { response } = err;
    yield call(unauthorizedHandler, response);
    yield put(
      saveFinancialReportDataError(err.response?.data?.success?.message)
    );
  }
}
//  add save report credit
export function* saveFinancialReporCreditortDataAction({ data }) {
  try {
    // if there is no id, need loading immediately
    const isImmediateLoading = !data.id;
    yield put(setIsImmediateLoading(isImmediateLoading));

    const res = yield callRequestWithToken({
      method: 'post',
      url: saveFinancialReportCreditortUrl,
      params: data,
    });
    if (res.status === 200 && res.data.success.message === 'Success') {
      // reset to delay mode
      if (isImmediateLoading) {
        yield put(setIsImmediateLoading(!isImmediateLoading));
      }

      const storeData = yield select(makeSelectFinancialInfo());
      let newFinancialData = storeData.toJS();

      if (data.fieldName === 'add') {
        const creditReportData = {
          creditorName: '',
          repaymentAmount: '',
          currentLoanBalance: '',
          remainingPaymentTerms: '',
          typeOfLoan: '',
          id: res.data.data.result.id,
        };
        newFinancialData.creditBureauReportResult.creditor.push(
          creditReportData
        );
      } else {
        const curCredit =
          newFinancialData.creditBureauReportResult.creditor.find(
            (item) => item.id === data.id
          );
        curCredit[data.fieldName] = data.fieldValue;
      }
      yield put(setFinancialInfoDataRequest(newFinancialData));
      yield put(saveFinancialReporCreditortDataSuccess('Save data success!'));
    }
  } catch (err) {
    const { response } = err;
    yield call(unauthorizedHandler, response);
    yield put(
      saveFinancialReporCreditortDataError(err.response?.data?.success?.message)
    );
  }
}
// add save country residence
export function* saveFinancialCountryResidenceDataAction({ data }) {
  try {
    // if there is no id, need loading immediately
    const isImmediateLoading = !data.id;
    yield put(setIsImmediateLoading(isImmediateLoading));

    const res = yield callRequestWithToken({
      method: 'post',
      url: saveFinancialCountryResidenceUrl,
      params: data,
    });

    if (res.status === 200 && res.data.success.message === 'Success') {
      // reset to delay mode
      if (isImmediateLoading) {
        yield put(setIsImmediateLoading(!isImmediateLoading));
      }

      const storeData = yield select(makeSelectFinancialInfo());
      let newFinancialData = storeData.toJS();

      if (data.fieldName === 'add') {
        const taxResidenceData = {
          countryTaxResidence: '',
          taxIdentificationNumber: '',
          answer: '',
          detail: '',
          id: res.data.data.result.id,
        };
        newFinancialData.countryTaxResidenceResult.countryArray.push(
          taxResidenceData
        );
      } else {
        const curResidence =
          newFinancialData.countryTaxResidenceResult.countryArray.find(
            (item) => item.id === data.id
          );
        if (!data.id || data.id !== res.data.data.result.id) {
          curResidence.id = res.data.data.result.id;
        }
        if (data.fieldName === 'sync') {
          curResidence.countryTaxResidence =
            data.fieldValue.countryTaxResidence;
          curResidence.taxIdentificationNumber =
            data.fieldValue.taxIdentificationNumber;
          curResidence.isSyncFromPersonalInfo =
            data.fieldValue.isSyncFromPersonalInfo;
        } else {
          curResidence[data.fieldName] = data.fieldValue;
          if (
            data.fieldName ===
              FINANCIALFLIEDNAME.COUNTRYRESIDENCE.TAXIDENTIFICATIONNUMBER &&
            data.fieldValue?.length > 0
          ) {
            curResidence.answer = '';
            curResidence.detail = '';
          }
        }
      }
      yield put(setFinancialInfoDataRequest(newFinancialData));
      yield put(saveFinancialCountryResidenceDataSuccess('Save data success!'));
    }
  } catch (err) {
    const { response } = err;
    yield call(unauthorizedHandler, response);
    yield put(
      saveFinancialCountryResidenceDataError(
        err.response?.data?.success?.message
      )
    );
  }
}
// add save Fact
export function* saveFinancialFactDataAction({ data }) {
  try {
    const res = yield callRequestWithToken({
      method: 'post',
      url: saveFinancialFACTUrl,
      params: data,
    });
    if (res.status === 200 && res.data.success.message === 'Success') {
      const storeData = yield select(makeSelectFinancialInfo());
      let newFinancialData = storeData.toJS();
      if (!data.id || data.id !== res.data.data.result.id) {
        newFinancialData.fatcaDeclarationResult.id = res.data.data.result.id;
      }
      newFinancialData.fatcaDeclarationResult[data.fieldName] = data.fieldValue;
      yield put(setFinancialInfoDataRequest(newFinancialData));
      yield put(saveFinancialFactDataSuccess('Save data success!'));
    }
  } catch (err) {
    const { response } = err;
    yield call(unauthorizedHandler, response);
    yield put(saveFinancialFactDataError(err.response?.data?.success?.message));
  }
}
// save checkbox
export function* saveCheckQuestionAction({ data }) {
  try {
    const res = yield callRequestWithToken({
      method: 'post',
      url: saveCheckQuestionUrl,
      params: data,
    });
    if (res.status === 200 && res.data.success.message === 'Success') {
      const storeData = yield select(makeSelectFinancialInfo());
      let newFinancialData = storeData.toJS();
      if (!data.id || (data.id && data.answer)) {
        newFinancialData.fatcaDeclarationResult.select.push({
          answer: data.answer,
          id: res.data.data.result.id,
          question: 'FATCA',
        });
      }
      if (data.id && !data.answer) {
        const ind = newFinancialData.fatcaDeclarationResult.select.findIndex(
          (item) => item.id === data.id
        );
        newFinancialData.fatcaDeclarationResult.select.splice(ind, 1);
      }
      yield put(setFinancialInfoDataRequest(newFinancialData));
      yield put(saveCheckQuestionSuccess('Save data success!'));
    }
  } catch (err) {
    const { response } = err;
    yield call(unauthorizedHandler, response);
    yield put(saveCheckQuestionError(err.response?.data?.success?.message));
  }
}
// delete credit
export function* deleteFinancialCreditBureauReportAction({ data }) {
  try {
    const res = yield callRequestWithToken({
      method: 'post',
      url: deleteFinancialCreditBureauReportUrl,
      params: data,
    });
    if (res.status === 200 && res.data.success.message === 'Success') {
      const storeData = yield select(makeSelectFinancialInfo());
      let newFinancialData = storeData.toJS();
      const creditReportNewList =
        newFinancialData.creditBureauReportResult.creditor.filter(
          (item) => item.id !== data.creatorId
        );
      newFinancialData.creditBureauReportResult.creditor = [
        ...creditReportNewList,
      ];
      yield put(setFinancialInfoDataRequest(newFinancialData));
    }
  } catch (err) {
    const { response } = err;
    yield call(unauthorizedHandler, response);
    yield put(
      deleteFinancialCreditBureauReportError(
        err.response?.data?.success?.message
      )
    );
  }
}
// delete country residence
export function* deleteFinancialCountryOfTaxResidenceAction({ data }) {
  try {
    const res = yield callRequestWithToken({
      method: 'post',
      url: deleteFinancialCountryOfTaxResidencetUrl,
      params: data,
    });
    if (res.status === 200 && res.data.success.message === 'Success') {
      const storeData = yield select(makeSelectFinancialInfo());
      let newFinancialData = storeData.toJS();
      const taxResidenceNewList =
        newFinancialData.countryTaxResidenceResult.countryArray.filter(
          (item) => item.id !== data.countryTaxResidenceId
        );
      newFinancialData.countryTaxResidenceResult.countryArray = [
        ...taxResidenceNewList,
      ];
      yield put(setFinancialInfoDataRequest(newFinancialData));
    }
  } catch (err) {
    const { response } = err;
    yield call(unauthorizedHandler, response);
    yield put(
      deleteFinancialCountryOfTaxResidenceError(
        err.response?.data?.success?.message
      )
    );
  }
}
// get comments
export function* getCommentsDataAction({ data }) {
  try {
    const res = yield callRequestWithToken({
      method: 'post',
      url: getAllComments,
      params: data,
    });
    if (res.status === 200 && res.data.success.message === 'Success') {
      yield put(setCommentsData(res.data.data.comments));
      yield put(getCommentsDataSuccess('Successfully get Invitation data'));
    }
  } catch (err) {
    const { response } = err;
    yield call(unauthorizedHandler, response);
    yield put(getCommentsDataError(err.response?.data?.success?.message));
  }
}

// handler file
export function* uploadDocumentsAction({ data }) {
  const ref = data?.ref;
  const UploadFileParams = data?.UploadFileParams;
  const fileList = data?.fileList;
  try {
    const storeData = yield select(makeSelectFinancialInfo());
    let newFinancialData = storeData.toJS();
    const newFile = {
      id: data.id,
      uploadStatus: 'uploading',
      uploadingText: `${0} / ${formatBytes(UploadFileParams.file.size)}`,
      listFile: fileList,
    };
    ref.current.handleUpdateFile(newFile);
    const config = {
      headers: { 'Content-Type': 'multipart/form-data' },
      timeout: 30000,
    };
    const res = yield callRequestWithToken({
      method: 'post',
      url: uploadFileUrl,
      params: UploadFileParams,
      config,
    });
    if (res.status === 201 && res.data.success.status) {
      const resFile = res.data.data.recruitmentProcessFile;
      const fileItem = {
        name: resFile.fileName,
        size: formatBytes(resFile.fileSize * 1),
        id: resFile.id,
      };
      ref.current.handleUpdateFile({
        ...newFile,
        uploadStatus: 'completed',
        uploadingText: `${fileItem.size} / ${fileItem.size}`,
        listFile: [...fileList, fileItem],
      });
      newFinancialData[data.part].reportDate = resFile?.reportDate || null; // save the reportDate to state
      newFinancialData[data.part].files.splice(0, 0, {
        fileId: resFile.id,
        fileName: resFile.fileName,
        fileSize: resFile.fileSize,
        pageName: 'financialInformation',
        archieved: false,
        newUpload: true,
      });
      yield put(setFinancialInfoDataRequest(newFinancialData));
      yield put(uploadDocumentsSuccess('upload file Successfully!'));
    } else {
      ref.current.handleUpdateFile({
        ...newFile,
        uploadStatus: 'error',
        uploadingText: `${0} / ${formatBytes(UploadFileParams.file.size)}`,
        errorMsg: 'Unable to process attached file.',
        listFile: fileList,
      });
      yield put(uploadDocumentsError(res.data?.success?.message));
    }
  } catch (err) {
    const { response } = err;
    yield call(unauthorizedHandler, response);
    yield put(uploadDocumentsError(err.response?.data?.success?.message));
  }
}

export function* deleteDocumentsAction({ data }) {
  const ref = data?.ref;
  const file = data?.file;
  try {
    const res = yield callRequestWithToken({
      method: 'post',
      url: deleteFileUrl,
      params: { id: file.id },
    });
    if (res.status === 201 && res.data.success.status) {
      ref.current.handleDeleteFile(file);
      const storeData = yield select(makeSelectFinancialInfo());
      let newFinancialData = storeData.toJS();
      newFinancialData[data.part].files = newFinancialData[
        data.part
      ].files.filter((item) => item.fileId !== file.id);
      newFinancialData[data.part].reportDate = null; // set the reportDate back to null on file delete
      yield put(setFinancialInfoDataRequest(newFinancialData));
      yield put(deleteDocumentsSuccess('delete file Successfully!'));
    } else {
      yield put(deleteDocumentsError(res.data?.success?.message));
    }
  } catch (err) {
    const { response } = err;
    yield call(unauthorizedHandler, response);
    yield put(deleteDocumentsError(err.response?.data?.success?.message));
  }
}

export default function* rootSaga() {
  yield takeLatest(GET_FINANCIAL_INFO_REQUEST, getFinancialInfoAction);
  // add save interface
  yield takeLatest(SAVE_FINANCIAL_BANK_REQUEST, saveFinancialBankAction);
  // add save report
  yield takeLatest(SAVE_FINANCIAL_REPORT_REQUEST, saveFinancialReportAction);
  // add save report credit
  yield takeLatest(
    SAVE_FINANCIAL_REPORT_CREDITOR_REQUEST,
    saveFinancialReporCreditortDataAction
  );
  // add country residence
  yield takeLatest(
    SAVE_FINANCIAL_COUNTRY_RESIDENCE_REQUEST,
    saveFinancialCountryResidenceDataAction
  );
  // save fact
  yield takeLatest(SAVE_FINANCIAL_FACT_REQUEST, saveFinancialFactDataAction);

  yield takeLatest(
    DELETE_FINANCIAL_CREDITBUREAUREPORT_REQUEST,
    deleteFinancialCreditBureauReportAction
  );
  yield takeLatest(
    DELETE_FINANCIAL_COUNTRYOFTAXRESIDENCE_REQUEST,
    deleteFinancialCountryOfTaxResidenceAction
  );
  yield takeLatest(GET_ALL_COMMENTS_REQUEST, getCommentsDataAction);
  yield takeLatest(SAVE_CHECK_QUESTION_REQUEST, saveCheckQuestionAction);

  // add handle file
  yield takeLatest(UPLOAD_DOCUMENTS_REQUEST, uploadDocumentsAction);
  yield takeLatest(DELETE_DOCUMENTS_REQUEST, deleteDocumentsAction);
}
