import { fromJS } from 'immutable';
import {
  GET_SIGNING_URL_REQUEST,
  GET_SIGNING_URL_SUCCESS,
  GET_SIGNING_URL_ERROR,
  POST_SIGNER_STATUS_REQUEST,
  POST_SIGNER_STATUS_SUCCESS,
  POST_SIGNER_STATUS_ERROR,
} from './actionTypes';
import { GET_EPS_DATA_REQUEST } from '../eps/actionTypes';

const initState = fromJS({
  loading: false,
  error: false,
  success: false,
  message: null,
  signUrl: '',
  signerStatus: false,
});

export default function (state = initState, action) {
  const { type } = action;

  switch (type) {
    case GET_SIGNING_URL_REQUEST:
    case POST_SIGNER_STATUS_REQUEST: {
      return state
        .set('loading', true)
        .set('success', false)
        .set('error', false);
    }
    case GET_SIGNING_URL_SUCCESS: {
      return state
        .set('loading', false)
        .set('success', true)
        .set('signUrl', fromJS(action.signUrl));
    }

    case POST_SIGNER_STATUS_SUCCESS: {
      return state
        .set('loading', false)
        .set('success', true)
        .set('signerStatus', true);
    }

    case GET_EPS_DATA_REQUEST: {
      return state.set('signerStatus', false);
    }

    case GET_SIGNING_URL_ERROR:
    case POST_SIGNER_STATUS_ERROR:
      return state
        .set('loading', false)
        .set('error', true)
        .set('message', action.message);
    default:
      return state;
  }
}
