import {
  SET_EMPLOYMENTHISTORY_DATA,
  SET_EMPLOYMENTGAP_DATA,
  GET_EMPLOYMENTHISTORY_REQUEST,
  SAVE_EMPLOYMENTHISTORY_REQUEST,
  SAVE_EMPLOYMENTGAP_REQUEST,
  PROCEED_EMPLOYMENTHISTORY_SUCCESS,
  PROCEED_EMPLOYMENTHISTORY_ERROR,
  DELETE_EMPLOYMENTHISTORY_REQUEST,
  UPDATE_EMPLOYMENTHISTORY_REQUEST,
  SET_INITIAL_STATE,
  SET_IS_INIT_COMPLETED,
} from './actionTypes';

export const setInitialState = (data) => ({
  type: SET_INITIAL_STATE,
  data,
});

export const setIsInitCompleted = (data) => ({
  type: SET_IS_INIT_COMPLETED,
  data,
});

export const setEmploymentHistoryData = (data) => ({
  type: SET_EMPLOYMENTHISTORY_DATA,
  data,
});

export const setEmploymentGapData = (data) => ({
  type: SET_EMPLOYMENTGAP_DATA,
  data,
});

export const getEmploymentHistoryDataRequest = (data) => ({
  type: GET_EMPLOYMENTHISTORY_REQUEST,
  data,
});

export const saveEmploymentHistoryDataRequest = (
  data,
  index,
  setIsLoading
) => ({
  type: SAVE_EMPLOYMENTHISTORY_REQUEST,
  data,
  index,
  setIsLoading,
});

export const saveEmploymentGapDataRequest = (data, index) => ({
  type: SAVE_EMPLOYMENTGAP_REQUEST,
  data,
  index,
});

export const proceedEmploymentHistorySuccess = (message) => ({
  type: PROCEED_EMPLOYMENTHISTORY_SUCCESS,
  message,
});

export const proceedEmploymentHistoryError = (message) => ({
  type: PROCEED_EMPLOYMENTHISTORY_ERROR,
  message,
});

export const deleteEmploymentHistoryRequest = (data, index, setIsLoading) => ({
  type: DELETE_EMPLOYMENTHISTORY_REQUEST,
  data,
  index,
  setIsLoading,
});

export const updateInvitationDataRequest = (data) => ({
  type: UPDATE_EMPLOYMENTHISTORY_REQUEST,
  data,
});
