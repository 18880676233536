import { call, put, select, takeLatest } from 'redux-saga/effects';
import {
  callRequestWithToken,
  unauthorizedHandler,
} from '../../../../utils/request';
import moment from 'moment';
import {
  GET_CERTIFICATION_REQUEST,
  SAVE_CERTIFICATION_REQUEST,
} from './actionTypes';
import {
  proceedCertificationSuccess,
  proceedCertificationError,
  setCertificationData,
} from './actions';
import {
  getCertificationUrl,
  saveCertificationUrl,
  getCommentsList,
  getFileListUrl,
  deleteByQuestionListURL,
} from '@utils/apis';
import { DOCUMENTS_INFO } from '@pages/fscApplication/assessment/Certification/business/constants';
import { filter, keys } from 'lodash';
import { BOOLEAN_FLAG, PAGE_NAME } from '@utils/constants';
import { formatBytes } from '@utils/helper';
import { makeSelectRecruitmentProcessIds } from '@redux/fscApplication/eobCommonInfo/selectors';

export function* getCertificationAction({ data }) {
  try {
    // get field data
    const res = yield callRequestWithToken({
      method: 'post',
      url: getCertificationUrl,
      params: data,
    });
    if (res.status === 200 && res.data.success.status) {
      const certificationData = res.data.data.assessmentCertification;
      certificationData.isFirstLoad = true;

      // get document data
      const requestData = {
        recruitmentProcessId: data.recruitmentProcessId,
        pageName: PAGE_NAME.CMFAS_CERTIFICATION.pageKey,
      };
      const documentRes = yield callRequestWithToken({
        method: 'post',
        url: getFileListUrl,
        params: requestData,
      });
      if (documentRes.status === 201 && documentRes.data.success.status) {
        const documents = documentRes.data.data.fileList;

        const documentIds = [];
        documents.forEach((documentInfo) => {
          documentInfo.name = documentInfo.fileName;
          documentInfo.size = formatBytes(documentInfo.fileSize * 1);
          documentIds.push(documentInfo.id);
        });

        // get comment data
        const commentsRes = yield callRequestWithToken({
          method: 'post',
          url: getCommentsList,
          params: { fids: [certificationData.id, ...documentIds] },
        });
        if (commentsRes.status === 200 && commentsRes.data.success.status) {
          certificationData.comments =
            commentsRes.data.data.commentsList[certificationData.id];

          certificationData.documents = {};

          keys(DOCUMENTS_INFO).forEach((key) => {
            const fileInfos = filter(documents, {
              question: key,
            });
            fileInfos.forEach((fileInfo) => {
              fileInfo.comments =
                commentsRes.data.data.commentsList[fileInfo.id];
            });
            certificationData.documents[key] = fileInfos;
          });

          yield put(proceedCertificationSuccess());
          yield put(setCertificationData(certificationData));
        } else if (
          !commentsRes.data.success.status &&
          commentsRes.data.success.message
        ) {
          yield put(
            proceedCertificationError(commentsRes.data.success.message)
          );
        } else {
          yield put(
            proceedCertificationError('commentsRes.data.success.message')
          );
        }
      } else if (
        !documentRes.data.success.status &&
        documentRes.data.success.message
      ) {
        yield put(proceedCertificationError(documentRes.data.success.message));
      } else {
        yield put(proceedCertificationError('Error: no message'));
      }
    } else if (!res.data.success.status && res.data.success.message) {
      yield put(proceedCertificationError(res.data.success.message));
    } else {
      yield put(proceedCertificationError('Error: no message'));
    }
  } catch (err) {
    const { response } = err;
    yield call(unauthorizedHandler, response);
    yield put(proceedCertificationError(err.response?.data?.success?.message));
  }
}

export function* saveCertificationAction({ data: combinedData }) {
  try {
    const recruitmentProcessIds = yield select(
      makeSelectRecruitmentProcessIds()
    );

    let data = combinedData?.cloneData;
    const originalData = combinedData?.certificationData?.toJS();

    const requestData = {
      recruitmentProcessId: recruitmentProcessIds.get('id'),
      assessmentCmfasCertification: {},
    };

    data[data.fieldName] = data.value;
    requestData.assessmentCmfasCertification[data.fieldName] = data.value;

    let documentArray = [];
    if (
      data.fieldName === 'isPassModuleFive' &&
      data.value === BOOLEAN_FLAG.N
    ) {
      data.moduleFiveCertificateIssuedDate = '';
      requestData.assessmentCmfasCertification.moduleFiveCertificateIssuedDate =
        '';
      documentArray = ['M5_Cert'];
    } else if (data.fieldName === 'isPassModuleNine') {
      if (data.value != BOOLEAN_FLAG.Y) {
        data.moduleNineCertificateDate = '';
        data.cliCertificateDate = '';
        data.ilpCertificateDate = '';
        requestData.assessmentCmfasCertification.moduleNineCertificateDate = '';
        requestData.assessmentCmfasCertification.cliCertificateDate = '';
        requestData.assessmentCmfasCertification.ilpCertificateDate = '';
        documentArray = ['M9_Cert', 'M9_CLI', 'M9_CLI_ILP'];
      }
      if (data.value != 'Exempted') {
        documentArray.push('M9_Exemption');
      }
    } else if (data.fieldName === 'isPassModuleNinea') {
      if (data.value != BOOLEAN_FLAG.Y) {
        data.moduleNineaCertificateDate = '';
        requestData.assessmentCmfasCertification.moduleNineaCertificateDate =
          '';
        documentArray = ['M9A_Cert'];
      }
      if (data.value != 'Exempted') {
        documentArray.push('M9A_Exemption');
      }
    } else if (
      data.fieldName === 'isPassHealthInsurance' &&
      data.value === BOOLEAN_FLAG.N
    ) {
      data.healthInsuranceDate = '';
      data.hiMedishieldLifeCertificateDate = '';
      requestData.assessmentCmfasCertification.healthInsuranceDate = '';
      requestData.assessmentCmfasCertification.hiMedishieldLifeCertificateDate =
        '';
      documentArray = ['HI_Cert', 'HI_MLC'];
    } else if (
      data.fieldName === 'healthInsuranceDate' &&
      !moment(data.value).isBefore(moment('01 Nov 2015', 'DD MMM YYYY'))
    ) {
      data.hiMedishieldLifeCertificateDate = '';
      requestData.assessmentCmfasCertification.hiMedishieldLifeCertificateDate =
        '';
      documentArray = ['HI_MLC'];
    }
    if (documentArray.length > 0) {
      documentArray.forEach((docName) => {
        data.documents[docName] = [];
      });
    }
    yield put(setCertificationData(data));

    const res = yield callRequestWithToken({
      method: 'post',
      url: saveCertificationUrl,
      params: requestData,
    });

    if (res.status === 201 && res.data.success.status) {
      if (documentArray.length === 0) {
        yield put(proceedCertificationSuccess());
        return;
      }
      const deleteDocRes = yield callRequestWithToken({
        method: 'delete',
        url: deleteByQuestionListURL,
        params: {
          id: recruitmentProcessIds.get('id'),
          pageName: 'cmfasCertification',
          questions: documentArray,
        },
      });
      if (deleteDocRes.data.success.status) {
        yield put(proceedCertificationSuccess());
      } else if (
        !deleteDocRes.data.success.status &&
        deleteDocRes.data.success.message
      ) {
        yield put(proceedCertificationError(deleteDocRes.data.success.message));
        yield put(setCertificationData(originalData));
      } else {
        yield put(proceedCertificationError('Error: no message'));
        yield put(setCertificationData(originalData));
      }
    } else if (!res.data.success.status && res.data.success.message) {
      yield put(proceedCertificationError(res.data.success.message));
      yield put(setCertificationData(originalData));
    } else {
      yield put(proceedCertificationError('Error: no message'));
      yield put(setCertificationData(originalData));
    }
  } catch (err) {
    const { response } = err;
    yield call(unauthorizedHandler, response);
    yield put(proceedCertificationError(err.response?.data?.success?.message));
  }
}

export default function* rootSaga() {
  yield takeLatest(GET_CERTIFICATION_REQUEST, getCertificationAction);
  yield takeLatest(SAVE_CERTIFICATION_REQUEST, saveCertificationAction);
}
