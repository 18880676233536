import { createSelector } from 'reselect';
import includes from 'lodash/includes';

const selectEmployment = (state) => state.employmentHistory;

export const makeSelectEmploymentHistoryRequest = () =>
  createSelector(selectEmployment, (employmentState) =>
    employmentState.filter((value, key) =>
      includes(
        [
          'loading',
          'error',
          'success',
          'message',
          'isSubmitting',
          'isSubmitted',
        ],
        key
      )
    )
  );

export const makeSelectEmploymentHistoryData = () =>
  createSelector(selectEmployment, (employmentState) =>
    employmentState.get('employmentHistoryData')
  );

export const makeSelectEmploymentGapData = () =>
  createSelector(selectEmployment, (employmentState) =>
    employmentState.get('employmentGapData')
  );

export const makeSelectIsInitCompleted = () =>
  createSelector(selectEmployment, (employmentState) =>
    employmentState.get('isInitCompleted')
  );
