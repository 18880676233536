import { createSelector } from 'reselect';
import includes from 'lodash/includes';

const selectFinancialInfo = (state) => state.financialInfo;

export const makeSelectRequest = () =>
  createSelector(selectFinancialInfo, (financialInfoState) =>
    financialInfoState.filter((value, key) =>
      includes(
        [
          'loading',
          'error',
          'success',
          'message',
          'isSubmitting',
          'isSubmitted',
          'comments',
        ],
        key
      )
    )
  );

export const makeSelectFinancialInfo = () =>
  createSelector(selectFinancialInfo, (financialInfoState) =>
    financialInfoState.get('financialInfoData')
  );
export const makeSelectLoading = () =>
  createSelector(selectFinancialInfo, (financialInfoState) =>
    financialInfoState.get('loading')
  );

export const makeSelectIsImmediateLoading = () =>
  createSelector(selectFinancialInfo, (financialInfoState) =>
    financialInfoState.get('isImmediateLoading')
  );
export const makeSelectSuccess = () =>
  createSelector(selectFinancialInfo, (financialInfoState) =>
    financialInfoState.get('success')
  );

export const makeSelectCommentsData = () =>
  createSelector(selectFinancialInfo, (financialInfoState) =>
    financialInfoState.get('comments')
  );

export const makeSelectCommentsDateCompleted = () =>
  createSelector(selectFinancialInfo, (financialInfoState) =>
    financialInfoState.get('getCommentsSuccess')
  );
