import { fromJS } from 'immutable';
import {
  SET_JOB_SOURCE_DATA,
  GET_JOB_SOURCE_REQUEST,
  PROCEED_JOB_SOURCE_SUCCESS,
  PROCEED_JOB_SOURCE_ERROR,
} from './actionTypes';

const initialState = fromJS({
  loading: false,
  error: false,
  success: false,
  message: null,
  jobSourceData: {},
  isSubmitting: false,
  isSubmitted: false,
});

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_JOB_SOURCE_REQUEST:
      return state
        .set('loading', true)
        .set('success', false)
        .set('error', false)
        .set('message', null);
    case SET_JOB_SOURCE_DATA:
      return state.set('jobSourceData', fromJS(action.data));
    case PROCEED_JOB_SOURCE_SUCCESS:
      return state
        .set('loading', false)
        .set('success', true)
        .set('isSubmitting', false)
        .set('isSubmitted', true)
        .set('message', action.message);
    case PROCEED_JOB_SOURCE_ERROR:
      return state
        .set('loading', false)
        .set('error', true)
        .set('isSubmitting', false)
        .set('message', action.message);
    default:
      return state;
  }
}
