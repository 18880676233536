import { call, put, select, takeLatest } from 'redux-saga/effects';
import {
  callRequestWithToken,
  unauthorizedHandler,
} from '../../../../utils/request';
import { GET_JOB_SOURCE_REQUEST, SAVE_JOB_SOURCE_REQUEST } from './actionTypes';
import {
  proceedJobSourceSuccess,
  proceedJobSourceError,
  setJobSourceData,
} from './actions';
import {
  getJobSourceUrl,
  saveJobSourceUrl,
  getCommentsList,
} from '@utils/apis';
import { makeSelectRecruitmentProcessIds } from '@redux/fscApplication/eobCommonInfo/selectors';

export function* getJobSourceAction({ data }) {
  try {
    const res = yield callRequestWithToken({
      method: 'post',
      url: getJobSourceUrl,
      params: data,
    });

    if (res.status === 200 && res.data.success.status) {
      const jobSourceData = {};
      const answerArray =
        res.data.data.assessmentJobSource.recruitmentProcessSelectAnswer;

      if (answerArray?.length > 0) {
        const selectedItems = [];
        answerArray.forEach((element) => {
          if (element.question === 'JobSource_Q1') {
            selectedItems.push(element.answer);
            if (element.answer === 'Q1-6' && element.detail?.length > 0) {
              jobSourceData.q1_6Detail = element.detail;
            }
          }
        });

        jobSourceData.selectedItems = selectedItems;
      }
      jobSourceData.id = res.data.data.assessmentJobSource.id;
      jobSourceData.isFirstLoad = true;

      const commentsRes = yield callRequestWithToken({
        method: 'post',
        url: getCommentsList,
        params: { fids: [jobSourceData.id] },
      });
      if (commentsRes.status === 200 && commentsRes.data.success.status) {
        jobSourceData.comments =
          commentsRes.data.data.commentsList[jobSourceData.id];
        yield put(proceedJobSourceSuccess());
        yield put(setJobSourceData(jobSourceData));
      } else if (
        !commentsRes.data.success.status &&
        commentsRes.data.success.message
      ) {
        yield put(proceedJobSourceError(commentsRes.data.success.message));
      } else {
        yield put(proceedJobSourceError('commentsRes.data.success.message'));
      }
    } else if (!res.data.success.status && res.data.success.message) {
      yield put(proceedJobSourceError(res.data.success.message));
    } else {
      yield put(proceedJobSourceError('res.data.success.message'));
    }
  } catch (err) {
    const { response } = err;
    yield call(unauthorizedHandler, response);
    yield put(proceedJobSourceError(err.response?.data?.success?.message));
  }
}

export function* saveJobSourceAction({ data: combinedData }) {
  try {
    const recruitmentProcessId = yield select(
      makeSelectRecruitmentProcessIds()
    );

    const data = combinedData?.cloneData;
    const originalData = combinedData?.jobSourceData?.toJS();

    const answerArray = [];
    if (data.selectedItems?.length > 0) {
      data.selectedItems.forEach((element) => {
        if (element != '') {
          const answer = {
            question: 'JobSource_Q1',
            answer: element,
            detail: '',
          };
          if (element === 'Q1-6') {
            answer.detail = data.q1_6Detail;
          }
          answerArray.push(answer);
        }
      });
    } else {
      // blank answer for delete the last record in DB
      const placeHolderAnswer = { question: 'JobSource_Q1', answer: '' };
      answerArray.push(placeHolderAnswer);
    }

    yield put(setJobSourceData(data));

    const requestData = {
      recruitmentProcessId: recruitmentProcessId.get('id'),
      recruitmentProcessSelectAnswer: answerArray,
    };
    const res = yield callRequestWithToken({
      method: 'post',
      url: saveJobSourceUrl,
      params: requestData,
    });

    if (res.status === 201 && res.data.success.status) {
      yield put(proceedJobSourceSuccess());
    } else if (!res.data.success.status && res.data.success.message) {
      yield put(proceedJobSourceError(res.data.success.message));
      yield put(setJobSourceData(originalData));
    } else {
      yield put(proceedJobSourceError('res.data.success.message'));
      yield put(setJobSourceData(originalData));
    }
  } catch (err) {
    const { response } = err;
    yield call(unauthorizedHandler, response);
    yield put(proceedJobSourceError(err.response?.data?.success?.message));
  }
}

export default function* rootSaga() {
  yield takeLatest(GET_JOB_SOURCE_REQUEST, getJobSourceAction);
  yield takeLatest(SAVE_JOB_SOURCE_REQUEST, saveJobSourceAction);
}
