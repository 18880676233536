import { call, put, select, takeLatest } from 'redux-saga/effects';
import {
  callRequestWithToken,
  unauthorizedHandler,
} from '../../../../utils/request';
import { GET_BACKGROUND_REQUEST, SAVE_BACKGROUND_REQUEST } from './actionTypes';
import {
  proceedBackgroundSuccess,
  proceedBackgroundError,
  setBackgroundData,
} from './actions';
import {
  getBackgroundUrl,
  saveBackgroundUrl,
  getCommentsList,
} from '@utils/apis';
import { BOOLEAN_FLAG } from '@utils/constants';
import { makeSelectRecruitmentProcessIds } from '@redux/fscApplication/eobCommonInfo/selectors';

export function* getBackgroundAction({ data }) {
  try {
    const res = yield callRequestWithToken({
      method: 'post',
      url: getBackgroundUrl,
      params: data,
    });

    if (res.status === 200 && res.data.success.status) {
      const backgroundData = res.data.data.assessmentBackground;

      const answerArray = backgroundData.recruitmentProcessSelectAnswer;

      if (answerArray?.length > 0) {
        const selectedItems = [];
        answerArray.forEach((element) => {
          if (element.question === 'Background_Q3_Sub') {
            selectedItems.push(element.answer);
          }
        });

        backgroundData.selectedItems = selectedItems;
      }
      backgroundData.isFirstLoad = true;

      const commentsRes = yield callRequestWithToken({
        method: 'post',
        url: getCommentsList,
        params: { fids: [backgroundData.id] },
      });

      if (commentsRes.status === 200 && commentsRes.data.success.status) {
        backgroundData.comments =
          commentsRes.data.data.commentsList[backgroundData.id];
        yield put(proceedBackgroundSuccess());
        yield put(setBackgroundData(backgroundData));
      } else if (
        !commentsRes.data.success.status &&
        commentsRes.data.success.message
      ) {
        yield put(proceedBackgroundError(commentsRes.data.success.message));
      } else {
        yield put(proceedBackgroundError('commentsRes.data.success.message'));
      }
    } else if (!res.data.success.status && res.data.success.message) {
      yield put(proceedBackgroundError(res.data.success.message));
    } else {
      yield put(proceedBackgroundError('res.data.success.message'));
    }
  } catch (err) {
    const { response } = err;
    yield call(unauthorizedHandler, response);
    yield put(proceedBackgroundError(err.response?.data?.success?.message));
  }
}

export function* saveBackgroundAction({ data: combinedData }) {
  try {
    const recruitmentProcessIds = yield select(
      makeSelectRecruitmentProcessIds()
    );

    const data = combinedData?.cloneData;
    const originalData = combinedData?.backgroundData?.toJS();

    const requestData = {
      recruitmentProcessId: recruitmentProcessIds.get('id'),
      assessmentBackground: {
        recruitmentProcessSelectAnswer: [],
      },
    };

    if (data.fieldName === 'Background_Q3_Sub') {
      const answerArray = [];
      if (data.selectedItems?.length > 0) {
        data.selectedItems.forEach((element) => {
          if (element != '') {
            const answer = {
              question: 'Background_Q3_Sub',
              answer: element,
            };
            answerArray.push(answer);
          }
        });
      } else {
        // blank answer for delete the last record in DB
        const placeHolderAnswer = { question: 'Background_Q3_Sub', answer: '' };
        answerArray.push(placeHolderAnswer);
      }
      requestData.assessmentBackground.recruitmentProcessSelectAnswer =
        answerArray;
    } else {
      data[data.fieldName] = data.value;
      requestData.assessmentBackground[data.fieldName] = data.value;

      if (
        data.fieldName === 'isIssuedRnfNumber' &&
        data.value === BOOLEAN_FLAG.N
      ) {
        data.rnfNumber = '';
        requestData.assessmentBackground.rnfNumber = '';
      } else if (
        data.fieldName === 'isWithAnotherLeader' &&
        data.value === BOOLEAN_FLAG.N
      ) {
        data.isCompletedActivities = '';
        data.selectedItems = [];
        data.isSubmittedApplication = '';
        data.prevAgencyName = '';
        data.prevLeaderName = '';
        requestData.assessmentBackground.isCompletedActivities = '';
        // blank answer for delete all records in DB
        requestData.assessmentBackground.recruitmentProcessSelectAnswer = [
          { question: 'Background_Q3_Sub', answer: '' },
        ];

        requestData.assessmentBackground.isSubmittedApplication = '';
        requestData.assessmentBackground.prevAgencyName = '';
        requestData.assessmentBackground.prevLeaderName = '';
      } else if (
        data.fieldName === 'isCompletedActivities' &&
        data.value === BOOLEAN_FLAG.N
      ) {
        data.selectedItems = [];
        // blank answer for delete all records in DB
        requestData.assessmentBackground.recruitmentProcessSelectAnswer = [
          { question: 'Background_Q3_Sub', answer: '' },
        ];
      } else if (
        data.fieldName === 'isSubmittedApplication' &&
        data.value === BOOLEAN_FLAG.N
      ) {
        data.prevAgencyName = '';
        data.prevLeaderName = '';
        requestData.assessmentBackground.prevAgencyName = '';
        requestData.assessmentBackground.prevLeaderName = '';
      }
    }
    yield put(setBackgroundData(data));

    const res = yield callRequestWithToken({
      method: 'post',
      url: saveBackgroundUrl,
      params: requestData,
    });

    if (res.status === 201 && res.data.success.status) {
      yield put(proceedBackgroundSuccess());
    } else if (!res.data.success.status && res.data.success.message) {
      yield put(proceedBackgroundError(res.data.success.message));
      yield put(setBackgroundData(originalData));
    } else {
      yield put(proceedBackgroundError('res.data.success.message'));
      yield put(setBackgroundData(originalData));
    }
  } catch (err) {
    const { response } = err;
    yield call(unauthorizedHandler, response);
    yield put(proceedBackgroundError(err.response?.data?.success?.message));
  }
}

export default function* rootSaga() {
  yield takeLatest(GET_BACKGROUND_REQUEST, getBackgroundAction);
  yield takeLatest(SAVE_BACKGROUND_REQUEST, saveBackgroundAction);
}
