import { all } from 'redux-saga/effects';

import appSaga from './app/sagas';
import dashboardSaga from './dashboard/sagas';
import reefSaga from './reef/sagas';
import epsSaga from './eps/sagas';
import consentSaga from './consent/sagas';
import eventSaga from './upcomingEvents/sagas';
import myinfoSaga from './myinfo/sagas';
import signUrlSaga from './signEps/sagas';
import eobCommonInfoSaga from './fscApplication/eobCommonInfo/sagas';
import jobSourceSaga from './fscApplication/assessment/jobsource/sagas';
import backgroundSaga from './fscApplication/assessment/background/sagas';
import educationSaga from './fscApplication/assessment/education/sagas';
import certificationSaga from './fscApplication/assessment/certification/sagas';
import personalSaga from './fscApplication/personalInformation/common/saga';
import basicInfoSaga from './fscApplication/personalInformation/basicInfo/saga';
import familyInfoSaga from './fscApplication/personalInformation/familyInfo/saga';
import emergencyContactSaga from './fscApplication/personalInformation/emergencyContact/saga';
import educationBackgroundSaga from './fscApplication/personalInformation/educationBackground/saga';
import employmentHistorySaga from './fscApplication/personalInformation/workExperiEmploymentHistory/saga';
import employmentAssessmentSaga from './fscApplication/personalInformation/workExperiEmploymentAssessment/saga';
import reviewSaga from './fscApplication/review/sagas';
import financialInformationSaga from './fscApplication/FinancialInformation/sagas';
import criteriaSaga from './fscApplication/FitCriteria/sagas';
import rsvpSaga from './fscApplication/rsvp/sagas';
import fscSignDocSaga from './fscApplication/fscSignDoc/sagas';
import businessInterestDeclarationSaga from './fscApplication/BusinessInterestDeclaration/sagas';
import commonSaga from './fscApplication/common/sagas';
import studenConversionSaga from './studentConversion/sagas';

export default function* rootSaga() {
  try {
    yield all([
      appSaga(),
      dashboardSaga(),
      reefSaga(),
      epsSaga(),
      consentSaga(),
      eventSaga(),
      myinfoSaga(),
      signUrlSaga(),
      eobCommonInfoSaga(),
      jobSourceSaga(),
      backgroundSaga(),
      educationSaga(),
      certificationSaga(),
      personalSaga(),
      basicInfoSaga(),
      familyInfoSaga(),
      emergencyContactSaga(),
      educationBackgroundSaga(),
      employmentHistorySaga(),
      employmentAssessmentSaga(),
      reviewSaga(),
      financialInformationSaga(),
      criteriaSaga(),
      rsvpSaga(),
      fscSignDocSaga(),
      businessInterestDeclarationSaga(),
      commonSaga(),
      studenConversionSaga(),
    ]);
  } catch (err) {
    console.log(err);
  }
}
