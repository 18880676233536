import {
  COMMON_ERROR,
  COMMON_SUCCESS,
  DELETE_FILE,
  UPLOAD_FILE,
} from './actionType';

export const commonSuccess = (consent = {}) => ({
  type: COMMON_SUCCESS,
  consent,
});
export const commonError = (error = {}) => ({
  type: COMMON_ERROR,
  error,
});

export const uploadFile = (data, callback) => ({
  type: UPLOAD_FILE,
  data,
  callback,
});
export const deleteFile = (data, callback) => ({
  type: DELETE_FILE,
  data,
  callback,
});
