import { fromJS } from 'immutable';
import {
  GET_DRAFT_DATA_REQUEST,
  GET_DRAFT_DATA_SUCCESS,
  GET_DRAFT_DATA_ERROR,
  SAVE_DRAFT_DATA_REQUEST,
  SAVE_DRAFT_DATA_SUCCESS,
  SAVE_DRAFT_DATA_ERROR,
  SUBMIT_REEF_DATA_REQUEST,
  SUBMIT_REEF_DATA_REQUEST_SUCCESS,
  SUBMIT_REEF_DATA_REQUEST_ERROR,
} from './actionTypes';

const initState = fromJS({
  loading: false,
  error: false,
  success: false,
  message: null,
  reefForm: {},
  countries: [],
  educ: [],
  institution: [],
});

export default function (state = initState, action) {
  const { type } = action;

  switch (type) {
    case GET_DRAFT_DATA_REQUEST:
    case SAVE_DRAFT_DATA_REQUEST:
    case SUBMIT_REEF_DATA_REQUEST:
      return state
        .set('loading', true)
        .set('success', false)
        .set('error', false)
        .set('message', null);

    case GET_DRAFT_DATA_SUCCESS:
      return state
        .set('loading', false)
        .set('success', true)
        .set('reefForm', fromJS(action.reef))
        .set('countries', fromJS(action.countries))
        .set('educ', fromJS(action.educ))
        .set('institution', fromJS(action.institution));
    case SAVE_DRAFT_DATA_SUCCESS:
    case SUBMIT_REEF_DATA_REQUEST_SUCCESS:
      return state
        .set('loading', false)
        .set('success', true)
        .set('message', action.message);
    case GET_DRAFT_DATA_ERROR:
    case SAVE_DRAFT_DATA_ERROR:
    case SUBMIT_REEF_DATA_REQUEST_ERROR:
      return state
        .set('loading', false)
        .set('error', true)
        .set('message', action.message);
    default:
      return state;
  }
}
