import { call, put, takeLatest, select } from 'redux-saga/effects';
import request, { unauthorizedHandler } from '@utils/request';
import { makeSelectToken } from '@redux/app/selectors';

import {
  getUpcomingDataSuccess,
  getUpcomingDataError,
  getHappeningDataError,
  getHappeningDataSuccess,
  acceptEventSuccess,
  acceptEventError,
  declineEventSuccess,
  declineEventError,
} from './actions';

import {
  ACCEPT_EVENT_REQUEST,
  DECLINE_EVENT_REQUEST,
  GET_HAPPENING_DATA_REQUEST,
  GET_UPCOMING_DATA_REQUEST,
} from './actionTypes';

function* getUpcomingEvents() {
  try {
    let token = yield select(makeSelectToken());

    if (token) {
      const res = yield call(request, '/candidate/events', 'get', null, token);
      if (res.data.success.status) {
        yield put(getUpcomingDataSuccess(res.data.data));
      } else {
        yield put(getUpcomingDataError('Failed get upcoming events data'));
      }
    } else {
      yield put(getUpcomingDataError('Token not provided'));
    }
  } catch (err) {
    const { response } = err;
    yield call(unauthorizedHandler, response);
    yield put(getUpcomingDataError('Failed get upcoming events data'));
  }
}

function* getHappeningEvents() {
  try {
    let token = yield select(makeSelectToken());

    if (token) {
      const res = yield call(
        request,
        '/candidate/events/other',
        'get',
        null,
        token
      );
      if (res.data.success.status) {
        yield put(getHappeningDataSuccess(res.data.data));
      } else {
        yield put(getHappeningDataError('Failed get happening events data'));
      }
    } else {
      yield put(getHappeningDataError('Token not provided'));
    }
  } catch (err) {
    const { response } = err;
    yield call(unauthorizedHandler, response);
    yield put(getHappeningDataError('Failed get happening events data'));
  }
}

function* acceptEventAction({ id }) {
  try {
    let token = yield select(makeSelectToken());

    if (token) {
      const res = yield call(
        request,
        `/candidate/events/${id}/accept`,
        'post',
        null,
        token
      );
      if (res.data.success.status) {
        yield put(
          acceptEventSuccess(
            `${res.data.data.name} event accepted successfully`
          )
        );
      } else {
        yield put(acceptEventError(res.data.success.message));
      }
    } else {
      yield put(acceptEventError('Token not provided'));
    }
  } catch (err) {
    const { response } = err;
    yield call(unauthorizedHandler, response);
    yield put(acceptEventError(err.response?.data?.success?.message));
  }
}

function* declineEventAction({ id, comment }) {
  try {
    let token = yield select(makeSelectToken());

    if (token) {
      const config = {
        headers: { 'Content-Type': 'application/json' },
      };

      const dataToSend = { comment };

      const res = yield call(
        request,
        `/candidate/events/${id}/decline`,
        'post',
        dataToSend,
        token,
        config
      );
      if (res.data.success.status) {
        yield put(
          declineEventSuccess(
            `${res.data.data.name} event declined successfully`
          )
        );
      } else {
        yield put(declineEventError(res.data.success.message));
      }
    } else {
      yield put(declineEventError('Token not provided'));
    }
  } catch (err) {
    const { response } = err;
    yield call(unauthorizedHandler, response);
    yield put(
      declineEventError(
        'VMS Service: Cannot generate QR code for a iRecruit+ event that has past.'
      )
    );
  }
}

export default function* rootSaga() {
  yield takeLatest(GET_UPCOMING_DATA_REQUEST, getUpcomingEvents);
  yield takeLatest(GET_HAPPENING_DATA_REQUEST, getHappeningEvents);
  yield takeLatest(ACCEPT_EVENT_REQUEST, acceptEventAction);
  yield takeLatest(DECLINE_EVENT_REQUEST, declineEventAction);
}
