import {
  SET_CERTIFICATION_DATA,
  GET_CERTIFICATION_REQUEST,
  SAVE_CERTIFICATION_REQUEST,
  PROCEED_CERTIFICATION_SUCCESS,
  PROCEED_CERTIFICATION_ERROR,
} from './actionTypes';

export const setCertificationData = (data) => ({
  type: SET_CERTIFICATION_DATA,
  data,
});

export const getCertificationRequest = (data) => ({
  type: GET_CERTIFICATION_REQUEST,
  data,
});

export const saveCertificationRequest = (data) => ({
  type: SAVE_CERTIFICATION_REQUEST,
  data,
});

export const proceedCertificationSuccess = (message) => ({
  type: PROCEED_CERTIFICATION_SUCCESS,
  message,
});

export const proceedCertificationError = (message) => ({
  type: PROCEED_CERTIFICATION_ERROR,
  message,
});
