import {
  SET_EMPLOYMENT_ASSESSMENT_DATA,
  GET_EMPLOYMENT_ASSESSMENT_REQUEST,
  SAVE_EMPLOYMENT_ASSESSMENT_REQUEST,
  PROCEED_EMPLOYMENT_ASSESSMENT_SUCCESS,
  PROCEED_EMPLOYMENT_ASSESSMENT_ERROR,
  DELETE_EMPLOYMENT_ASSESSMENT_REQUEST,
  UPDATE_EMPLOYMENT_ASSESSMENT_REQUEST,
  SET_INITIAL_STATE,
  SET_IS_INIT_COMPLETED,
} from './actionTypes';

export const setInitialState = (data) => ({
  type: SET_INITIAL_STATE,
  data,
});

export const setIsInitCompleted = (data) => ({
  type: SET_IS_INIT_COMPLETED,
  data,
});

export const setEmploymentAssessmentData = (data) => ({
  type: SET_EMPLOYMENT_ASSESSMENT_DATA,
  data,
});

export const getEmploymentAssessmentDataRequest = (data) => ({
  type: GET_EMPLOYMENT_ASSESSMENT_REQUEST,
  data,
});

export const saveEmploymentAssessmentDataRequest = (
  radioData,
  companyName
) => ({
  type: SAVE_EMPLOYMENT_ASSESSMENT_REQUEST,
  radioData,
  companyName,
});

export const proceedEmploymentAssessmentSuccess = (message) => ({
  type: PROCEED_EMPLOYMENT_ASSESSMENT_SUCCESS,
  message,
});

export const proceedEmploymentAssessmentError = (message) => ({
  type: PROCEED_EMPLOYMENT_ASSESSMENT_ERROR,
  message,
});

export const deleteEmploymentAssessmentRequest = (data) => ({
  type: DELETE_EMPLOYMENT_ASSESSMENT_REQUEST,
  data,
});

export const updateInvitationDataRequest = (data) => ({
  type: UPDATE_EMPLOYMENT_ASSESSMENT_REQUEST,
  data,
});
