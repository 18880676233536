import { call, put, takeLatest } from 'redux-saga/effects';
import { callRequestWithToken, unauthorizedHandler } from '@utils/request';
import {
  getCandidateReview,
  submitCandidateReview,
  saveCandidateReview,
  getAllComments,
  getReviewCardInfo,
  submitWithdrawUrl,
} from '@utils/apis';
//import get from 'lodash/get';
import {
  GET_CANDIDATE_REVIEW_REQUEST,
  SUBMIT_CANDIDATE_REVIEW_REQUEST,
  SAVE_CANDIDATE_REVIEW,
  GET_ALL_COMMENTS_REQUEST,
  GET_REVIEW_CARD_INFOR_REQUEST,
  SUBMIT_WITHDRAW_REQUEST,
} from './actionTypes';
import {
  getCanditateReviewError,
  setCanditateReviewDate,
  submitCanditateReviewError,
  submitCanditateReviewSuccess,
  saveCanditateReviewError,
  setSaveCanditateReviewDate,
  getAllCommentsError,
  setAllCommentsDate,
  getReviewCardInfoError,
  setReviewCardInfoData,
  submitWithdrawSuccess,
  submitWithdrawError,
} from './actions';
export function* getCandidateReviewAction({ data }) {
  try {
    const res = yield callRequestWithToken({
      method: 'post',
      url: getCandidateReview,
      params: data,
    });
    if (res.status == 200) {
      yield put(setCanditateReviewDate(res.data?.data?.reviewResult));
    } else {
      yield put(getCanditateReviewError('Token not provide to get data'));
    }
  } catch (err) {
    const { response } = err;
    yield call(unauthorizedHandler, response);
    yield put(getCanditateReviewError(err?.response?.data?.success?.message));
  }
}
export function* submitCandidateReviewAction({ data }) {
  try {
    const res = yield callRequestWithToken({
      method: 'post',
      url: submitCandidateReview,
      params: data,
    });
    if (res.status == 200) {
      yield put(submitCanditateReviewSuccess('success '));
    } else {
      yield put(submitCanditateReviewError('Token not provide to get data'));
    }
  } catch (err) {
    const { response } = err;
    yield call(unauthorizedHandler, response);
    yield put(
      submitCanditateReviewError(err?.response?.data?.success?.message)
    );
  }
}

export function* saveCandidateReviewAction({ data }) {
  try {
    const res = yield callRequestWithToken({
      method: 'post',
      url: saveCandidateReview,
      params: data,
    });
    if (res.status == 200) {
      yield put(setSaveCanditateReviewDate(res.data?.data?.result));
    } else {
      yield put(saveCanditateReviewError('Token not provide to get data'));
    }
  } catch (err) {
    const { response } = err;
    yield call(unauthorizedHandler, response);
    yield put(saveCanditateReviewError(err?.response?.data?.success?.message));
  }
}

export function* getAllCommentsAction({ data }) {
  try {
    const res = yield callRequestWithToken({
      method: 'post',
      url: getAllComments,
      params: data,
    });
    if (res.status == 200) {
      yield put(setAllCommentsDate(res.data?.data?.comments));
    } else {
      yield put(getAllCommentsError('Token not provide to get data'));
    }
  } catch (err) {
    const { response } = err;
    yield call(unauthorizedHandler, response);
    yield put(getAllCommentsError(err?.response?.data?.success?.message));
  }
}
//getReviewCardInfoAction
export function* getReviewCardInfoAction({ data }) {
  try {
    const res = yield callRequestWithToken({
      method: 'post',
      url: getReviewCardInfo,
      params: data,
    });
    if (res.status == 200) {
      yield put(setReviewCardInfoData(res.data?.data?.leaderReviewCardInfo));
    } else {
      yield put(getReviewCardInfoError('Token not provide to get data'));
    }
  } catch (err) {
    const { response } = err;
    yield call(unauthorizedHandler, response);
    yield put(getReviewCardInfoError(err?.response?.data?.success?.message));
  }
}

// submit withdraw
export function* submitWithdrawAction({ data, callback }) {
  try {
    const res = yield callRequestWithToken({
      method: 'delete',
      url: submitWithdrawUrl,
      params: data,
    });
    if (
      res.status == 200 ||
      (res.status === 201 && res.data.success.message === 'Success')
    ) {
      yield put(submitWithdrawSuccess('Successfully withdraw'));
      callback && callback();
    }
  } catch (err) {
    const { response } = err;
    yield call(unauthorizedHandler, response);
    yield put(submitWithdrawError(err?.response?.data?.success?.message));
  }
}
export default function* rootSaga() {
  yield takeLatest(GET_CANDIDATE_REVIEW_REQUEST, getCandidateReviewAction);
  yield takeLatest(
    SUBMIT_CANDIDATE_REVIEW_REQUEST,
    submitCandidateReviewAction
  );
  yield takeLatest(SAVE_CANDIDATE_REVIEW, saveCandidateReviewAction);
  yield takeLatest(GET_ALL_COMMENTS_REQUEST, getAllCommentsAction);
  yield takeLatest(GET_REVIEW_CARD_INFOR_REQUEST, getReviewCardInfoAction);
  yield takeLatest(SUBMIT_WITHDRAW_REQUEST, submitWithdrawAction);
}
