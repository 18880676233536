import { createSelector } from 'reselect';
import includes from 'lodash/includes';

const selectEmploymentAssessment = (state) => state.employmentAssessment;

export const makeSelectEmploymentAssessmentRequest = () =>
  createSelector(selectEmploymentAssessment, (employmentAssessmentState) =>
    employmentAssessmentState.filter((value, key) =>
      includes(
        [
          'loading',
          'error',
          'success',
          'message',
          'isSubmitting',
          'isSubmitted',
        ],
        key
      )
    )
  );

export const makeSelectEmploymentAssessmentData = () =>
  createSelector(selectEmploymentAssessment, (employmentAssessmentState) =>
    employmentAssessmentState.get('employmentAssessmentData')
  );
export const makeSelectIsInitCompleted = () =>
  createSelector(selectEmploymentAssessment, (employmentAssessmentState) =>
    employmentAssessmentState.get('isInitCompleted')
  );
