import {
  SET_JOB_SOURCE_DATA,
  GET_JOB_SOURCE_REQUEST,
  SAVE_JOB_SOURCE_REQUEST,
  PROCEED_JOB_SOURCE_SUCCESS,
  PROCEED_JOB_SOURCE_ERROR,
} from './actionTypes';

export const setJobSourceData = (data) => ({
  type: SET_JOB_SOURCE_DATA,
  data,
});

export const getJobSourceRequest = (data) => ({
  type: GET_JOB_SOURCE_REQUEST,
  data,
});

export const saveJobSourceRequest = (data) => ({
  type: SAVE_JOB_SOURCE_REQUEST,
  data,
});

export const proceedJobSourceSuccess = (message) => ({
  type: PROCEED_JOB_SOURCE_SUCCESS,
  message,
});

export const proceedJobSourceError = (message) => ({
  type: PROCEED_JOB_SOURCE_ERROR,
  message,
});
