import _, { isEmpty, trim } from 'lodash';
import axios from 'axios';
import FileSaver from 'file-saver';
import queryString from 'query-string';
import get from 'lodash/get';
import moment from 'moment';
import {
  BASE_API,
  SCHEDULE_SIGN_STATUS,
  STEP_STATUS,
  BOOLEAN_FLAG,
  PAGE_NAME,
} from './constants';
import notifications from '@utils/notifications';

export const validationEmail = (email) => {
  const regex =
    /^[a-zA-Z0-9_.+-]+@(?:(?:[a-zA-Z0-9-]+\.)?[a-zA-Z]+\.)?(aia)\.com$/i;
  return regex.test(email);
};

export const validationPhoneNo = (number) => {
  const regex = /^[6|8|9]\d{7}|\+65[6|8|9]\d{7}|\+65\s[6|8|9]\d{7}$/i;
  return regex.test(number);
};

export const isNumeric = (number) => {
  const regex = /^[0-9]+$/;
  return regex.test(number);
};

export const validateNRIC = (nric) => {
  const regex = /^[SFTG]\d{7}[A-Z]$/;
  return regex.test(nric);
};

export const isEmptyString = (value) => (value ? isEmpty(trim(value)) : false);

export const getResponseMessage = (res, defaultMessage = null) => {
  let message = get(res, ['response', 'data', 'message']);
  if (message) {
    return message;
  }
  message = get(res, ['data', 'success', 'message']);
  if (message) {
    return message;
  }
  return defaultMessage;
};

export const findProfile = (profiles, status) => {
  if (profiles && Array.isArray(profiles)) {
    return profiles.find((profile) => profile.status === status);
  }
  return null;
};

export const groupBy = (array, prop) =>
  array.reduce((acc, curr) => {
    acc[curr[prop]] = [...(acc[curr[prop]] || []), curr];
    return acc;
  }, {});

export const numberWithCommas = (numString) =>
  numString ? numString.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') : '-';

export const searchFromOptions = (options, value) => {
  const result = options
    .map((opt) => {
      const name = opt.name || '';

      if (name.toLowerCase().indexOf(value.toLowerCase()) !== -1) {
        return opt;
      }
      return null;
    })
    .filter((e) => e);

  return result;
};

export const downloadProtectedImage = (token, path, reqConfig = {}) => {
  if (
    path.indexOf('blob:') === -1 &&
    path.indexOf('imageblob:') === -1 &&
    path.indexOf('/static/') === -1
  ) {
    const config = {
      method: 'get',
      url: `${BASE_API}/file/image${path}`,
      headers: { Authorization: `Bearer ${token}` },
      responseType: 'blob',
      ...reqConfig,
    };
    return axios(config).then((res) => {
      if (res.data) {
        const blob = new Blob([res.data], { type: 'application/png' });
        return URL.createObjectURL(blob);
      }
      return null;
    });
  }
  return Promise.resolve(path);
};

export const isValidAdvisoryStartDate = (date) => {
  const isValid = moment(date).isSameOrBefore('2014-02-01');
  return isValid;
};

export const isValidAdvisoryEndDate = (startDate, endDate) => {
  const isEarly = moment(endDate).isSameOrBefore('2014-02-01');
  const checkWithStartDate = moment(endDate).isSameOrAfter(startDate);
  return isEarly && checkWithStartDate;
};

export const maskStart = (string, length, chars) => {
  if (!_.isString(string) || !_.isNumber(length) || !_.isString(chars))
    return '';

  const masked = _.times(`${string}`.length - length, _.constant(chars));
  return masked.concat(_.takeRight(string, length)).join('');
};

export const isExpired = (dateBefore) =>
  moment(dateBefore).isAfter(moment(), 'day');

export const downloadBlobPdf = (token, url, fileName) => {
  const headers = {
    Authorization: `Bearer ${token}`,
  };
  axios({
    url,
    method: 'GET',
    responseType: 'blob',
    headers,
  })
    .then((response) => {
      const blob = new Blob([response.data], { type: 'application/pdf' });
      FileSaver.saveAs(blob, fileName);
    })
    .catch((err) => notifications('error', err));
};

export const replaceValue = (val) => {
  switch (val) {
    case SCHEDULE_SIGN_STATUS.PPS:
      return "Pending Candidate's signature";
    case SCHEDULE_SIGN_STATUS.PT2S:
      return "Pending T2 leader's signature";
    case SCHEDULE_SIGN_STATUS.PT3S:
      return "Pending T3 leader's signature";
    default:
      return val;
  }
};

export function convertFlatListToTreeList(flatList) {
  let treeList = flatList ?? [];
  treeList.forEach((element) => {
    let pid = element.pid;
    if (pid) {
      treeList.forEach((ele) => {
        if (ele.id == pid) {
          if (!ele.children) {
            ele.children = [];
          }
          ele.children.push(element);
        }
      });
    }
  });
  treeList = treeList.filter((ele) => !ele.pid);
  return treeList;
}

export function scrollToEl({ locationSearch, id }) {
  if (id) {
    document.getElementById(id)?.scrollIntoView(true);
  } else if (locationSearch) {
    const parsed = queryString.parse(locationSearch);
    if (parsed.scrollto) {
      document.getElementById(parsed.scrollto)?.scrollIntoView(true);
    }
  }
}

export const formatBytes = (bytes, decimals) => {
  if (bytes == 0) return '0 Bytes';
  var k = 1024,
    dm = decimals || 2,
    sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'],
    i = Math.floor(Math.log(bytes) / Math.log(k));
  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
};

export const getFscStepList = () => {
  return [
    {
      id: '1',
      stepName: 'General assessment',
      stepValue: 'welcome',
      status: STEP_STATUS.INIT,
      commentStatus: STEP_STATUS.INIT,
    },
    {
      id: '101',
      pid: '1',
      backId: '1',
      stepName: 'Job source',
      stepValue: 'job-source',
      stepStatusField: `${PAGE_NAME.JOB_SOURCE.pageKey}Status`,
      commentStatusField: `${PAGE_NAME.JOB_SOURCE.pageKey}CommentStatus`,
      status: STEP_STATUS.INIT,
      commentStatus: STEP_STATUS.INIT,
    },
    {
      id: '102',
      pid: '1',
      backId: '101',
      stepName: 'Background',
      stepValue: 'background',
      stepStatusField: `${PAGE_NAME.BACKGROUND.pageKey}Status`,
      commentStatusField: `${PAGE_NAME.BACKGROUND.pageKey}CommentStatus`,
      status: STEP_STATUS.INIT,
      commentStatus: STEP_STATUS.INIT,
    },
    {
      id: '103',
      pid: '1',
      backId: '102',
      stepName: 'Education',
      stepValue: 'education',
      stepStatusField: `${PAGE_NAME.EDUCATION.pageKey}Status`,
      commentStatusField: `${PAGE_NAME.EDUCATION.pageKey}CommentStatus`,
      status: STEP_STATUS.INIT,
      commentStatus: STEP_STATUS.INIT,
    },
    {
      id: '104',
      pid: '1',
      backId: '103',
      stepName: 'Insurance license (CMFAS) certification',
      stepValue: 'certification',
      stepStatusField: `${PAGE_NAME.CMFAS_CERTIFICATION.pageKey}Status`,
      commentStatusField: `${PAGE_NAME.CMFAS_CERTIFICATION.pageKey}CommentStatus`,
      status: STEP_STATUS.INIT,
      commentStatus: STEP_STATUS.INIT,
    },
    {
      id: '2',
      backId: '104',
      stepName: 'Personal information',
      stepValue: 'personal-information',
      stepStatusField: `${PAGE_NAME.PERSONAL_INFORMATION.pageKey}Status`,
      commentStatusField: `${PAGE_NAME.PERSONAL_INFORMATION.pageKey}CommentStatus`,
      status: STEP_STATUS.INIT,
      commentStatus: STEP_STATUS.INIT,
    },
    {
      id: '3',
      backId: '2',
      stepName: 'Financial information',
      stepValue: 'financial-information',
      stepStatusField: `${PAGE_NAME.FINANCIAL_INFORMATION.pageKey}Status`,
      commentStatusField: `${PAGE_NAME.FINANCIAL_INFORMATION.pageKey}CommentStatus`,
      status: STEP_STATUS.INIT,
      commentStatus: STEP_STATUS.INIT,
    },
    {
      id: '4',
      backId: '3',
      stepName: 'Fit and proper criteria',
      stepValue: 'fit-proper-criteria',
      stepStatusField: `${PAGE_NAME.FIT_AND_PROPER_CRITERIA.pageKey}Status`,
      commentStatusField: `${PAGE_NAME.FIT_AND_PROPER_CRITERIA.pageKey}CommentStatus`,
      status: STEP_STATUS.INIT,
      commentStatus: STEP_STATUS.INIT,
    },
    {
      id: '5',
      backId: '4',
      stepName: 'Review',
      stepValue: 'review',
      stepStatusField: `${PAGE_NAME.REVIEW.pageKey}Status`,
      commentStatusField: `${PAGE_NAME.REVIEW.pageKey}CommentStatus`,
      status: STEP_STATUS.INIT,
      commentStatus: STEP_STATUS.INIT,
      isDisabled: BOOLEAN_FLAG.Y,
      isHidden: BOOLEAN_FLAG.Y,
    },
    {
      id: '6',
      stepName: 'Submitted',
      stepValue: 'submitted',
      status: STEP_STATUS.INIT,
      isDisabled: BOOLEAN_FLAG.Y,
      isHidden: BOOLEAN_FLAG.Y,
    },
    {
      id: '7',
      backId: '-1',
      stepName: 'Supporting documents',
      stepValue: 'supporting-documents',
      status: STEP_STATUS.INIT,
      isHidden: BOOLEAN_FLAG.Y,
    },
    {
      id: '11',
      backId: '-1',
      stepName: 'Preview document',
      stepValue: 'preview-document',
      status: STEP_STATUS.INIT,
      isHidden: BOOLEAN_FLAG.Y,
    },
    {
      id: '21',
      backId: '-1',
      stepName: 'business interest declaration',
      stepValue: 'business-interest-declaration',
      status: STEP_STATUS.INIT,
      isHidden: BOOLEAN_FLAG.Y,
    },
  ];
};

export const getViewFscStepList = () => {
  return [
    {
      id: '10',
      stepName: 'Overview',
      stepValue: 'overview',
      status: STEP_STATUS.COMPLETED,
      isHidden: BOOLEAN_FLAG.Y,
    },
    {
      id: '6',
      stepName: 'Submitted',
      stepValue: 'submitted',
      status: STEP_STATUS.INIT,
      isHidden: BOOLEAN_FLAG.Y,
    },
    {
      id: '7',
      backId: '10',
      stepName: 'Supporting documents',
      stepValue: 'supporting-documents',
      status: STEP_STATUS.COMPLETED,
      isHidden: BOOLEAN_FLAG.Y,
    },
    {
      id: '22',
      stepName: 'Review and sign document(s)',
      stepValue: 'sign-submit-success',
      status: STEP_STATUS.COMPLETED,
      isHidden: BOOLEAN_FLAG.Y,
    },
    {
      id: '11',
      backId: '-1',
      stepName: 'Preview document',
      stepValue: 'preview-document',
      status: STEP_STATUS.COMPLETED,
      isHidden: BOOLEAN_FLAG.Y,
    },
    {
      id: '21',
      backId: '-1',
      stepName: 'business interest declaration',
      stepValue: 'business-interest-declaration',
      status: STEP_STATUS.COMPLETED,
      isHidden: BOOLEAN_FLAG.Y,
    },
  ];
};

export const getRSVPorSIGNFscStepList = () => {
  return [
    {
      id: '6',
      stepName: 'Submitted',
      stepValue: 'submitted',
      status: STEP_STATUS.INIT,
      isHidden: BOOLEAN_FLAG.Y,
    },
    {
      id: '12',
      stepName: 'RSVP',
      stepValue: 'rsvp',
      status: STEP_STATUS.COMPLETED,
      isHidden: BOOLEAN_FLAG.Y,
    },
    {
      id: '13',
      stepName: 'Withdraw',
      stepValue: 'rsvp-withdraw-success',
      status: STEP_STATUS.COMPLETED,
      isHidden: BOOLEAN_FLAG.Y,
    },
    {
      id: '14',
      backId: '12',
      stepName: 'Supporting documents',
      stepValue: 'supporting-documents',
      status: STEP_STATUS.COMPLETED,
      isHidden: BOOLEAN_FLAG.Y,
    },
    {
      id: '15',
      backId: '12',
      stepName: 'Sign',
      stepValue: 'sign-page',
      status: STEP_STATUS.COMPLETED,
      isHidden: BOOLEAN_FLAG.Y,
    },
    {
      id: '16',
      backId: '15',
      stepName: 'Review document',
      stepValue: 'sign-review-document',
      status: STEP_STATUS.COMPLETED,
      isHidden: BOOLEAN_FLAG.Y,
    },
    {
      id: '17',
      backId: '15',
      stepName: 'Review and sign document(s)',
      stepValue: 'sign-submit-success',
      status: STEP_STATUS.COMPLETED,
      isHidden: BOOLEAN_FLAG.Y,
    },
  ];
};
// format number  set 12345 to 12,345.00
export const formatNumber = (num, decimal) => {
  if (isNaN(num)) {
    return '-'; //not number
  }
  if (decimal == 0) {
    return num.replace(/\d{1,3}(?=(\d{3})+(\.\d*)?$)/g, '$&,');
  } else {
    return (num.toFixed(2) + '').replace(
      /\d{1,3}(?=(\d{3})+(\.\d*)?$)/g,
      '$&,'
    );
  }
};

export const dataURLtoFile = (base64Str, fileName) => {
  var arr = base64Str.split(','),
    mime = arr[0].match(/:(.*?);/)[1],
    bstr = window.atob(arr[1]),
    n = bstr.length,
    u8arr = new Uint8Array(n);
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }
  const nFileName = fileName?.includes('.')
    ? fileName
    : `${fileName}.${mime.split('/').pop()}`;
  return new File([u8arr], nFileName, {
    type: mime,
  });
};

export const sanitizer = (input) => {
  const sanitizedInput = input.replace(/<script>|<\/script>/gi, '');
  return sanitizedInput;
};
