export const SET_EMPLOYMENTHISTORY_DATA =
  'irecruit/employmentHistory/SET_EMPLOYMENTHISTORY_DATA';
export const SET_IS_INIT_COMPLETED =
  'irecruit/employmentAssessment/SET_IS_INIT_COMPLETED';
export const SET_INITIAL_STATE =
  'irecruit/employmentAssessment/SET_INITIAL_STATE';

export const GET_EMPLOYMENTHISTORY_REQUEST =
  'irecruit/employmentHistory/GET_EMPLOYMENTHISTORY_REQUEST';
export const SAVE_EMPLOYMENTHISTORY_REQUEST =
  'irecruit/employmentHistory/SAVE_EMPLOYMENTHISTORY_REQUEST';
export const PROCEED_EMPLOYMENTHISTORY_SUCCESS =
  'irecruit/employmentHistory/ADD_EMPLOYMENTHISTORY_SUCCESS';
export const PROCEED_EMPLOYMENTHISTORY_ERROR =
  'irecruit/employmentHistory/ADD_EMPLOYMENTHISTORY_ERROR';

export const DELETE_EMPLOYMENTHISTORY_REQUEST =
  'irecruit/employmentHistory/DELETE_EMPLOYMENTHISTORY_REQUEST';

export const UPDATE_EMPLOYMENTHISTORY_REQUEST =
  'irecruit/employmentHistory/UPDATE_EMPLOYMENTHISTORY_REQUEST';

export const SET_EMPLOYMENTGAP_DATA =
  'irecruit/employmentHistory/SET_EMPLOYMENTGAP_DATA';
export const SAVE_EMPLOYMENTGAP_REQUEST =
  'irecruit/employmentHistory/SAVE_EMPLOYMENTGAP_REQUEST';
