export const qualificationOptions = [
  { label: 'Postgraduate', value: 'education_1' },
  { label: 'Degree', value: 'education_2' },
  { label: 'Diploma', value: 'education_3' },
  { label: "GCE 'A' Level", value: 'education_4' },
  { label: "GCE 'O' Level", value: 'education_5' },
  {
    label: "Approved GCE 'O' Level equivalent",
    value: 'education_6',
  },
  { label: 'Secondary and below', value: 'education_7' },
  { label: 'Pass qualifying test', value: 'education_8' },
];

export const studyOptions = [
  { label: 'Full time', value: 'Full time' },
  { label: 'Part time', value: 'Part time' },
];

export const REEF_DOCUMENT_NAME = {
  REEF_DOCUMENT_10: 'Academic certificate',
  REEF_DOCUMENT_20: 'Academic transcript',
  REEF_DOCUMENT_30: 'Duration of study proof from institution',
  REEF_DOCUMENT_40: 'Course write-up from institution',
  REEF_DOCUMENT_50: 'Original academic certificate and transcript results',
  REEF_DOCUMENT_60: 'Notarial translation for academic certificate',
  REEF_DOCUMENT_70: 'Notarial translation for academic transcript results',
  REEF_DOCUMENT_80:
    'Notarial translation duration of study proof from institution',
  REEF_DOCUMENT_90:
    'Notarial translation duration of course write-up from institution',
};

export const documentsYes = [
  {
    id: 'REEF_DOCUMENT_10',
    title: REEF_DOCUMENT_NAME.REEF_DOCUMENT_10,
    fileRenameTemplate: '_Academic certificate_',
  },
  {
    id: 'REEF_DOCUMENT_20',
    title: REEF_DOCUMENT_NAME.REEF_DOCUMENT_20,
    fileRenameTemplate: '_Academic transcript_',
  },
  {
    id: 'REEF_DOCUMENT_30',
    title: REEF_DOCUMENT_NAME.REEF_DOCUMENT_30,
    detail: '(for diploma holder only)',
    fileRenameTemplate: '_Duration of study proof from institution_',
  },
  {
    id: 'REEF_DOCUMENT_40',
    title: REEF_DOCUMENT_NAME.REEF_DOCUMENT_40,
    detail: '(for diploma holder only)',
    fileRenameTemplate: '_Course writeup from institution_',
  },
];

export const documentsNo = [
  {
    id: 'REEF_DOCUMENT_50',
    title: REEF_DOCUMENT_NAME.REEF_DOCUMENT_50,
    fileRenameTemplate:
      '_Original academic certificate and transcript results_',
  },
  {
    id: 'REEF_DOCUMENT_60',
    title: REEF_DOCUMENT_NAME.REEF_DOCUMENT_60,
    fileRenameTemplate: '_Notarial translation for academic certificate_',
  },
  {
    id: 'REEF_DOCUMENT_70',
    title: REEF_DOCUMENT_NAME.REEF_DOCUMENT_70,
    fileRenameTemplate:
      '_Notarial translation for academic transcript results_',
  },
  {
    id: 'REEF_DOCUMENT_80',
    title: REEF_DOCUMENT_NAME.REEF_DOCUMENT_80,
    detail: '(for diploma holder only)',
    fileRenameTemplate:
      '_Notarial translation for duration of study proof from institution_',
  },
  {
    id: 'REEF_DOCUMENT_90',
    title: REEF_DOCUMENT_NAME.REEF_DOCUMENT_90,
    detail: '(for diploma holder only)',
    fileRenameTemplate:
      '_Notarial translation for duration of course writeup from institution_',
  },
];

export const FSC_REEF_STATUS = {
  PPS: "Pending candidate's submission", // not show
  PLR: "Pending leader's review", // show, under review, disable
  PPF: "Pending candidate's follow-up", // show, reject
  PTDOR: 'Pending TD Ops review', // show under review, disable
  AP: 'Application appeal', //show, under review, disable
  RJD: 'Rejected', // show, reject
  APRV: 'Approved', // show , approval, disable
};

export const EDUCATION_DOCUMENT_NAME_DIPLOMA = [
  'REEF_DOCUMENT_30',
  'REEF_DOCUMENT_40',
  'REEF_DOCUMENT_80',
  'REEF_DOCUMENT_90',
];
