import { fromJS } from 'immutable';
import {
  SET_EDUCATION_DATA,
  GET_EDUCATION_REQUEST,
  UPLOAD_DOCUMENTS_REQUEST,
  PROCEED_EDUCATION_SUCCESS,
  PROCEED_EDUCATION_ERROR,
  SAVE_EDUCATION_REQUEST,
} from './actionTypes';

const initialState = fromJS({
  loading: false,
  error: false,
  success: false,
  message: null,
  invitationData: {},
  isSubmitting: false,
  isSubmitted: false,
});

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_EDUCATION_REQUEST:
      return state
        .set('loading', true)
        .set('success', false)
        .set('error', false)
        .set('message', null);
    case SAVE_EDUCATION_REQUEST:
      return state
        .set('loading', true)
        .set('success', false)
        .set('error', false)
        .set('message', null);
    case SET_EDUCATION_DATA:
      return state.set('educationData', fromJS(action.data));
    case UPLOAD_DOCUMENTS_REQUEST:
      return state
        .set('loading', true)
        .set('success', false)
        .set('error', false)
        .set('isSubmitting', false)
        .set('isSubmitted', true)
        .set('message', action.message);
    case PROCEED_EDUCATION_SUCCESS:
      return state
        .set('loading', false)
        .set('success', true)
        .set('isSubmitting', false)
        .set('isSubmitted', true)
        .set('message', action.message);
    case PROCEED_EDUCATION_ERROR:
      return state
        .set('loading', false)
        .set('error', true)
        .set('isSubmitting', false)
        .set('message', action.message);
    default:
      return state;
  }
}
