export const VALIDATE_CONSENT_DATA_REQUEST =
  'irecruit/consent/GET_CONSENT_DATA_REQUEST';
export const VALIDATE_CONSENT_DATA_SUCCESS =
  'irecruit/consent/GET_CONSENT_DATA_SUCCESS';
export const VALIDATE_CONSENT_DATA_ERROR =
  'irecruit/consent/GET_CONSENT_DATA_ERROR';

export const SEND_CONSENT_DATA_REQUEST =
  'irecruit/consent/SEND_CONSENT_DATA_REQUEST';
export const SEND_CONSENT_DATA_SUCCESS =
  'irecruit/consent/SEND_CONSENT_DATA_SUCCESS';
export const SEND_CONSENT_DATA_ERROR =
  'irecruit/consent/SEND_CONSENT_DATA_ERROR';
