import { createSelector } from 'reselect';
import includes from 'lodash/includes';

const selectEducationBackground = (state) => state.educationBackground;

export const makeSelectEducationBackgroundRequest = () =>
  createSelector(selectEducationBackground, (educationBackgroundState) =>
    educationBackgroundState.filter((value, key) =>
      includes(
        [
          'loading',
          'error',
          'success',
          'message',
          'isSubmitting',
          'isSubmitted',
        ],
        key
      )
    )
  );

export const makeSelectEducationBackgroundData = () =>
  createSelector(selectEducationBackground, (educationBackgroundState) =>
    educationBackgroundState.get('educationBackgroundData')
  );

export const makeSelectEvaluationData = () =>
  createSelector(selectEducationBackground, (educationBackgroundState) =>
    educationBackgroundState.get('evaluationData')
  );

export const makeSelectIsInitCompleted = () =>
  createSelector(selectEducationBackground, (educationBackgroundState) =>
    educationBackgroundState.get('isInitCompleted')
  );
