import { call, put, takeLatest } from 'redux-saga/effects';
import { callRequestWithToken, unauthorizedHandler } from '@utils/request';
import {
  getFileListUrl,
  getBDMFscStatusUrl,
  getCandidateRemoteSignUrl,
  saveCandidateSignStatusFlagUrl,
  generateCandidateSignLinkUrl,
} from '@utils/apis';
import {
  GET_FSC_SIGN_DOC_DATA_REQUEST,
  SAVE_FILE_REVIEW_FLAG_REQUEST,
  GENERATE_CANDIDATE_SIGN_LINK_REQUEST,
  SUBMIT_SIGN_REQUEST,
} from './actionTypes';
import {
  saveFscSignDocData,
  generateCandidateSignLinkSuccess,
  submitSignSuccess,
  proceedFscSignDocSuccess,
  proceedFscSignDocError,
} from './actions';
import _ from 'lodash';

export function* getFscSignDocDataAction({ data }) {
  try {
    // 1.get sign status
    const statusRes = yield callRequestWithToken({
      method: 'get',
      url: getCandidateRemoteSignUrl,
      params: data,
    });

    if (statusRes.data?.success?.status) {
      // 2.get file list
      const fileListRes = yield callRequestWithToken({
        method: 'post',
        url: getFileListUrl,
        params: {
          recruitmentProcessId: data.recruitmentProcessId,
          pageName: statusRes.data?.data?.candidateSignFlag
            ? 'remoteSign'
            : 'reviewAndSign',
        },
      });
      if (fileListRes.data?.success?.status) {
        // 3.get fsc status
        const fscStatusRes = yield callRequestWithToken({
          method: 'get',
          url: getBDMFscStatusUrl,
          params: {
            recruitmentProcessId: data.recruitmentProcessId,
          },
        });
        if (fscStatusRes.data?.success?.status) {
          // handel data
          yield put(
            saveFscSignDocData({
              fileList: _.sortBy(fileListRes.data?.data?.fileList, [
                'question',
              ]),
              status: statusRes.data?.data,
              fscStatus: fscStatusRes.data?.data?.fscStatus,
            })
          );
          yield put(proceedFscSignDocSuccess());
        } else {
          yield put(
            proceedFscSignDocError(fscStatusRes.data?.success?.message)
          );
        }
      } else {
        yield put(proceedFscSignDocError(fileListRes.data?.success?.message));
      }
    } else {
      yield put(proceedFscSignDocError(statusRes.data?.success?.message));
    }
  } catch (err) {
    const { response } = err;
    yield call(unauthorizedHandler, response);
    yield put(proceedFscSignDocError(err.response?.data?.success?.message));
  }
}

export function* saveFileReviewFlagAction({ data }) {
  try {
    let { recruitmentProcessId, flagKey, flag, fscSignDocData } = data;

    fscSignDocData.status[flagKey] = flag;

    let param = {};
    param[flagKey] = flag;
    param['recruitmentProcessId'] = recruitmentProcessId;

    const res = yield callRequestWithToken({
      method: 'patch',
      url: saveCandidateSignStatusFlagUrl,
      params: param,
    });

    if (res.data?.success?.status) {
      yield put(
        saveFscSignDocData({
          fileList: fscSignDocData.fileList,
          status: fscSignDocData.status,
        })
      );
      yield put(proceedFscSignDocSuccess());
    } else {
      yield put(proceedFscSignDocError(res.data?.success?.message));
    }
  } catch (err) {
    const { response } = err;
    yield call(unauthorizedHandler, response);
    yield put(proceedFscSignDocError(err.response?.data?.success?.message));
  }
}

export function* generateCandidateSignLinkAction({ data }) {
  try {
    const res = yield callRequestWithToken({
      method: 'get',
      url: generateCandidateSignLinkUrl,
      params: data,
    });
    if (res.data?.success?.status) {
      yield put(generateCandidateSignLinkSuccess(res.data?.data));
      yield put(proceedFscSignDocSuccess());
    } else {
      yield put(proceedFscSignDocError(res.data?.success?.message));
    }
  } catch (err) {
    const { response } = err;
    yield call(unauthorizedHandler, response);
    yield put(proceedFscSignDocError(err.response?.data?.success?.message));
  }
}

export function* submitSignAction({ data }) {
  try {
    const res = yield callRequestWithToken({
      method: 'patch',
      url: saveCandidateSignStatusFlagUrl,
      params: data,
    });
    if (res.data?.success?.status) {
      yield put(submitSignSuccess(data));
      yield put(proceedFscSignDocSuccess());
    } else {
      yield put(proceedFscSignDocError(res.data?.success?.message));
    }
  } catch (err) {
    const { response } = err;
    yield call(unauthorizedHandler, response);
    yield put(proceedFscSignDocError(err.response?.data?.success?.message));
  }
}

export default function* rootSaga() {
  yield takeLatest(GET_FSC_SIGN_DOC_DATA_REQUEST, getFscSignDocDataAction);
  yield takeLatest(SAVE_FILE_REVIEW_FLAG_REQUEST, saveFileReviewFlagAction);
  yield takeLatest(
    GENERATE_CANDIDATE_SIGN_LINK_REQUEST,
    generateCandidateSignLinkAction
  );
  yield takeLatest(SUBMIT_SIGN_REQUEST, submitSignAction);
}
