import { call, put, select, takeEvery, takeLatest } from 'redux-saga/effects';
import {
  proceedEmploymentHistoryError,
  proceedEmploymentHistorySuccess,
  setEmploymentHistoryData,
  setEmploymentGapData,
  setIsInitCompleted,
} from './action';
import {
  GET_EMPLOYMENTHISTORY_REQUEST,
  SAVE_EMPLOYMENTHISTORY_REQUEST,
  SAVE_EMPLOYMENTGAP_REQUEST,
  DELETE_EMPLOYMENTHISTORY_REQUEST,
} from './actionTypes';
import { callRequestWithToken, unauthorizedHandler } from '@utils/request';
import {
  deleteEmploymentHistoryUrl,
  getEmploymentHistoryUrl,
  saveEmploymentGapUrl,
  saveEmploymentHistoryUrl,
} from '@utils/apis';
import { makeSelectRecruitmentProcessIds } from '@redux/fscApplication/eobCommonInfo/selectors';
import {
  makeSelectEmploymentHistoryData,
  makeSelectIsInitCompleted,
} from './selectors';

export function* getEmploymentHistoryAction({ data }) {
  try {
    const params = {
      userId: data.prospectId,
      recruitmentProcessId: data.id,
    };

    const res = yield callRequestWithToken({
      method: 'post',
      url: getEmploymentHistoryUrl,
      params,
    });

    if (res.data.success.status) {
      res.data?.data?.employmentHistoryResult?.personalInfoEmploymentHistory?.personalInfoEmploymentHistoryItem?.map(
        (item) => {
          item.saveCount = 0;
          item.nonFaEmploymentFileList = [];
          item.nonFaFormFileList = [];
          item.resignationFileList = [];
        }
      );
      yield put(
        setEmploymentHistoryData(
          res.data?.data?.employmentHistoryResult?.personalInfoEmploymentHistory
            ?.personalInfoEmploymentHistoryItem ?? {}
        )
      );
      yield put(
        setEmploymentGapData(
          res.data?.data?.employmentHistoryResult
            ?.personalInfoEmploymentGapItem ?? {}
        )
      );
      const isInitCompleted = yield select(makeSelectIsInitCompleted());
      if (!isInitCompleted) {
        yield put(setIsInitCompleted(true));
      }
      yield put(proceedEmploymentHistorySuccess());
    }
  } catch (err) {
    const { response } = err;
    yield call(unauthorizedHandler, response);
    yield put(
      proceedEmploymentHistoryError(err.response?.data?.success?.message)
    );
  }
}

export function* saveEmploymentHistoryAction({ data, index, setIsLoading }) {
  try {
    const recruitmentProcessIds = yield select(
      makeSelectRecruitmentProcessIds()
    );
    let params = {
      userId: recruitmentProcessIds.get('prospectId'),
      recruitmentProcessId: recruitmentProcessIds.get('id'),
      personalInfoEmploymentHistoryItem: data[index],
    };

    data[index].saveCount++;
    yield put(setEmploymentHistoryData(data));

    if (!data[index].id && data[index].saveCount != 1) {
      return;
    }
    // if there is no id, need loading immediately
    if (!data[index].id) {
      setIsLoading && setIsLoading({ isImmediate: true, isLoading: true });
    } else {
      setIsLoading && setIsLoading(true);
    }

    const res = yield callRequestWithToken({
      method: 'post',
      url: saveEmploymentHistoryUrl,
      params,
    });
    if (res.data.success.status) {
      if (!data[index].id) {
        const employmentHistoryData = yield select(
          makeSelectEmploymentHistoryData()
        );
        const copyData = employmentHistoryData.toJS();
        copyData[index].id =
          res.data.data?.result?.employmentHistoryItemId ?? '';
        yield put(setEmploymentHistoryData(copyData));

        // resave the latest data with ID
        if (data[index].saveCount != copyData[index].saveCount) {
          params = {
            userId: recruitmentProcessIds.get('prospectId'),
            recruitmentProcessId: recruitmentProcessIds.get('id'),
            personalInfoEmploymentHistoryItem: copyData[index],
          };
          const resaveRes = yield callRequestWithToken({
            method: 'post',
            url: saveEmploymentHistoryUrl,
            params,
          });
          setIsLoading && setIsLoading(false);
          if (resaveRes.data.success.status) {
            yield put(
              setEmploymentGapData(resaveRes.data.data.result.employmentGapItem)
            );
          } else {
            yield put(
              proceedEmploymentHistoryError(resaveRes.data?.success?.message)
            );
          }
        } else {
          setIsLoading && setIsLoading(false);
          yield put(
            setEmploymentGapData(res.data.data.result.employmentGapItem)
          );
        }
      } else {
        setIsLoading && setIsLoading(false);
        if (data[index].id === res.data.data?.result?.employmentHistoryItemId) {
          yield put(
            setEmploymentGapData(res.data.data.result.employmentGapItem)
          );
        } else {
          yield put(
            proceedEmploymentHistoryError(
              'duplicate record in DB for the record in index ' + index
            )
          );
        }
      }
    } else {
      setIsLoading && setIsLoading(false);
      yield put(proceedEmploymentHistoryError(res.data?.success?.message));
    }
  } catch (err) {
    const { response } = err;
    yield call(unauthorizedHandler, response);
    setIsLoading && setIsLoading(false);
    yield put(
      proceedEmploymentHistoryError(err.response?.data?.success?.message)
    );
  }
}

export function* saveEmploymentGapAction({ data, index }) {
  try {
    const recruitmentProcessIds = yield select(
      makeSelectRecruitmentProcessIds()
    );

    const params = {
      userId: recruitmentProcessIds.get('prospectId'),
      recruitmentProcessId: recruitmentProcessIds.get('id'),
      personalInfoEmploymentGapItem: data[index],
    };
    const res = yield callRequestWithToken({
      method: 'post',
      url: saveEmploymentGapUrl,
      params,
    });

    if (res.data.success.status) {
      yield put(setEmploymentGapData(data));
      yield put(proceedEmploymentHistorySuccess());
    } else {
      yield put(proceedEmploymentHistoryError(res.data?.success?.message));
    }
  } catch (err) {
    const { response } = err;
    yield call(unauthorizedHandler, response);
    yield put(
      proceedEmploymentHistoryError(err.response?.data?.success?.message)
    );
  }
}

export function* deleteEmploymentHistoryAction({ data, index, setIsLoading }) {
  try {
    const recruitmentProcessIds = yield select(
      makeSelectRecruitmentProcessIds()
    );

    const params = {
      userId: recruitmentProcessIds.get('prospectId'),
      recruitmentProcessId: recruitmentProcessIds.get('id'),
      personalInfoEmploymentHistoryItem: data[index],
    };
    setIsLoading && setIsLoading(true);
    const res = yield callRequestWithToken({
      method: 'post',
      url: deleteEmploymentHistoryUrl,
      params,
    });
    setIsLoading && setIsLoading(false);
    if (res.data.success.status) {
      yield put(proceedEmploymentHistorySuccess());
      yield put(setEmploymentGapData(res.data.data.employmentGapItem));
    }
  } catch (err) {
    const { response } = err;
    yield call(unauthorizedHandler, response);
    setIsLoading && setIsLoading(false);
    yield put(
      proceedEmploymentHistoryError(err.response?.data?.success?.message)
    );
  }
}

export default function* rootSaga() {
  yield takeLatest(GET_EMPLOYMENTHISTORY_REQUEST, getEmploymentHistoryAction);
  yield takeEvery(SAVE_EMPLOYMENTHISTORY_REQUEST, saveEmploymentHistoryAction);
  yield takeLatest(SAVE_EMPLOYMENTGAP_REQUEST, saveEmploymentGapAction);
  yield takeLatest(
    DELETE_EMPLOYMENTHISTORY_REQUEST,
    deleteEmploymentHistoryAction
  );
}
