export const SET_EMERGENCY_CONTACT_DATA =
  'irecruit/emergencyContact/SET_EMERGENCY_CONTACT_DATA';
export const SET_IS_INIT_COMPLETED =
  'irecruit/emergencyContact/SET_IS_INIT_COMPLETED';
export const SET_INITIAL_STATE = 'irecruit/emergencyContact/SET_INITIAL_STATE';

export const GET_EMERGENCY_CONTACT_REQUEST =
  'irecruit/emergencyContact/GET_EMERGENCY_CONTACT_REQUEST';
export const SAVE_EMERGENCY_CONTACT_REQUEST =
  'irecruit/emergencyContact/SAVE_EMERGENCY_CONTACT_REQUEST';
export const PROCEED_EMERGENCY_CONTACT_SUCCESS =
  'irecruit/emergencyContact/ADD_EMERGENCY_CONTACT_SUCCESS';
export const PROCEED_EMERGENCY_CONTACT_ERROR =
  'irecruit/emergencyContact/ADD_EMERGENCY_CONTACT_ERROR';

export const DELETE_EMERGENCY_CONTACT_REQUEST =
  'irecruit/emergencyContact/DELETE_EMERGENCY_CONTACT_REQUEST';

export const UPDATE_EMERGENCY_CONTACT_REQUEST =
  'irecruit/emergencyContact/UPDATE_EMERGENCY_CONTACT_REQUEST';
