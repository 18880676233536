import { fromJS } from 'immutable';
import {
  COMMON_SUCCESS,
  COMMON_ERROR,
  UPLOAD_FILE,
  DELETE_FILE,
} from './actionType';

const initialState = fromJS({
  loading: false,
  error: false,
  success: false,
  message: null,
});
export default function (state = initialState, action) {
  switch (action.type) {
    case UPLOAD_FILE:
    case DELETE_FILE:
      return state
        .set('loading', true)
        .set('success', false)
        .set('error', false)
        .set('message', null);
    case COMMON_SUCCESS:
      return state
        .set('loading', false)
        .set('success', true)
        .set('error', false)
        .set('message', action.message);
    case COMMON_ERROR:
      return state
        .set('loading', false)
        .set('success', false)
        .set('error', true)
        .set('message', action.message);
    default:
      return state;
  }
}
