export const SET_EDUCATION_DATA = 'irecruit/assessment/SET_EDUCATION_DATA';

export const GET_EDUCATION_REQUEST =
  'irecruit/assessment/GET_EDUCATION_REQUEST';

export const SAVE_EDUCATION_REQUEST =
  'irecruit/assessment/SAVE_EDUCATION_REQUEST';

export const PROCEED_EDUCATION_SUCCESS =
  'irecruit/assessment/PROCEED_EDUCATION_SUCCESS';
export const PROCEED_EDUCATION_ERROR =
  'irecruit/assessment/PROCEED_EDUCATION_ERROR';

export const UPLOAD_DOCUMENTS_REQUEST =
  'irecruit/assessment/UPLOAD_DOCUMENTS_REQUEST';

export const DELETE_DOCUMENTS_REQUEST =
  'irecruit/assessment/DELETE_DOCUMENTS_REQUEST';
