import {
  GET_HOME_SCREEN_APPLICATION_STATUS,
  GET_STEP_BAR_REQUEST,
  PROCEED_EOB_COMMON_INFO_ERROR,
  PROCEED_EOB_COMMON_INFO_SUCCESS,
  PROCEED_STEP_BAR_ERROR,
  PROCEED_STEP_BAR_SUCCESS,
  PROCEED_STEP_STATUS_SUCCESS,
  SAVE_HOME_SCREEN_APPLICATION_STATUS,
  SAVE_STEP_BAR_REQUEST,
  SET_DISABLED,
  SET_HOME_SCREEN_APPLICATION_STATUS,
  SET_STEP_STATUS,
} from './actionTypes';

export const proceedEobCommonInfoSuccess = (message) => ({
  type: PROCEED_EOB_COMMON_INFO_SUCCESS,
  message,
});

export const proceedStepStatusSuccess = (data) => ({
  type: PROCEED_STEP_STATUS_SUCCESS,
  data,
});

export const proceedStepBarSuccess = (message) => ({
  type: PROCEED_STEP_BAR_SUCCESS,
  message,
});

export const proceedStepBarError = (message) => ({
  type: PROCEED_STEP_BAR_ERROR,
  message,
});

export const proceedEobCommonInfoError = (message) => ({
  type: PROCEED_EOB_COMMON_INFO_ERROR,
  message,
});

export const getStepBarRequest = (data) => ({
  type: GET_STEP_BAR_REQUEST,
  data,
});

export const saveStepBarRequest = (data) => ({
  type: SAVE_STEP_BAR_REQUEST,
  data,
});

export const setStepStatus = (data) => ({
  type: SET_STEP_STATUS,
  data,
});

export const getHomeScreenApplicationStatusRequest = (data) => ({
  type: GET_HOME_SCREEN_APPLICATION_STATUS,
  data,
});

export const saveHomeScreenApplicationStatusRequest = (data) => ({
  type: SAVE_HOME_SCREEN_APPLICATION_STATUS,
  data,
});

export const setHomeScreenApplicationStatus = (data) => ({
  type: SET_HOME_SCREEN_APPLICATION_STATUS,
  data,
});

export const setDisabled = (data) => ({
  type: SET_DISABLED,
  data,
});
