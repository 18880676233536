export const tabItems = [
  { title: 'RES5/M5 certificate', eventKey: 'M5' },
  { title: 'M9/CM-LIP/CM-LIC certificate', eventKey: 'M9' },
  { title: 'M9A/CM-LIP/CM-LIC certificate', eventKey: 'M9A' },
  { title: 'Health Insurance (HI) certificate', eventKey: 'HI' },
];
export const tabItems_mobile = [
  { title: 'RES5', eventKey: 'M5' },
  { title: 'M9', eventKey: 'M9' },
  { title: 'M9A', eventKey: 'M9A' },
  { title: 'HI', eventKey: 'HI' },
];

export const questions = [
  {
    name: 'isPassModuleFive',
    label: 'Have you passed any of the below examinations?',
    labelWithNo:
      'It is a mandatory requirement to attend and obtain a passing grade for CMFAS Module 5 (M5) examination. You may register at your own cost or speak to your leader about cost reimbursement.',
  },
  {
    name: 'isPassModuleNine',
    label: 'Have you passed any of the below examinations? ',
    labelWithNo:
      'It is a mandatory requirement to attend and obtain a passing grade for CMFAS Module 9 (M9) examination. You may register at your own cost or speak to your leader about cost reimbursement.',
    linkText: 'Check M9 exemption criteria',
  },
  {
    name: 'isPassModuleNinea',
    label: 'Have you passed any of the below examinations? ',
    labelWithNo:
      'It is a mandatory requirement to attend and obtain a passing grade for CMFAS Module 9A (M9A) examination. You may register at your own cost or speak to your leader about cost reimbursement.',
    linkText: 'Check M9A exemption criteria',
  },
  {
    name: 'isPassHealthInsurance',
    label: 'Have you passed the CMFAS Health Insurance (HI) examination?',
    labelWithNo:
      'It is a mandatory requirement to attend and obtain a passing grade for CMFAS Health Insurance (HI) examination. You may register at your own cost or speak to your leader about cost reimbursement.',
  },
];

export const DOCUMENTS_INFO = {
  M5_Cert: 'M5 Certificate (issued within 3 years)',

  M9_Cert: 'M9 Certificate',
  M9_CLI: 'Certificate in Life Insurance',
  M9_CLI_ILP: 'Certificate in Life Insurance (Investment-Linked Policies)',
  M9_Exemption: 'Exemption document(s)',

  M9A_Cert: 'M9A Certificate',
  M9A_Exemption: 'Exemption document(s)',

  HI_Cert: 'Health Insurance (HI) Certificate',
  HI_MLC: 'MediShield Life Certificate',
};
