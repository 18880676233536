import { fromJS } from 'immutable';
import {
  VALIDATE_CONSENT_DATA_REQUEST,
  VALIDATE_CONSENT_DATA_SUCCESS,
  VALIDATE_CONSENT_DATA_ERROR,
  SEND_CONSENT_DATA_REQUEST,
  SEND_CONSENT_DATA_SUCCESS,
  SEND_CONSENT_DATA_ERROR,
} from './actionType';

const initialState = fromJS({
  loading: false,
  error: false,
  success: false,
  message: null,
  consent: {},
  consentResponse: {},
});
export default function (state = initialState, action) {
  switch (action.type) {
    case VALIDATE_CONSENT_DATA_REQUEST:
    case SEND_CONSENT_DATA_REQUEST:
      return state
        .set('loading', true)
        .set('success', false)
        .set('error', false)
        .set('message', null);
    case VALIDATE_CONSENT_DATA_SUCCESS: {
      let newState = state.set('loading', false).set('success', true);
      if (action.consent) {
        newState = newState.set('consent', fromJS(action.consent));
      }
      return newState;
    }
    case SEND_CONSENT_DATA_SUCCESS:
      return state
        .set('loading', false)
        .set('success', true)
        .set('consentResponse', fromJS(action.message));
    case VALIDATE_CONSENT_DATA_ERROR:
    case SEND_CONSENT_DATA_ERROR:
      return state
        .set('loading', false)
        .set('error', true)
        .set('message', action.error);
    default:
      return state;
  }
}
