// file deepcode ignore DuplicateCaseBody:
import { fromJS } from 'immutable';
import {
  GET_USER_INFO_REQUEST,
  GET_USER_INFO_SUCCESS,
  GET_USER_INFO_ERROR,
  GET_PROFILE_REQUEST,
  GET_PROFILE_SUCCESS,
  GET_PROFILE_ERROR,
  GET_PROFILE_THUMBNAILS_REQUEST,
  GET_PROFILE_THUMBNAILS_REQUEST_SUCCESS,
  GET_PROFILE_THUMBNAILS_REQUEST_ERROR,
} from './actionTypes';

const initialState = fromJS({
  agency: [],
  leader: [],
  thumbnails: [],
  loading: false,
  error: false,
  success: false,
  message: null,
});

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_PROFILE_REQUEST:
    case GET_PROFILE_THUMBNAILS_REQUEST:
      return state
        .set('loading', true)
        .set('success', false)
        .set('error', false)
        .set('message', null);
    case GET_USER_INFO_REQUEST:
      return state
        .set('loading', true)
        .set('success', false)
        .set('error', false)
        .set('message', null);
    case GET_PROFILE_ERROR:
    case GET_PROFILE_THUMBNAILS_REQUEST_ERROR:
      return state
        .set('loading', false)
        .set('error', true)
        .set('message', action.error);
    case GET_USER_INFO_ERROR:
      return state
        .set('loading', false)
        .set('error', true)
        .set('message', action.error);
    case GET_USER_INFO_SUCCESS:
      return state.set('loading', false).set('success', true);
    case GET_PROFILE_SUCCESS:
      return state
        .set('loading', false)
        .set('success', true)
        .set('agency', fromJS(action.agency))
        .set('leader', fromJS(action.leader));
    case GET_PROFILE_THUMBNAILS_REQUEST_SUCCESS:
      return state
        .set('loading', false)
        .set('success', true)
        .set('thumbnails', fromJS(action.thumbnails));
    default:
      return state;
  }
}
