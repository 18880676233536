export const SET_FAMILYINFO_DATA = 'irecruit/familyInfo/SET_FAMILYINFO_DATA';
export const SET_IS_INIT_COMPLETED =
  'irecruit/familyInfo/SET_IS_INIT_COMPLETED';
export const SET_INITIAL_STATE = 'irecruit/familyInfo/SET_INITIAL_STATE';

export const GET_FAMILYINFO_REQUEST =
  'irecruit/familyInfo/GET_FAMILYINFO_REQUEST';
export const SAVE_FAMILYINFO_REQUEST =
  'irecruit/familyInfo/SAVE_FAMILYINFO_REQUEST';
export const PROCEED_FAMILYINFO_SUCCESS =
  'irecruit/familyInfo/ADD_FAMILYINFO_SUCCESS';
export const PROCEED_FAMILYINFO_ERROR =
  'irecruit/familyInfo/ADD_FAMILYINFO_ERROR';

export const DELETE_FAMILYINFO_REQUEST =
  'irecruit/familyInfo/DELETE_FAMILYINFO_REQUEST';

export const UPDATE_FAMILYINFO_REQUEST =
  'irecruit/familyInfo/UPDATE_FAMILYINFO_REQUEST';
