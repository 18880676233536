export const GET_BUSINESS_REQUEST =
  'irecruit/BusinessInterestDeclaration/GET_BUSINESS_REQUEST';
export const SET_BUSINESS_REQUEST =
  'irecruit/BusinessInterestDeclaration/SET_BUSINESS_REQUEST';
export const DELETE_BUSINESS_REQUEST =
  'irecruit/BusinessInterestDeclaration/DELETE_BUSINESS_REQUEST';
export const SET_BUSINESS_QUESTION_REQUEST =
  'irecruit/BusinessInterestDeclaration/SET_BUSINESS_QUESTION_REQUEST';
export const SUBMIT_BUSINESS_REQUEST =
  'irecruit/BusinessInterestDeclaration/SUBMIT_BUSINESS_REQUEST';
export const SUCCESS_BUSINESS =
  'irecruit/BusinessInterestDeclaration/SUCCESS_BUSINESS';
export const ERROR_BUSINESS =
  'irecruit/BusinessInterestDeclaration/ERROR_BUSINESS';
export const GET_BUSINESS_REQUEST_DATA =
  'irecruit/BusinessInterestDeclaration/GET_BUSINESS_REQUEST_DATA';
