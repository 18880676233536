import {
  GET_USER_INFO_SUCCESS,
  GET_USER_INFO_ERROR,
  GET_USER_INFO_REQUEST,
  GET_PROFILE_REQUEST,
  GET_PROFILE_SUCCESS,
  GET_PROFILE_ERROR,
  GET_PROFILE_THUMBNAILS_REQUEST,
  GET_PROFILE_THUMBNAILS_REQUEST_SUCCESS,
  GET_PROFILE_THUMBNAILS_REQUEST_ERROR,
} from './actionTypes';

export const getUserInfo = () => ({
  type: GET_USER_INFO_REQUEST,
});

export const getUserInfoSuccess = (data) => ({
  type: GET_USER_INFO_SUCCESS,
  data,
});

export const getUserInfoError = (error) => ({
  type: GET_USER_INFO_ERROR,
  error,
});

export const getProfile = (profileId = null) => ({
  type: GET_PROFILE_REQUEST,
  profileId,
});

export const getProfileSuccess = (agency = null, leader = null) => ({
  type: GET_PROFILE_SUCCESS,
  agency,
  leader,
});

export const getProfileError = (error) => ({
  type: GET_PROFILE_ERROR,
  error,
});

export const getProfileThumbnails = () => ({
  type: GET_PROFILE_THUMBNAILS_REQUEST,
});

export const getProfileThumbnailsSuccess = (thumbnails) => ({
  type: GET_PROFILE_THUMBNAILS_REQUEST_SUCCESS,
  thumbnails,
});

export const getProfileThumbnailsError = (error) => ({
  type: GET_PROFILE_THUMBNAILS_REQUEST_ERROR,
  error,
});
