import {
  SET_BACKGROUND_DATA,
  GET_BACKGROUND_REQUEST,
  SAVE_BACKGROUND_REQUEST,
  PROCEED_BACKGROUND_SUCCESS,
  PROCEED_BACKGROUND_ERROR,
} from './actionTypes';

export const setBackgroundData = (data) => ({
  type: SET_BACKGROUND_DATA,
  data,
});

export const getBackgroundRequest = (data) => ({
  type: GET_BACKGROUND_REQUEST,
  data,
});

export const saveBackgroundRequest = (data) => ({
  type: SAVE_BACKGROUND_REQUEST,
  data,
});

export const proceedBackgroundSuccess = (message) => ({
  type: PROCEED_BACKGROUND_SUCCESS,
  message,
});

export const proceedBackgroundError = (message) => ({
  type: PROCEED_BACKGROUND_ERROR,
  message,
});
