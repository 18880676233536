import { call, put, select, takeLatest } from 'redux-saga/effects';
import {
  proceedEmploymentAssessmentError,
  proceedEmploymentAssessmentSuccess,
  setEmploymentAssessmentData,
  setIsInitCompleted,
} from './action';
import {
  GET_EMPLOYMENT_ASSESSMENT_REQUEST,
  SAVE_EMPLOYMENT_ASSESSMENT_REQUEST,
} from './actionTypes';
import { callRequestWithToken, unauthorizedHandler } from '@utils/request';
import {
  getEmploymentAssessmentUrl,
  saveEmploymentAssessmentUrl,
} from '@utils/apis';
import { makeSelectRecruitmentProcessIds } from '@redux/fscApplication/eobCommonInfo/selectors';
import { makeSelectIsInitCompleted } from './selectors';

export function* getEmploymentAssessmentAction({ data }) {
  try {
    const params = {
      userId: data.prospectId,
      recruitmentProcessId: data.id,
    };

    const res = yield callRequestWithToken({
      method: 'post',
      url: getEmploymentAssessmentUrl,
      params,
    });

    if (res.data.success.status) {
      yield put(setEmploymentAssessmentData(res.data.data.workExperience));
      const isInitCompleted = yield select(makeSelectIsInitCompleted());
      if (!isInitCompleted) {
        yield put(setIsInitCompleted(true));
      }
      yield put(proceedEmploymentAssessmentSuccess());
    }
  } catch (err) {
    const { response } = err;
    yield call(unauthorizedHandler, response);
    yield put(
      proceedEmploymentAssessmentError(err.response?.data?.success?.message)
    );
  }
}

export function* saveEmploymentAssessmentAction({ radioData, companyName }) {
  try {
    const recruitmentProcessIds = yield select(
      makeSelectRecruitmentProcessIds()
    );

    const params = {
      userId: recruitmentProcessIds.get('prospectId'),
      recruitmentProcessId: recruitmentProcessIds.get('id'),
      workExperience: {
        companyNames: companyName,
        recruitmentProcessSelectAnswer: radioData,
      },
    };
    // yield put(setEmploymentAssessmentData(requestData.workExperience));
    const res = yield callRequestWithToken({
      method: 'post',
      url: saveEmploymentAssessmentUrl,
      params,
    });

    if (res.data.success.status) {
      yield put(setEmploymentAssessmentData(params.workExperience));
      yield put(proceedEmploymentAssessmentSuccess());
    } else {
      yield put(proceedEmploymentAssessmentError(res.data?.success?.message));
    }
  } catch (err) {
    const { response } = err;
    yield call(unauthorizedHandler, response);
    yield put(
      proceedEmploymentAssessmentError(err.response?.data?.success?.message)
    );
  }
}

export default function* rootSaga() {
  yield takeLatest(
    GET_EMPLOYMENT_ASSESSMENT_REQUEST,
    getEmploymentAssessmentAction
  );
  yield takeLatest(
    SAVE_EMPLOYMENT_ASSESSMENT_REQUEST,
    saveEmploymentAssessmentAction
  );
}
