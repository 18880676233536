import { fromJS } from 'immutable';
import {
  ERROR_BUSINESS,
  GET_BUSINESS_REQUEST,
  SUCCESS_BUSINESS,
  GET_BUSINESS_REQUEST_DATA,
  SET_BUSINESS_REQUEST,
  SET_BUSINESS_QUESTION_REQUEST,
  DELETE_BUSINESS_REQUEST,
  SUBMIT_BUSINESS_REQUEST,
} from './actionType';

const initialState = fromJS({
  loading: false,
  error: false,
  success: false,
  message: null,
  businessInterestDeclaration: {},
  consentResponse: {},
});
export default function (state = initialState, action) {
  switch (action.type) {
    case GET_BUSINESS_REQUEST:
    case SET_BUSINESS_REQUEST:
    case SET_BUSINESS_QUESTION_REQUEST:
    case DELETE_BUSINESS_REQUEST:
    case SUBMIT_BUSINESS_REQUEST:
      return state
        .set('loading', true)
        .set('success', false)
        .set('error', false)
        .set('message', null);
    case SUCCESS_BUSINESS:
      return state
        .set('loading', false)
        .set('success', true)
        .set('error', false)
        .set('message', action.message);
    case ERROR_BUSINESS:
      return state
        .set('loading', false)
        .set('success', false)
        .set('error', true)
        .set('message', action.message);
    case GET_BUSINESS_REQUEST_DATA:
      return state.set('businessInterestDeclaration', fromJS(action.data));
    default:
      return state;
  }
}
