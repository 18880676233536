export const GET_USER_INFO_REQUEST = 'irecruit/dashboard/GET_USER_INFO_REQUEST';
export const GET_USER_INFO_SUCCESS = 'irecruit/dashboard/GET_USER_INFO_SUCCESS';
export const GET_USER_INFO_ERROR = 'irecruit/dashboard/GET_USER_INFO_ERROR';
export const GET_PROFILE_REQUEST = 'irecruit/dashboard/GET_PROFILE_REQUEST';
export const GET_PROFILE_SUCCESS = 'irecruit/dashboard/GET_PROFILE_SUCCESS';
export const GET_PROFILE_ERROR = 'irecruit/dashboard/GET_PROFILE_ERROR';
export const GET_PROFILE_THUMBNAILS_REQUEST =
  'irecruit/dashboard/GET_PROFILE_THUMBNAILS_REQUEST';
export const GET_PROFILE_THUMBNAILS_REQUEST_SUCCESS =
  'irecruit/dashboard/GET_PROFILE_THUMBNAILS_REQUEST_SUCCESS';
export const GET_PROFILE_THUMBNAILS_REQUEST_ERROR =
  'irecruit/dashboard/GET_PROFILE_THUMBNAILS_REQUEST_ERROR';
