import { call, put, takeLatest } from 'redux-saga/effects';
import { callRequestWithToken, unauthorizedHandler } from '@utils/request';
import {
  getInvitedSlotError,
  setInvitedSlotDate,
  submitInvitedSlotError,
  setInterviewStatus,
  submitInvitedSlotSuccess,
} from './actions';

import {
  GET_INTERVIEW_SLOT_DETAIL_REQUEST,
  SUBMIT_INTERVIEW_SLOT_REQUEST,
} from './actionTypes';
import { submitInterviewSlotUrl, getInvitedSlotDetailUrl } from '@utils/apis';
export function* getInvitedSlotDetailAction({ data }) {
  try {
    const params = {
      recruitmentProcessId: data,
    };
    const res = yield callRequestWithToken({
      method: 'post',
      url: getInvitedSlotDetailUrl,
      params,
    });
    if (res.status == 200) {
      yield put(setInvitedSlotDate(res.data?.data));
    } else {
      yield put(getInvitedSlotError('Token not provide to get data'));
    }
  } catch (err) {
    const { response } = err;
    yield call(unauthorizedHandler, response);
    yield put(getInvitedSlotError(err.response?.data?.success?.message));
  }
}

export function* submitInterviewSlotAction({ data }) {
  try {
    const res = yield callRequestWithToken({
      method: 'post',
      url: submitInterviewSlotUrl,
      params: data,
    });
    if (res.status == 200) {
      yield put(setInterviewStatus(res.data?.data));
      yield put(submitInvitedSlotSuccess('Submit interviw slot success'));
    } else {
      yield put(submitInvitedSlotError('Token not provide to get data'));
    }
  } catch (err) {
    const { response } = err;
    yield call(unauthorizedHandler, response);
    yield put(submitInvitedSlotError(err.response?.data?.success?.message));
  }
}
export default function* rootSaga() {
  yield takeLatest(
    GET_INTERVIEW_SLOT_DETAIL_REQUEST,
    getInvitedSlotDetailAction
  );
  yield takeLatest(SUBMIT_INTERVIEW_SLOT_REQUEST, submitInterviewSlotAction);
}
