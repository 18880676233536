export const PROCEED_EOB_COMMON_INFO_SUCCESS =
  'irecruit/eobCommonInfo/PROCEED_EOB_COMMON_INFO_SUCCESS';
export const PROCEED_EOB_COMMON_INFO_ERROR =
  'irecruit/eobCommonInfo/PROCEED_EOB_COMMON_INFO_ERROR';
export const PROCEED_STEP_STATUS_SUCCESS =
  'irecruit/eobCommonInfo/PROCEED_STEP_STATUS_SUCCESS';

export const SAVE_STEP_BAR_REQUEST =
  'irecruit/eobCommonInfo/SAVE_STEP_BAR_REQUEST';
export const GET_STEP_BAR_REQUEST =
  'irecruit/eobCommonInfo/GET_STEP_BAR_REQUEST';
export const PROCEED_STEP_BAR_SUCCESS =
  'irecruit/eobCommonInfo/PROCEED_STEP_BAR_SUCCESS';
export const PROCEED_STEP_BAR_ERROR =
  'irecruit/eobCommonInfo/PROCEED_STEP_BAR_ERROR';
export const SET_STEP_STATUS = 'irecruit/eobCommonInfo/SET_STEP_STATUS';

export const GET_HOME_SCREEN_APPLICATION_STATUS =
  'irecruit/eobCommonInfo/GET_HOME_SCREEN_APPLICATION_STATUS';
export const SAVE_HOME_SCREEN_APPLICATION_STATUS =
  'irecruit/eobCommonInfo/SAVE_HOME_SCREEN_APPLICATION_STATUS';
export const SET_HOME_SCREEN_APPLICATION_STATUS =
  'irecruit/eobCommonInfo/SET_HOME_SCREEN_APPLICATION_STATUS';

export const SET_DISABLED = 'irecruit/eobCommonInfo/SET_DISABLED';
