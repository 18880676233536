import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import appInsights from './index';

const RouteTracking = ({ children }) => {
  const location = useLocation();

  useEffect(() => {
    if (appInsights) {
      // Track a page view
      if (location?.pathname) {
        appInsights.trackPageView({ name: location.pathname });
      }
    }
  }, [location.pathname]);

  return children;
};

export default RouteTracking;
