import { createSelector } from 'reselect';
import includes from 'lodash/includes';

const selectApp = (state) => state.app;

export const makeSelectUserData = () =>
  createSelector(selectApp, (appState) => appState.get('userData'));
export const makeSelectToken = () =>
  createSelector(selectApp, (appState) => appState.get('token'));
export const makeSelectRequest = () =>
  createSelector(selectApp, (appState) =>
    appState.filter((value, key) =>
      includes(['loading', 'error', 'success', 'message'], key)
    )
  );
export const makeSelectMaintenanceAlert = () =>
  createSelector(selectApp, (appState) => appState.get('maintenanceAlert'));
export const makeSelectMaintenanceStatus = () =>
  createSelector(selectApp, (appState) => appState.get('maintenanceStatus'));
export const makeSelectUnauthorized = () =>
  createSelector(selectApp, (appState) => appState.get('unauthorized'));
