import { fromJS } from 'immutable';
import {
  GET_FITPROPER_REQUEST,
  GET_FITPROPER_SUCCESS,
  GET_FITPROPER_ERROR,
  SET_FITPROPER_DATA,
  SAVE_FITPROPER_REQUEST,
  SAVE_FITPROPER_ERROR,
  SAVE_FITPROPER_SUCCESS,
  SET_INITIAL_STATE,
} from './actionType';

const initialState = fromJS({
  loading: false,
  error: false,
  success: false,
  message: null,
  criteria: {},
  consentResponse: {},
});
export default function (state = initialState, action) {
  switch (action.type) {
    case SET_INITIAL_STATE:
      return initialState;
    case GET_FITPROPER_REQUEST:
      return state
        .set('loading', true)
        .set('success', false)
        .set('error', false)
        .set('message', null);
    case GET_FITPROPER_SUCCESS:
      return state
        .set('loading', false)
        .set('success', true)
        .set('error', false)
        .set('message', action.message);
    case GET_FITPROPER_ERROR:
      return state
        .set('loading', false)
        .set('success', false)
        .set('error', true)
        .set('message', action.message);
    case SAVE_FITPROPER_REQUEST:
      return state
        .set('loading', true)
        .set('success', false)
        .set('error', false)
        .set('message', null);
    case SAVE_FITPROPER_SUCCESS:
      return state
        .set('loading', false)
        .set('success', true)
        .set('error', false)
        .set('message', action.message);
    case SAVE_FITPROPER_ERROR:
      return state
        .set('loading', false)
        .set('success', false)
        .set('error', true)
        .set('message', action.message);
    case SET_FITPROPER_DATA:
      return state.set('criteria', fromJS(action.data));
    default:
      return state;
  }
}
