import { fromJS } from 'immutable';
import {
  SET_INITIAL_STATE,
  SAVE_FSC_SIGN_DOC_DATA,
  GET_FSC_SIGN_DOC_DATA_REQUEST,
  SAVE_FILE_REVIEW_FLAG_REQUEST,
  GENERATE_CANDIDATE_SIGN_LINK_REQUEST,
  SUBMIT_SIGN_REQUEST,
  GENERATE_CANDIDATE_SIGN_LINK_SUCCESS,
  PROCEED_FSC_SIGN_DOC_SUCCESS,
  SUBMIT_SIGN_SUCCESS,
  PROCEED_FSC_SIGN_DOC_ERROR,
} from './actionTypes';

const initialState = fromJS({
  loading: false,
  error: false,
  success: false,
  message: null,
  candidateSignLinkResult: {},
  candidateSubmitResult: null,
});

export default function (state = initialState, action) {
  switch (action.type) {
    case SET_INITIAL_STATE:
      return initialState;
    case SAVE_FSC_SIGN_DOC_DATA:
      return state.set('signDocData', fromJS(action.data));

    case GET_FSC_SIGN_DOC_DATA_REQUEST:
      return state
        .set('loading', true)
        .set('success', false)
        .set('error', false)
        .set('message', null);

    case SAVE_FILE_REVIEW_FLAG_REQUEST:
      return state
        .set('loading', true)
        .set('success', false)
        .set('error', false)
        .set('message', null);

    case GENERATE_CANDIDATE_SIGN_LINK_REQUEST:
      return state
        .set('loading', true)
        .set('success', false)
        .set('error', false)
        .set('message', null);

    case SUBMIT_SIGN_REQUEST:
      return state
        .set('loading', true)
        .set('success', false)
        .set('error', false)
        .set('message', null);

    case GENERATE_CANDIDATE_SIGN_LINK_SUCCESS:
      return state
        .set('loading', false)
        .set('success', true)
        .set('candidateSignLinkResult', fromJS(action.data));

    case SUBMIT_SIGN_SUCCESS:
      return state
        .set('loading', false)
        .set('success', true)
        .set('candidateSubmitResult', fromJS(action.data));
    case PROCEED_FSC_SIGN_DOC_SUCCESS:
      return state.set('loading', false).set('success', true);
    case PROCEED_FSC_SIGN_DOC_ERROR:
      return state
        .set('loading', false)
        .set('error', true)
        .set('message', action.message);

    default:
      return state;
  }
}
