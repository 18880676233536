export const theme = {
  color: {
    w: '#FFFFFF',
    k: '#000000',
    p1: '#D31145',
    p2: '#596C80',
    p3: '#554344',
    p4: '#363E3F',
    p5: '#333D47',
    s1: '#E86487',
    s2: '#FBBF56',
    s3: '#97CB5D',
    s4: '#46D3BD',
    s5: '#22A8DA',
    s6: '#9962D2',
    t1: '#F6CCD7',
    t2: '#B8123E',
    t3: '#F9E1B8',
    t4: '#C36418',
    t5: '#C6EB9E',
    t6: '#65962E',
    t7: '#848A90',
    t8: '#1F78AD',
    t9: '#E4708F',
    t10: '#4C4794',
    t11: '#3da758',
    b1: '#D4EDF1',
    b2: '#FAF9F3',
    b3: '#F0EEE1',
    b4: '#DBDAD4',
    b5: '#B3B1A8',
    b6: '#6F6E68',
    b8: '#302F2C',
    b9: '#292025',
    b10: '#D6D8DA',
    b11: '#F7F7F8',
    b12: '#FCF1C8',
    b13: '#d8edde',
    b14: '#F9D65C',
    b15: '#9ED3AB',
    u2: '#FF5500',
    u3: '#405B70',
    u4: '#769FAB',
  },
};

export const BASE_API = `${process.env.REACT_APP_BASE_URL}/api`;
export const BASE_WEB = `${BASE_API}/web`;
export const BASE_PROFILE_URL = process.env.REACT_APP_BASE_PROFILE_URL;
export const CALLIDUS_URL = process.env.REACT_APP_CALLIDUS_URL;

export const PROFILE_STATUS = {
  submit: 'submitted',
  draft: 'draft',
  approve: 'approved',
  reject: 'rejected',
  publish: 'published',
};

export const LOADING_TEXT = 'Loading...';

export const REEF_STATUS = {
  APPROVED: 'Approved',
  REJECTED_DS: 'Rejected',
  APPEAL_APPROVED: 'Appeal Approved',
  APPROVED_LEADER: 'Pending TD Ops review',
  SUBMIT: "Pending leader's review",
  REJECTED_LEADER: "Pending candidate's follow-up",
  DRAFT: 'Draft',
};

export const PROFILE_TYPE = {
  agency: 'agency',
  leader: 'leader',
};

export const EPS = {
  STATUS: {
    AS: 'Application Successful',
    AUS: 'Application Unsuccessful',
    DR: 'Draft',
    IB: 'Interview booked',
    IRB: 'Interview results pending',
    PAFU: "Pending applicant's follow-up",
    PAS: "Pending applicant's submission",
    PES: 'Pending EPS Schedule',
    PIB: 'Pending interview booking',
    PT2LR: "Pending T2 leader's review",
    PT3LR: "Pending T3 leader's review",
    DEL: 'Deleted',
    FR: 'Follow-up Required',
    FOL: "Pending applicant's follow-up",
    PRFU: 'Pending review of follow-up',
  },
  DRAFT: 'draft',
  SUBMIT: 'submit',
  ACCEPT: 'accept',
  DECLINE: 'decline',
  DOC_TYPES: [
    {
      type: 'studentMatriculationCard',
      name: 'Student Matriculation Card',
      isRequired: ['STUDENTS'],
      toShow: ['STUDENTS'],
      supDescription: '',
      maximum: 1,
    },
    {
      type: 'proofOfIncome',
      name: 'Proof of Income',
      isRequired: ['NORMAL', 'FAS'],
      toShow: ['NORMAL', 'FAS'],
      supDescription:
        'Eg. Latest NOA, Income statement or last 6 months pay slips',
      maximum: 6,
    },
    {
      type: 'resume',
      name: 'Resume',
      isRequired: ['FRESH GRADUATE (UNI/POLY)', 'NORMAL', 'FAS'],
      toShow: ['STUDENTS', 'FRESH GRADUATE (UNI/POLY)', 'NORMAL', 'FAS'],
      supDescription: '',
      maximum: 1,
    },
    {
      type: 'momSelfAssessment',
      name: 'MOM self-assessment results',
      isRequired: ['FAS'],
      toShow: ['FAS'],
      supDescription:
        'Please complete the self-assessment at <a href="http://www.mom.gov.sg/eservices/services/employment-s-pass-self-assessment-tool" target="_blank">http://www.mom.gov.sg/eservices/services/employment-s-pass-self-assessment-tool</a>',
      maximum: 1,
    },
    {
      type: 'others',
      name: 'Education Certificate and Others',
      isRequired: ['STUDENTS', 'FRESH GRADUATE (UNI/POLY)', 'NORMAL', 'FAS'],
      toShow: ['STUDENTS', 'FRESH GRADUATE (UNI/POLY)', 'NORMAL', 'FAS'],
      supDescription: '',
      maximum: 6,
    },
  ],
  DOC_TYPE_NAME: {
    studentMatriculationCard: 'Student Matriculation Card',
    proofOfIncome: 'Proof of Income',
    resume: 'Resume',
    momSelfAssessmentResults: 'MOM self-assessment results',
    others: 'Others',
  },
  SUPPORTED_FILE_TYPES: [
    'application/pdf',
    '.doc',
    '.docx',
    'application/msword',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    'image/jpg',
    'image/jpeg',
    'image/png',
  ],
  SALES_LEADS_CATEGORY: {
    acquaintance: [
      { label: 'Third party referrals', value: 'Third party referrals' },
      { label: 'Networking groups', value: 'Networking groups' },
      {
        label: 'Contacts whom you have not been in in touch with for a while',
        value: 'Contacts whom you have not been in in touch with for a while',
      },
      { label: 'Others', value: 'Others' },
    ],
    warm: [
      { label: 'Friends', value: 'Friends' },
      { label: 'Family members', value: 'Family members' },
      { label: 'Extended family members', value: 'Extended family members' },
      {
        label: 'Networking groups in regular contact',
        value: 'Networking groups in regular contact',
      },
      { label: 'Others', value: 'Others' },
    ],
  },
};

export const REEF = {
  STATUS: {
    PLR: "Pending leader's review",
    PAS: "Pending candidate's submission",
    PAFU: "Pending candidate's follow-up",
  },
};

export const USER_DATA = {
  STATUS: {
    AUS: 'AGENCY UNAVAILABLE',
    LUS: 'LEADER UNAVAILABLE',
  },
};

export const DATE_FORMAT = {
  DDMM_JOIN: 'DDMM',
  MMYYYY: 'MM-YYYY',
  DDMMYYYY: 'DD-MM-YYYY',
  DDMMYYYY_JOIN: 'DDMMYYYY',
  YYYYMMDD: 'YYYY-MM-DD',
};

export const MYINFO_MODULE_TYPE = {
  EON: 'E-onBoarding Submission',
  EPS: 'EPS/FAS Submission',
  PIB: 'Personal-Info/Basic Submission',
};

export const SCHEDULE_SIGN_STATUS = {
  EXPIRED: 'Expired. Contact leader fo retriggering',
  PPS: "Pending Candidate's signature",
  PT2S: "Pending T2 leader's signature",
  PT3S: "Pending T3 leader's signature",
  PARS: 'Pending AIA Representative signature',
  Completed: 'Application completed',
};

export const MAINTENANCE_STATUS = {
  IN_MAINTENANCE: 'IN_MAINTENANCE',
  NORMAL: 'NORMAL',
  ERROR: 'ERROR',
  INITIAL: 'INITIAL',
};

export const BREAKPOINTS = {
  xs: 576,
  md: 768,
  lg: 992,
  xl: 1200,
};

export const CHANNEL_CODE = {
  AFA: 'AFA',
  AGY: 'AGY',
};

export const groupFA = ['Y1', 'Z1', 'Z2', 'Z3'];
export const groupAAG = ['Z1', 'Z2', 'Z3'];
export const groupTG = ['Y1'];

export const SUCCESS_CODE = 0;

export const BASIC_STATUS = {
  INIT: '0',
  INCOMPLETE: '1',
  COMPLETED: '2',
};

export const STEP_STATUS = {
  ...BASIC_STATUS,
};

export const BOOLEAN_FLAG = {
  Y: 'Y',
  N: 'N',
};

export const ELEMENT_ID_MAP = {
  jobSource: 'jobsource',
  background: 'background',
  education: 'education',
  cmfas: 'cmfas',
  bankInformation: 'bankinformation',
  cbsReport: 'cbsreport',
  taxResidence: 'taxresidence',
  fatcaDeclaration: 'fatcadeclaration',
  criteriaID: 'criteriaid',
  basicInfo: 'basicinfo',
  nationality: 'nationality',
  contact: 'contact',
  familyInfo: 'familyinfo',
  emergencyContact: 'emergencycontact',
  educationBackground: 'educationbackground',
  workExperience: 'workexperience',
  employmentAssessment: 'employmentassessment',
  employmentHistory: 'employmenthistory',
  employmentGap: 'employmentgap',
  nricDocumentComment: 'nricDocumentComment',
  educationBackgroundUploader: 'educationBackgroundUploader',
  employmentAssessmentQ1Uploader: 'employmentAssessmentQ1Uploader',
  employmentAssessmentQ2Uploader: 'employmentAssessmentQ2Uploader',
  employmentAssessmentQ3Uploader: 'employmentAssessmentQ3Uploader',
  employmentHistoryConsentUploader: 'employmentHistoryConsentUploader',
  employmentHistoryNonUploader: 'employmentHistoryNonUploader',
  employmentHistoryResignationUploader: 'employmentHistoryResignationUploader',
  previewDocument: 'previewDocument',
};

export const COMMENT_TYPE = {
  LEADER: 'leader',
  BDM: 'bdm',
  TD_OPS: 'tdOps',
};

export const REG_MAP = {
  NUMBER: /^\d+$/,
  ALPHA_NUMERIC: /^[a-zA-Z0-9]+$/,
  ALPHA_NUMERIC_SPACE: /^[A-Z0-9 ]+$/,
  ALPHA_NUMERIC_SPECIAL: /^[a-zA-Z0-9!@#%^&*()_+={[}\]|\\:;"' <,>.?/`~-]+$/,
  ALPHA_NUMERIC_SPECIAL_NO_CHINESE:
    /[^a-zA-Z0-9!@#%^&*()_+={[}\]|\\:;"' <,>.?/`~-\s]/g,
  PERSONALNAME: /^[a-zA-Z0-9!@#%^&*()_+={[}\]|\\:;"' <,>.?/`~-]+$/,
  ALPHA_SPECIAL: /^[a-zA-Z!@#%^&*()_+={[}\]|\\:;"' <,>.?/`~-]+$/,
  NUMERIC_SPECIAL: /^[0-9!@#%^&*()_+={[}\]|\\:;"' <,>.?/`~-]+$/,
  NUM_FORMAT: /^(\d+,?)+$/,
  NUM_FORMAT_O: /^(?:100|[1-9]\d|\d)$/,
  EMAIL: /^[A-Za-z\d]+([+-_.][A-Za-z\d]+)*@([A-Za-z\d]+[-.])+[A-Za-z]{2,5}$/,
};

export const HOME_SCREEN_APPLICATION_STATUS_MAP = {
  JOIN: '1', // 1010
  CONTINUE: '2', // 1020

  IN_PROGRESS: '3', // 2010
  FOLLOW_UP_LEADER: '5', // 2020
  LEADER_REJECT: '9', //2090

  // IN_PROGRESS: '3', // 3010
  RSVP: '4', // 3020
  IN_PROGRESS_INTERVIEW_ACCEPT: '20', // 3030
  IN_PROGRESS_INTERVIEW_DECLINE: '21', //3040
  FOLLOW_UP_BDM: '22', // 3050
  SIGN: '6', //3060
  BDM_REJECT: '11', // 3090

  // IN_PROGRESS: '3',  //4010
  FOLLOW_UP_DS: '30', //4020
  DS_REJECT: '31', //4090

  // IN_PROGRESS: '3', // 5010
  FOLLOW_UP_DS_MANAGEMENT: '32', // 5020
};

export const HOME_SCREEN_REEF_STATUS_MAP = {
  DS_REEF_REQUEST_UPDATE: 110, // DS request update
  REEF_SUBMITTED: 120, // submitted
  LEADER_REEF_REQUEST_UPDATE: 130, // Leader request update
};

export const RECRUITMENT_PROCESS_STATUS_MAP = {
  CREATED: '1',
  FSC_APPLICATION: '2',
  REVIEW_APPLICATION: '3',
  INTERVIEW_AND_SIGNING: '4',
  DUE_DILIGENCE_CHECK: '5',
  TD_OPS_MANAGEMENT_REVIEW: '6',
  RNF_LODGEMENT: '7',
  ISSUED_FSC_CODE: '8',
  COMPLETION_HIRING: '9',
};

export const IMAGE_FILE_TYPES = ['PNG', 'JPG'];

export const FILE_TYPES = [...IMAGE_FILE_TYPES, 'PDF'];

// don't delete or change this data
export const PAGE_NAME = {
  GENERAL_ASSESSMENT: {
    pageKey: 'generalAssessment',
  },
  JOB_SOURCE: {
    pageKey: 'jobSource',
    parentPage: 'GENERAL_ASSESSMENT',
  },
  BACKGROUND: {
    pageKey: 'background',
    parentPage: 'GENERAL_ASSESSMENT',
  },
  EDUCATION: {
    pageKey: 'education',
    sectionName: 'Education',
    parentPage: 'GENERAL_ASSESSMENT',
  },
  EDUCATION_REEF: {
    pageKey: 'educationReef',
    sectionName: 'Education Reef',
    parentPage: 'GENERAL_ASSESSMENT',
  },
  CMFAS_CERTIFICATION: {
    pageKey: 'cmfasCertification',
    parentPage: 'GENERAL_ASSESSMENT',
  },
  PERSONAL_INFORMATION: {
    pageKey: 'personalInformation',
  },
  FINANCIAL_INFORMATION: {
    pageKey: 'financialInformation',
  },
  FIT_AND_PROPER_CRITERIA: {
    pageKey: 'fitAndProperCriteria',
  },
  REVIEW: {
    pageKey: 'review',
  },
};

export const Application_Sign_Status = {
  notStarted: 'Not started',
  pending: 'Pending signature',
  completed: 'Completed',
};

export const SIGN_MODE = {
  ndi: 'NDI', // signing with singpass
  signature: 'ESIGN', // signing manual without singpass
};

export const BDM_FSC_STATUS = {
  PENDING_RSVP: 'RSVP',
  PENDING_INTERVIEW: 'Pending interview',
  WITHDRAW: 'Withdraw',
  DECLINE: 'Decline',
  FAILED_INTERVIEW: 'Failed interview',
  REQUEST_UPDATE: 'Request update',
  PENDING_2ND_INTERVIEW: 'Pending 2nd interview',
  PENDING_CANDIDATE_SIGNATURE: "Pending candidate's signature",
  PENDING_LEADER_SIGNATURE: "Pending Leader's signature",
  LEADER_APPEAL: 'Leader Appeal',
  SIGNATURE_FINISH: 'Signature Finish',
};

export const CASE_TYPE = {
  APPLICATION: 'Application',
  REEF: 'REEF',
};

// trigger function debounce default time 3s
export const DEBOUNCE_TIME = 3000;

// Student Conversion
export const STUDENT_CONVERSION_STATUS_MAP = {
  // epsScheme and review map
  FILLING: 'Filling',
  FILLED: 'Filled',
  REVIEWING: 'Reviewing',
  REVIEWED: 'Reviewed',
  // Main status map
  NOT_STARTED: 'Not Started',
  PENDING_CANDIDATE_SUBMISSION: 'Pending Candidate Submission',
  PENDING_CANDIDATE_FOLLOWUP: 'Pending Candidate Follow Up',
  PENDING_T2_LEADER: 'Pending T2 Leader Review',
  PENDING_T3_LEADER: 'Pending T3 Leader Review',
  T3_LEADER_REJECTED: 'T3 Leader Rejected',
  PENDING_TAM: 'Pending TAM Review',
  TAM_REJECTED: 'TAM Rejected',
  PENDING_SDOA: 'Pending SDOA Review',
  SDOA_REJECTED: 'SDOA Rejected',
  PENDING_HOR: 'Pending HOR Review',
  HOR_REJECTED: 'HOR Rejected',
  PENDING_TAM_REVIEW_SCHEDULE: 'Pending TAM Review EPS Schedule',
  TAM_REJECT_SCHEDULE: 'TAM Reject EPS Schedule',
  PENDING_EPS_SCHEDULE: 'Pending EPS Schedule',
  PENDING_CANDIDATE_SIGN: 'Pending Candidate Signature',
  PENDING_T2_LEADER_SIGN: 'Pending T2 Leader Signature',
  PENDING_T3_LEADER_SIGN: 'Pending T3 Leader Signature',
  PENDING_AIAREP_SIGN: 'Pending AIA REP Signature',
  COMPLETE_CONVERSION: 'Complete Conversion',
};

export const STUDENT_CONVERSION_SIGNING_STATUS = [
  STUDENT_CONVERSION_STATUS_MAP.PENDING_CANDIDATE_SIGN,
  STUDENT_CONVERSION_STATUS_MAP.PENDING_T2_LEADER_SIGN,
  STUDENT_CONVERSION_STATUS_MAP.PENDING_T3_LEADER_SIGN,
  STUDENT_CONVERSION_STATUS_MAP.PENDING_AIAREP_SIGN,
];

export const cbrValidityDays = process.env.REACT_APP_CBR_VALIDITY;
