export const SET_CERTIFICATION_DATA =
  'irecruit/assessment/SET_CERTIFICATION_DATA';

export const GET_CERTIFICATION_REQUEST =
  'irecruit/assessment/GET_CERTIFICATION_REQUEST';

export const SAVE_CERTIFICATION_REQUEST =
  'irecruit/assessment/SAVE_CERTIFICATION_REQUEST';

export const PROCEED_CERTIFICATION_SUCCESS =
  'irecruit/assessment/PROCEED_CERTIFICATION_SUCCESS';
export const PROCEED_CERTIFICATION_ERROR =
  'irecruit/assessment/PROCEED_CERTIFICATION_ERROR';
