export const SET_BASICINFO_DATA = 'irecruit/basicInfo/SET_BASICINFO_DATA';

export const GET_BASICINFO_REQUEST = 'irecruit/basicInfo/GET_BASICINFO_REQUEST';
export const SAVE_BASICINFO_REQUEST =
  'irecruit/basicInfo/SAVE_BASICINFO_REQUEST';
export const PROCEED_BASICINFO_SUCCESS =
  'irecruit/basicInfo/ADD_BASICINFO_SUCCESS';
export const PROCEED_BASICINFO_ERROR = 'irecruit/basicInfo/ADD_BASICINFO_ERROR';

export const DELETE_BASICINFO_REQUEST =
  'irecruit/basicInfo/DELETE_BASICINFO_REQUEST';

export const UPDATE_BASICINFO_REQUEST =
  'irecruit/basicInfo/UPDATE_BASICINFO_REQUEST';

export const SET_IS_INIT_COMPLETED = 'irecruit/basicInfo/SET_IS_INIT_COMPLETED';
export const SET_INITIAL_STATE = 'irecruit/basicInfo/SET_INITIAL_STATE';

export const SET_BLACK_STATUS = 'irecruit/basicInfo/SET_BLACK_STATUS';
export const SET_AGE_STATUS = 'irecruit/basicInfo/SET_AGE_STATUS';
export const GET_DATA_LIST_REQUEST = 'irecruit/basicInfo/GET_DATA_LIST_REQUEST';

//getMyInfoProfile
export const GET_MY_INFO_PROFILE_REQUEST =
  'irecruit/myinfo/GET_MY_INFO_PROFILE_REQUEST';

export const GET_MY_INFO_PROFILE_SUCCESS =
  'irecruit/myinfo/GET_MY_INFO_PROFILE_SUCCESS';

export const GET_MY_INFO_PROFILE_ERROR =
  'irecruit/myinfo/GET_MY_INFO_PROFILE_ERROR';

export const SET_MY_INFO_PROFILE_DATA =
  'irecruit/myinfo/SET_MY_INFO_PROFILE_DATA';
