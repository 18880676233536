import { combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form/lib/immutable';

import appReducer from './app/reducers';
import dashboardReducer from './dashboard/reducers';
import reefReducer from './reef/reducers';
import epsReducer from './eps/reducers';
import consentReducer from './consent/reducers';
import eventsReducer from './upcomingEvents/reducers';
import myInfoReducer from './myinfo/reducers';
import signUrlReducer from './signEps/reducers';
import eobCommonInfoReducer from './fscApplication/eobCommonInfo/reducers';
import jobSourceReducer from './fscApplication/assessment/jobsource/reducers';
import backgroundReducer from './fscApplication/assessment/background/reducers';
import educationReducer from './fscApplication/assessment/education/reducers';
import certificationReducer from './fscApplication/assessment/certification/reducers';
import personalCommonReducer from './fscApplication/personalInformation/common/reducers';
import basicInfoReducer from './fscApplication/personalInformation/basicInfo/reducers';
import familyInfoReducer from './fscApplication/personalInformation/familyInfo/reducers';
import emergencyContactReducer from './fscApplication/personalInformation/emergencyContact/reducers';
import educationBackgroundReducer from './fscApplication/personalInformation/educationBackground/reducers';
import employmentHistoryReducer from './fscApplication/personalInformation/workExperiEmploymentHistory/reducers';
import employmentAssessmentReducer from './fscApplication/personalInformation/workExperiEmploymentAssessment/reducers';
import reviewReducer from './fscApplication/review/reducers';
import financialInformationReducer from './fscApplication/FinancialInformation/reducers';
import criteriaReducer from './fscApplication/FitCriteria/reducers';
import rsvpReducer from './fscApplication/rsvp/reducers';
import fscSignDocReducer from './fscApplication/fscSignDoc/reducers';
import BusinessInterestDeclarationReducer from './fscApplication/BusinessInterestDeclaration/reducers';
import commonReducer from './fscApplication/common/reducers';
import studentConversionReducer from './studentConversion/reducers';

export default combineReducers({
  form: formReducer,
  app: appReducer,
  dashboard: dashboardReducer,
  reef: reefReducer,
  event: eventsReducer,
  eps: epsReducer,
  consent: consentReducer,
  myinfo: myInfoReducer,
  signUrl: signUrlReducer,
  eobCommonInfo: eobCommonInfoReducer,
  jobSource: jobSourceReducer,
  background: backgroundReducer,
  education: educationReducer,
  certification: certificationReducer,
  basicInfo: basicInfoReducer,
  personalCommon: personalCommonReducer,
  familyInfo: familyInfoReducer,
  emergencyContact: emergencyContactReducer,
  educationBackground: educationBackgroundReducer,
  employmentHistory: employmentHistoryReducer,
  employmentAssessment: employmentAssessmentReducer,
  review: reviewReducer,
  financialInfo: financialInformationReducer,
  criteria: criteriaReducer,
  rsvp: rsvpReducer,
  fscSignDoc: fscSignDocReducer,
  businessInterestDeclaration: BusinessInterestDeclarationReducer,
  common: commonReducer,
  studentConversion: studentConversionReducer,
});
