import { BOOLEAN_FLAG } from '@utils/constants';

export const PERSONALINFO_INPUT_PLACEHOLDER = {
  FULL_NAME: 'Full name as per NRIC',
  FORMER_NAME: 'Former name as per deed poll (if any)',
  AGE: 'Age',
  ID_NUMBER: 'ID number',
  PROVINCE_OF_BIRTH: 'State / province of birth',
  POSTAL_CODE: 'Postal code',
  POSTAL_CODE_OPTIONAL: 'Postal code (optional)',
  STREET_NAME: 'Street name',
  BLOCK_NUMBER: 'Block number',
  UNIT_NUMBER: 'Unit number (optional)',
  OVERSEA_UNIT_NUMBER: 'Unit number',
  FLOOR_NUMBER: 'Floor number (optional)',
  PROVINCE: 'State/ province',
  FAMILY_FULL_NAME: 'Family member’s full name as per NRIC',
  PREFERRED_NAME: 'Preferred name (if any)',
  PASSPORT_NUMBER: 'NRIC/FIN/Passport number',
  MOBILE_NUMBER: 'Mobile number',
  EMAIL_ADDRESS: 'Email address',
  ADDRESS_OPTIONAL: 'Address (optional)',
  CERTIFICATE_NAME: 'Certificate name/ name of course',
  CINSTITUTE_NAME: 'Name of institute (as per certification)',
  COMPANY_NAME: 'Company name',
  COMPANY_WEBSITE: 'Company website (optional)',
  OFFICER_NAME: 'HR/ officer name (optional)',
  COMPANY_EMAIL: 'HR email/ company email',
  INSURANCE_COMPANY: 'General insurance company name',
};

export const PERSONALINFO_SELECT_PLACEHOLDER = {
  GENDER: 'Select gender',
  RACE: 'Select race',
  MARITAL_STATUS: 'Select marital status',
  CITIZENSHIP: 'Select citizenship field',
  ID_TYPE: 'Select ID type',
  COUNTRY_OF_ISSUE: 'Country of issue (ID type)',
  COUNTRY_OF_BIRTH: 'Select country of birth',
  CURRENT_RESIDING_COUNTRY: 'Select current residing country',
  CURRENT_RESIDING_PROVINCE: 'State/ province of current residing country',
  COUNTRY: 'Select a country',
  RELATIONSHIP: 'Select relationship',
  RELATIONTOCANDIDATE: 'Select relationship to the candidate',
  NATIONALITY: 'Select nationality',
  CONTACT_NUMBER: 'Contact number',
  COMPANY_CONTACT_NUMBER: 'Company contact number',
  EDUCATION: 'Select education',
  COUNTRY_OF_INSTITUTION: 'Select country that you studied in',
  DEGREE: 'Degree',
  FULL_TIME: 'Full-time',
  MALAYSIA: 'Malaysia',
  MONTHY_INCOME: 'Current gross monthly income (SGD)',
  JOB_TITLE: 'Job title',
  EMPLOYEE_TYPE: 'Select employment type',
  EMPLOYEE_STATUS: 'Select employment status',
};

export const PERSONALINFO_DATE_PLACEHOLDER = {
  DATE_OF_BIRTH: 'Date of birth (e.g 22 JAN 2022)',
  DATE_OF_ISSUE: 'Date of issue (e.g 22 JAN 2022)',
  DATE_OF_EXPIRY: 'Date of expiry (e.g 22 JAN 2022)',
  FORMATT_DATE: 'dd M yyyy',
  EDUCATION_START_DATE: 'Education start date (e.g 22 JAN 2022)',
  EDUCATION_END_DATE: 'Education end date (e.g 22 JAN 2022)',
  START_DATE: 'Start date  (e.g JAN 2022)',
  END_DATE: 'End date  (e.g JAN 2022)',
  PASS_EXPIRY_DATE: 'Work pass/short-term visit pass expiry date',
  PASS_EXPIRY_DATE_OPTIONAL:
    'Work pass/short-term visit pass expiry date (optional)',
};

export const PERSONALINFO_BASCI_DATA = {
  MODAL_TITLE: 'Update Myinfo records',
  MODAL_DESC: 'Are you sure you want to edit the Myinfo records? ',
  HEADER_TITLE: 'FSC Application',
  CONTENT_TITLE: 'Personal information',
  CONTENT_DESC: 'Tell us about yourself.',
  FASTERFORM_TITLE: 'Faster form filling with Singpass',
  FASTERFORM_SUB_TITLE:
    'Make your application more convenient and auto-fill this form with Singpass.',
  DEED_POLL: 'What is deed poll?',
  DEED_POLL_TOOLTIP:
    "A Deed Poll in Singapore is a legal document that officially changes a person's name on their identify documents.",
  UPLOAD_IMAGE_TOOLTIP:
    'Please upload your passport photo in a ratio of 1:1 or 500 X 500 pixels. Upload in JPG, or PNG format (max 5MB) for successful submission.',
  EMPLOYMENT_HISTORY_TOOLTIP:
    'It includes banks, trust companies, insurance companies, brokerage firms, and investment dealers. Example, DBS bank, Citibank, UOB, OCBC, Great Eastern, Aviva, AIA, etc',
  PASSPORT_COPY: 'NRIC / FIN / Passport copy',
  EDUCATION_CERTIFICATE: 'Education certificate',
  PERSONAL_INFO_LABEL: 'Basic information',
  NATIONALITY_LABEL: 'Nationality',
  CONTACT_LABEL: 'Contact',
  C_RESIDENTAIL_ADDRESS_LABEL: 'Current Singapore residential address',
  O_RESIDENTAIL_ADDRESS_LABEL: 'Overseas residential address (Foreigners only)',
  FAMILY_INFO_LABEL: 'Family information',
  ADD_DOCUMENTS_LABEL: 'Add document(s)',
  ADD_MEMBER_LABEL: 'Add more family member',
  ADD_EDUCATION_LABEL: 'Add more education',
  ADD_WORK_EXPERIENCE_LABEL: 'Add more work experience',
};

export const PERSONALINFO_LABEL = {
  HIGHEST_QUALIFICATION: 'What is your highest qualification?',
  ORIGINAL_CERTIFICATE: 'Is original certificate in English language?',
  ACADEMIC_CERTIFICATE: 'Academic certificate',
  ACADEMIC_TRANSCRIPT: 'Academic transcript results ',
  DURATION_OF_STUDY: 'Duration of study proof from institution',
  DURATION_OF_STUDY_DESC: '(for diploma holder only)',
  COURSE_INSTITUTION: 'Course write-up from institution',
  COURSE_INSTITUTION_DESC: '(for diploma holder only)',
  EDUCATION_BACKGROUND_PROGRESS_MESSAGE:
    'Recruitment Education Evaluation Form (REEF) assessment in progress',
  EDUCATION_BACKGROUND_VARIFY_MESSAGE:
    'Recruitment Education Evaluation Form (REEF) assessment verified',
  BASICINFO_SINGPASS_MESSAGE: 'Details retrieved from MyInfo with Singpass',
};

export const photoArgs = {
  id: 'photo_img',
  alt: 'Photo',
  imgClass: 'd-block w-100',
  aspectRatio: '1 / 1',
  objectFit: 'cover',
  type: 'rounded',
};

export const uploaderPhotoArgs = {
  id: 'demo-upload-photo',
};

export const uploaderArgs = {
  id: 'demo-upload',
  isPreviewFile: true,
  completeIcon: {
    fill: '#3DA758',
    group: 'systemControl',
    name: 'success_solid',
  },

  completeText: 'Upload Complete',
  deleteIcon: { group: 'function', name: 'delete' },
  incompleteText: 'Upload Incomplete',
  takePhotoIcon: {
    fill: '#5B636B',
    group: 'systemControl',
    name: 'circle_plus_solid',
  },
  takePhotoText: 'Add document(s)',
  undoIcon: { fill: 'none', group: 'nutrition', name: 'refresh' },
  errorText: 'Upload Error',
  uploadFor: 'document',
  uploadingText: 'Uploading...',
  type: 'multiple',
};

export const familyInfoMandatoryFields = {
  fullName: '',
  selectRelationship: '',
  nricNumber: '',
  birthday: '',
  selectGender: '',
  selectNationality: '',
  contactNumber: '',
};

export const educationBackgroundMandatoryFields = {
  selectEducation: '',
  selectCountry: '',
  certificateName: '',
  instituteName: '',
  educationStartDate: '',
  educationEndDate: '',
  fileList: [],
};

export const emergencyContactMandtoryFields = {
  fullName: '',
  selectRelationship: '',
  address: '',
  postalCode: '',
  areaCode: '',
  phoneNumber: '',
  contactNumber: '',
};

//isFinancialInstitution=== 'N'
//selectEmploymentStatus === ''||employeeStatusOptions[2] ||e
export const employmentHistoryMandatoryFields = {
  jobTitle: '',
  companyName: '',
  monthyIncome: '',
  selectEmploymentType: '',
  selectEmploymentStatus: '',
  startDate: '',
  endDate: '',
  leaveReason: '',
};

//isFinancialInstitution=== 'N'
// mployeeStatusOptions[3]
export const employmentHistoryMandatoryFieldsBySelectStatus_4 = {
  jobTitle: '',
  companyName: '',
  monthyIncome: '',
  selectEmploymentType: '',
  selectEmploymentStatus: '',
  startDate: '',
  endDate: '',
  lastDate: '',
  leaveReason: '',
  resignationFileList: [],
};

//selectEmploymentStatus = employeeStatusOptions[0] || employeeStatusOptions[1]
export const employmentHistoryMandatoryFieldsBySelectEmploymentStatus = {
  jobTitle: '',
  companyName: '',
  monthyIncome: '',
  selectEmploymentType: '',
  selectEmploymentStatus: '',
  startDate: '',
  nonFaEmploymentFileList: [],
  nonFaFormFileList: [],
};

export const employmentHistoryMandatoryFieldsByisFinancialInstitution = {
  jobTitle: '',
  companyName: '',
  monthyIncome: '',
  selectEmploymentType: '',
  selectEmploymentStatus: '',
  startDate: '',
  endDate: '',
  leaveReason: '',
  companyEmail: '',
  companyContactNumber: '',
};

export const employmentHistoryMandatoryFieldsBySelectEmploymentStatusAndisFinancialInstitution =
  {
    jobTitle: '',
    companyName: '',
    monthyIncome: '',
    selectEmploymentType: '',
    selectEmploymentStatus: '',
    startDate: '',
    companyEmail: '',
    companyContactNumber: '',
  };

export const employmentHistoryInitData = {
  isFinancialInstitution: BOOLEAN_FLAG.N,
  saveCount: 0,
  fileRenameIndex: 1,
  jobTitle: '',
  companyName: '',
  companyWebsite: '',
  monthyIncomeType: '',
  monthyIncome: '',
  selectEmploymentType: '',
  selectEmploymentStatus: '',
  startDate: '',
  endDate: '',
  lastDate: '',
  leaveReason: '',
  officerContactName: '',
  companyEmail: '',
  companyAreaCode: '',
  companyPhoneNumber: '',
  companyContactNumber: '',
  nonFaEmploymentFileList: [],
  nonFaFormFileList: [],
  resignationFileList: [],
};

export const PERSONAL_INFO_DOCUMENTS = {
  BASIC_INFO_SECTION: {
    PHOTO: 'photo',
    NATIONALITY: 'nationality',
    MY_INFO: 'myInfo',
  },
  WORK_EXPERIENCE_SECTION: {
    ASSESSMENT_1: 'employmentAssessment_1',
    ASSESSMENT_2: 'employmentAssessment_2',
    ASSESSMENT_3: 'employmentAssessment_3',
  },
};

export const DIALOG_LABEL = {
  TITLE: 'Unable to proceed',
  SUBTITLE:
    'Please ensure that your ID number / name are entered correctly, or populated by Singpass correctly. Your application is unable to proceed for this ID number / name. Please contact your Talent Acquisition Manager (TAM) to check with TD Operations.',
  BTN_LABEL: 'Okay',
  COMMENTS_TITLE:
    'Please ensure that your ID number / name are entered correctly, or populated by Singpass correctly. Your application is unable to proceed for this ID number / name. Please contact your Talent Acquisition Manager (TAM) to check with TD Operations.',
  AGE_INVALID: 'The minimum age for FSC/FC applications is 21 years old.',
};
