import React from 'react';
import { useNavigate, Route } from 'react-router-dom';
import { ApmRoutes } from '@elastic/apm-rum-react';
import { Security, LoginCallback } from '@okta/okta-react';
import loadable from '@loadable/component';
import { OktaAuth } from '@okta/okta-auth-js';
import { oktaAuthConfig } from '@utils/config';

const IndexPage = loadable(() => import('@pages/index'));
const LoginPage = loadable(() => import('@components/LoginPage'));
const LogoutPage = loadable(() => import('@components/LogoutPage'));
const PrivateRoute = loadable(() => import('@components/PrivateRoute'));
const Dashboard = loadable(() => import('@components/Dashboard'));
const EpsPage = loadable(() => import('@components/Eps'));
const ReefPage = loadable(() => import('@components/Reef'));
const UpcomingEvents = loadable(() => import('@components/UpcomingEvents'));
const Consent = loadable(() => import('@components/Consent'));
const MyInfo = loadable(() => import('@components/MyInfo'));
const ApplicationInfo = loadable(() => import('@components/ApplicationInfo'));
const SignEps = loadable(() => import('@components/SignEps'));
const NotFoundPage = loadable(() => import('@pages/404'));
const SignComplete = loadable(() => import('@components/SignComplete'));
const FscRSVPWithdrawSuccess = loadable(() =>
  import('@pages/fscApplication/RSVP/WithdrawnSuccess')
);
const StudentConversionWelcome = loadable(() =>
  import('@pages/studentConversion/Welcome')
);
const EpsScheme = loadable(() => import('@pages/studentConversion/EpsScheme'));
const StudentConversionReview = loadable(() =>
  import('@pages/studentConversion/Review')
);
const StudentConversionOverview = loadable(() =>
  import('@pages/studentConversion/Overview')
);
const PreviewDocuments = loadable(() =>
  import('@pages/studentConversion/PreviewDocuments')
);
const Attachments = loadable(() =>
  import('@pages/studentConversion/PreviewDocuments/Attachments')
);
const StudentConversionSubmitSuccess = loadable(() =>
  import('@pages/studentConversion/SubmittedSuccess')
);

const StudentConversionRemoteSign = loadable(() =>
  import('@pages/studentConversion/SigningInfo')
);

const StudentConversionSignEps = loadable(() =>
  import('@pages/studentConversion/SignEps')
);

import {
  FscWelcome,
  FscJobSource,
  FscBackground,
  FscEducation,
  FscCertification,
  FscPersonalInformation,
  FscFinancialInformation,
  FscCriteria,
  FscReview,
  FscSubmitSuccess,
  FscSupportDocuments,
  FscPreviewDocument,
  FscOverview,
  FscRSVP,
  FscSignPage,
  FscSignReviewDocument,
  FscSignSubmitSuccess,
  FscBusinessInterestDeclaration,
} from '@pages/fscApplication/pageWrappers';

const oktaAuth = new OktaAuth({
  ...oktaAuthConfig,
  tokenManager: { storageKey: 'okta-candidate-token-storage' },
});

const Router = () => {
  const navigate = useNavigate();
  const restoreOriginalUri = () => {
    navigate('/app/login');
  };
  return (
    <Security oktaAuth={oktaAuth} restoreOriginalUri={restoreOriginalUri}>
      <ApmRoutes>
        <Route exact path='/' default element={<IndexPage />} />
        <Route exact path='/app/login' element={<LoginPage />} />
        <Route
          exact
          path='/app/login/callback'
          element={
            <LoginCallback
              errorComponent={() => {
                localStorage.clear();
                window.location = '/';
                return null;
              }}
            />
          }
        />
        <Route path='/app/logout' element={<LogoutPage />} />

        {/* Protected Routes */}
        <Route element={<PrivateRoute />}>
          <Route path='/app/welcome' element={<FscWelcome />} />
          <Route path='/app/job-source' element={<FscJobSource />} />
          <Route path='/app/background' element={<FscBackground />} />
          <Route path='/app/education' element={<FscEducation />} />
          <Route path='/app/certification' element={<FscCertification />} />
          <Route
            path='/app/personal-information'
            element={<FscPersonalInformation />}
          />
          <Route
            path='/app/financial-information'
            element={<FscFinancialInformation />}
          />
          <Route path='/app/fit-proper-criteria' element={<FscCriteria />} />
          <Route path='/app/review' element={<FscReview />} />
          <Route path='/app/submitted' element={<FscSubmitSuccess />} />
          <Route
            path='/app/supporting-documents'
            element={<FscSupportDocuments />}
          />
          <Route
            path='/app/preview-document'
            element={<FscPreviewDocument />}
          />
          <Route path='/app/overview' element={<FscOverview />} />
          <Route path='/app/rsvp' element={<FscRSVP />} />
          <Route
            path='/app/rsvp-withdraw-success'
            element={<FscRSVPWithdrawSuccess />}
          />
          <Route path='/app/sign-page' element={<FscSignPage />} />
          <Route
            path='/app/sign-review-document'
            element={<FscSignReviewDocument />}
          />
          <Route
            path='/app/sign-submit-success'
            element={<FscSignSubmitSuccess />}
          />
          <Route
            path='/app/business-interest-declaration'
            element={<FscBusinessInterestDeclaration />}
          />

          <Route element={<Dashboard />} path='/app/dashboard' />
          <Route element={<EpsPage />} path='/app/eps' />
          <Route element={<ReefPage />} path='/app/reef' />
          <Route element={<UpcomingEvents />} path='/app/upcoming-events' />
          <Route element={<MyInfo />} path='/app/my-info' />
          <Route
            element={<ApplicationInfo />}
            path='/app/remote-signing/application-info'
          />
          <Route element={<SignEps />} path='/app/remote-signing/sign-eps' />
          <Route element={<SignComplete />} path='/app/sign-complete' />

          {/* Student Conversion Routes */}
          <Route path='app/studentConversion'>
            <Route element={<StudentConversionWelcome />} path='welcome' />
            <Route element={<EpsScheme />} path='eps-scheme' />
            <Route element={<PreviewDocuments />} path='preview-documents' />
            <Route element={<Attachments />} path='supporting-documents' />
            <Route element={<StudentConversionReview />} path='review' />
            <Route
              element={<StudentConversionSubmitSuccess />}
              path='student-conversion-submitted'
            />
            <Route element={<StudentConversionOverview />} path='overview' />
            <Route
              element={<StudentConversionRemoteSign />}
              path='remote-signing/application-info'
            />
            <Route
              element={<StudentConversionSignEps />}
              path='remote-signing/sign-eps'
            />
          </Route>
        </Route>
        <Route element={<Consent />} path='/app/consent' />
        <Route
          element={<FscRSVPWithdrawSuccess />}
          path='/app/rsvp-withdraw-success'
        />
        <Route element={<NotFoundPage />} path='*' />
      </ApmRoutes>
    </Security>
  );
};

export default Router;
