import {
  SET_INITIAL_STATE,
  SET_IS_INIT_COMPLETED,
  GET_PERSONAL_FILE_REQUEST,
  UPLOAD_BASIC_FILE_REQUEST,
  DELETE_BASIC_FILE_REQUEST,
  UPLOAD_WORK_ASSESSMENT_FILE_REQUEST,
  DELETE_WORK_ASSESSMENT_FILE_REQUEST,
  DELETE_WORK_ASSESSMENT_FILE_BY_QUESTION_REQUEST,
  SET_BASIC_FILE_DATA,
  SET_WORK_FILE_DATA,
  SET_ICON_FILE_DATA,
  GET_PERSONAL_COMMENT_REQUEST,
  SET_PERSONAL_COMMENT_DATA,
  PROCEED_PERSONAL_SUCCESS,
  PROCEED_PERSONAL_ERROR,
} from './actionTypes';

// set/roll back initial data
export const setInitialState = (data) => ({
  type: SET_INITIAL_STATE,
  data,
});
// get data completed
export const setIsInitCompleted = (data) => ({
  type: SET_IS_INIT_COMPLETED,
  data,
});

// file request
export const getPersonalFileRequest = (data) => ({
  type: GET_PERSONAL_FILE_REQUEST,
  data,
});
export const uploadBasicFileRequest = (data, additionalData, callback) => ({
  type: UPLOAD_BASIC_FILE_REQUEST,
  data,
  additionalData,
  callback,
});
export const deleteBasicFileRequest = (data, additionalData, callback) => ({
  type: DELETE_BASIC_FILE_REQUEST,
  data,
  additionalData,
  callback,
});
export const uploadWorkAssessmentFileRequest = (
  data,
  additionalData,
  callback
) => ({
  type: UPLOAD_WORK_ASSESSMENT_FILE_REQUEST,
  data,
  additionalData,
  callback,
});
export const deleteWorkAssessmentFileRequest = (
  data,
  additionalData,
  callback
) => ({
  type: DELETE_WORK_ASSESSMENT_FILE_REQUEST,
  data,
  additionalData,
  callback,
});
export const deleteWorkAssessmentFileByQuestionRequest = (data) => ({
  type: DELETE_WORK_ASSESSMENT_FILE_BY_QUESTION_REQUEST,
  data,
});
// set file data
export const setIconFileData = (data) => ({
  type: SET_ICON_FILE_DATA,
  data,
});
export const setBasicFileData = (data) => ({
  type: SET_BASIC_FILE_DATA,
  data,
});
export const setWorkFileData = (data) => ({
  type: SET_WORK_FILE_DATA,
  data,
});
// comment request
export const getPersonalCommentRequest = (data) => ({
  type: GET_PERSONAL_COMMENT_REQUEST,
  data,
});
// set comment data
export const setPersonalCommentData = (data) => ({
  type: SET_PERSONAL_COMMENT_DATA,
  data,
});

//success & error
export const proceedPersonalSuccess = (message) => ({
  type: PROCEED_PERSONAL_SUCCESS,
  message,
});
export const proceedPersonalError = (message) => ({
  type: PROCEED_PERSONAL_ERROR,
  message,
});
