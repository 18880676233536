import { fromJS } from 'immutable';
import {
  GET_EPS_DATA_REQUEST,
  GET_EPS_DATA_SUCCESS,
  GET_EPS_DATA_ERROR,
  SEND_EPS_DATA_REQUEST,
  SEND_EPS_DATA_SUCCESS,
  SEND_EPS_DATA_ERROR,
  UPLOAD_EPS_DOCUMENT_REQUEST,
  UPLOAD_EPS_DOCUMENT_ERROR,
  SEND_EPS_FOLLOWUP_DOCUMENTS_REQUEST,
  SEND_EPS_FOLLOWUP_DOCUMENTS_REQUEST_SUCCESS,
  SEND_EPS_FOLLOWUP_DOCUMENTS_REQUEST_ERROR,
  SEND_INTERVIEW_DECISION,
  SEND_INTERVIEW_DECISION_SUCCESS,
  SEND_INTERVIEW_DECISION_ERROR,
  GET_CALLIDUS_INFO_REQUEST,
  GET_CALLIDUS_INFO_SUCCESS,
  GET_CALLIDUS_INFO_ERROR,
} from './actionTypes';

const initialState = fromJS({
  loading: false,
  error: false,
  success: false,
  message: null,
  edu: [],
  countries: [],
  occupations: [],
  allowances: [],
  epsForm: {},
  rsvpSubmitting: false,
  nationality: [],
  race: [],
  callidusInfo: {},
});

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_EPS_DATA_REQUEST:
    case SEND_EPS_DATA_REQUEST:
    case UPLOAD_EPS_DOCUMENT_REQUEST:
    case SEND_EPS_FOLLOWUP_DOCUMENTS_REQUEST:
    case GET_CALLIDUS_INFO_REQUEST:
      return state
        .set('loading', true)
        .set('success', false)
        .set('error', false)
        .set('message', null);
    case GET_CALLIDUS_INFO_SUCCESS:
      return state
        .set('loading', false)
        .set('success', true)
        .set('callidusInfo', fromJS(action.data));
    case GET_EPS_DATA_SUCCESS: {
      let newState = state
        .set('loading', false)
        .set('success', true)
        .set('edu', fromJS(action.edu))
        .set('countries', fromJS(action.countries))
        .set('occupations', fromJS(action.occupations))
        .set('allowances', fromJS(action.allowances))
        .set('race', fromJS(action.race))
        .set('nationality', fromJS(action.nationality));
      if (action.eps) {
        newState = newState.set('epsForm', fromJS(action.eps));
      }
      return newState;
    }
    case SEND_EPS_DATA_SUCCESS:
    case SEND_EPS_FOLLOWUP_DOCUMENTS_REQUEST_SUCCESS: {
      let newState = state
        .set('loading', false)
        .set('success', true)
        .set('message', action.message);
      if (action.eps) {
        newState = newState.set('epsForm', fromJS(action.eps));
      }
      return newState;
    }
    case GET_EPS_DATA_ERROR:
    case SEND_EPS_DATA_ERROR:
    case UPLOAD_EPS_DOCUMENT_ERROR:
    case SEND_EPS_FOLLOWUP_DOCUMENTS_REQUEST_ERROR:
    case GET_CALLIDUS_INFO_ERROR:
      return state
        .set('loading', false)
        .set('error', true)
        .set('message', action.error);
    case SEND_INTERVIEW_DECISION:
      return state
        .set('loading', true)
        .set('success', false)
        .set('error', false)
        .set('message', null)
        .set('rsvpSubmitting', true);
    case SEND_INTERVIEW_DECISION_SUCCESS:
      return state
        .set('loading', false)
        .set('success', true)
        .set('error', false)
        .set('message', action.message)
        .set('rsvpSubmitting', false);
    case SEND_INTERVIEW_DECISION_ERROR:
      return state
        .set('loading', false)
        .set('error', true)
        .set('message', action.error)
        .set('rsvpSubmitting', false);
    default:
      return state;
  }
}
