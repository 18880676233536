export const GET_STUDENT_CONVERSION_STATUS_REQUEST =
  'irecruit/studentConversion/GET_STUDENT_CONVERSION_STATUS_REQUEST';
export const GET_STUDENT_CONVERSION_STATUS_ERROR =
  'irecruit/studentConversion/GET_STUDENT_CONVERSION_STATUS_ERROR';
export const GET_STUDENT_CONVERSION_STATUS_SUCCESS =
  'irecruit/studentConversion/GET_STUDENT_CONVERSION_STATUS_SUCCESS';
export const SAVE_DRAFT_STUDENT_CONVERSION_REQUEST =
  'irecruit/studentConversion/SAVE_DRAFT_STUDENT_CONVERSION_REQUEST';
export const SAVE_DRAFT_STUDENT_CONVERSION_ERROR =
  'irecruit/studentConversion/SAVE_DRAFT_STUDENT_CONVERSION_ERROR';
export const SAVE_DRAFT_STUDENT_CONVERSION_SUCCESS =
  'irecruit/studentConversion/SAVE_DRAFT_STUDENT_CONVERSION_SUCCESS';
export const UPLOAD_FILE_STUDENT_CONVERSION_REQUEST =
  'irecruit/studentConversion/UPLOAD_FILE_STUDENT_CONVERSION_REQUEST';
export const UPLOAD_FILE_STUDENT_CONVERSION_SUCCESS =
  'irecruit/studentConversion/UPLOAD_FILE_STUDENT_CONVERSION_SUCCESS';
export const UPLOAD_FILE_STUDENT_CONVERSION_ERROR =
  'irecruit/studentConversion/UPLOAD_FILE_STUDENT_CONVERSION_ERROR';
export const DELETE_FILE_STUDENT_CONVERSION_REQUEST =
  'irecruit/studentConversion/DELETE_FILE_STUDENT_CONVERSION_REQUEST';
export const DELETE_FILE_STUDENT_CONVERSION_SUCCESS =
  'irecruit/studentConversion/DELETE_FILE_STUDENT_CONVERSION_SUCCESS';
export const DELETE_FILE_STUDENT_CONVERSION_ERROR =
  'irecruit/studentConversion/DELETE_FILE_STUDENT_CONVERSION_ERROR';
export const GET_STUDENT_SIGNING_URL_REQUEST =
  'irecruit/signEps/GET_STUDENT_SIGNING_URL_REQUEST';
export const GET_STUDENT_SIGNING_URL_SUCCESS =
  'irecruit/signEps/GET_STUDENT_SIGNING_URL_SUCCESS';
export const GET_STUDENT_SIGNING_URL_ERROR =
  'irecruit/signEps/GET_STUDENT_SIGNING_URL_ERROR';
