export const GET_MYINFO_INITIAL_DATA_REQUEST =
  'irecruit/myinfo/GET_MYINFO_INITIAL_DATA_REQUEST';
export const GET_MYINFO_INITIAL_DATA_SUCCESS =
  'irecruit/myinfo/GET_MYINFO_INITIAL_DATA_SUCCESS';
export const GET_MYINFO_INITIAL_DATA_ERROR =
  'irecruit/myinfo/GET_MYINFO_INITIAL_DATA_ERROR';

export const PROCEED_MYINFO_REQUEST = 'irecruit/myinfo/PROCEED_MYINFO_REQUEST';
export const PROCEED_MYINFO_SUCCESS = 'irecruit/myinfo/PROCEED_MYINFO_SUCCESS';
export const PROCEED_MYINFO_ERROR = 'irecruit/myinfo/PROCEED_MYINFO_ERROR';
