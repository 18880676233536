import { fromJS } from 'immutable';
import {
  GET_BASICINFO_REQUEST,
  SET_BASICINFO_DATA,
  SAVE_BASICINFO_REQUEST,
  PROCEED_BASICINFO_SUCCESS,
  PROCEED_BASICINFO_ERROR,
  DELETE_BASICINFO_REQUEST,
  UPDATE_BASICINFO_REQUEST,
  GET_MY_INFO_PROFILE_REQUEST,
  GET_MY_INFO_PROFILE_SUCCESS,
  GET_MY_INFO_PROFILE_ERROR,
  SET_MY_INFO_PROFILE_DATA,
  SET_IS_INIT_COMPLETED,
  SET_INITIAL_STATE,
  GET_DATA_LIST_REQUEST,
  SET_BLACK_STATUS,
  SET_AGE_STATUS,
} from './actionTypes';

const initialState = fromJS({
  basicInfoData: {},
  isInitCompleted: false,
  success: false,
  loading: false,
  error: false,
  isSubmitting: false,
  isSubmitted: false,
  message: null,
  myInfoProfileData: {},
  isBlack: false,
  isAgeInvalid: false,
});

export default function (state = initialState, action) {
  switch (action.type) {
    case SET_INITIAL_STATE:
      return initialState;
    case SET_IS_INIT_COMPLETED:
      return state.set('isInitCompleted', action.data);
    case SET_BASICINFO_DATA:
      return state
        .set('loading', false)
        .set('success', true)
        .set('basicInfoData', fromJS(action.data));
    case GET_BASICINFO_REQUEST:
      return state
        .set('loading', true)
        .set('success', false)
        .set('error', false)
        .set('message', null);
    case SAVE_BASICINFO_REQUEST:
      return state
        .set('loading', false)
        .set('success', false)
        .set('error', false)
        .set('isSubmitting', true)
        .set('message', null);
    case PROCEED_BASICINFO_SUCCESS:
      return state
        .set('loading', false)
        .set('success', true)
        .set('isSubmitting', false)
        .set('isSubmitted', true)
        .set('message', action.message);
    case PROCEED_BASICINFO_ERROR:
      return state
        .set('loading', false)
        .set('error', true)
        .set('isSubmitting', false)
        .set('message', action.message);
    case DELETE_BASICINFO_REQUEST:
      return state
        .set('loading', true)
        .set('success', false)
        .set('error', false)
        .set('message', null);

    case UPDATE_BASICINFO_REQUEST:
      return state
        .set('loading', true)
        .set('success', false)
        .set('error', false)
        .set('message', null);

    case GET_MY_INFO_PROFILE_REQUEST:
      return state
        .set('loading', true)
        .set('success', false)
        .set('error', false)
        .set('message', null);
    case GET_MY_INFO_PROFILE_SUCCESS:
      return state
        .set('loading', true)
        .set('success', false)
        .set('error', false)
        .set('message', null);
    case SET_MY_INFO_PROFILE_DATA:
      return state
        .set('loading', false)
        .set('success', true)
        .set('myInfoProfileData', fromJS(action.data));
    case GET_MY_INFO_PROFILE_ERROR:
      return state
        .set('loading', false)
        .set('error', true)
        .set('myInfoProfileData', fromJS({}))
        .set('message', action.message);
    case GET_DATA_LIST_REQUEST:
      return state
        .set('loading', true)
        .set('success', false)
        .set('error', false)
        .set('message', null);
    case SET_BLACK_STATUS:
      return state.set('isBlack', fromJS(action.data));
    case SET_AGE_STATUS:
      return state.set('isAgeInvalid', fromJS(action.data));
    default:
      return state;
  }
}
