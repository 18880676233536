import { fromJS } from 'immutable';
import {
  GET_UPCOMING_DATA_REQUEST,
  GET_UPCOMING_DATA_SUCCESS,
  GET_UPCOMING_DATA_ERROR,
  GET_HAPPENING_DATA_REQUEST,
  GET_HAPPENING_DATA_SUCCESS,
  GET_HAPPENING_DATA_ERROR,
  ACCEPT_EVENT_REQUEST,
  ACCEPT_EVENT_ERROR,
  ACCEPT_EVENT_SUCCESS,
  DECLINE_EVENT_REQUEST,
  DECLINE_EVENT_SUCCESS,
  DECLINE_EVENT_ERROR,
} from './actionTypes';

const initState = fromJS({
  loading: false,
  error: false,
  success: false,
  message: null,
  upcoming: [],
  happening: [],
});

export default function (state = initState, action) {
  const { type } = action;

  switch (type) {
    case GET_UPCOMING_DATA_REQUEST:
    case GET_HAPPENING_DATA_REQUEST:
    case ACCEPT_EVENT_REQUEST:
    case DECLINE_EVENT_REQUEST:
      return state
        .set('loading', true)
        .set('error', false)
        .set('success', false)
        .set('message', null);

    case GET_UPCOMING_DATA_SUCCESS: {
      return state
        .set('loading', false)
        .set('success', true)
        .set('upcoming', fromJS(action.upcoming));
    }

    case GET_HAPPENING_DATA_SUCCESS: {
      return state
        .set('loading', false)
        .set('success', true)
        .set('happening', fromJS(action.happening));
    }

    case ACCEPT_EVENT_SUCCESS:
    case DECLINE_EVENT_SUCCESS:
      return state
        .set('loading', false)
        .set('success', true)
        .set('message', action.message);

    case GET_UPCOMING_DATA_ERROR:
    case GET_HAPPENING_DATA_ERROR:
    case ACCEPT_EVENT_ERROR:
    case DECLINE_EVENT_ERROR:
      return state
        .set('loading', false)
        .set('error', true)
        .set('message', action.message);

    default:
      return state;
  }
}
