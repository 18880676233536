import { call, put, takeLatest, all, select } from 'redux-saga/effects';
import request, { unauthorizedHandler } from '@utils/request';
import { BASE_API, PROFILE_TYPE } from '@utils/constants';
import { makeSelectToken } from '@redux/app/selectors';

import { getResponseMessage } from '@utils/helper';
import {
  getUserInfoSuccess,
  getUserInfoError,
  getProfileSuccess,
  getProfileError,
  getProfileThumbnailsSuccess,
  getProfileThumbnailsError,
} from './actions';
import {
  GET_USER_INFO_REQUEST,
  GET_PROFILE_REQUEST,
  GET_PROFILE_THUMBNAILS_REQUEST,
} from './actionTypes';
import { setUserData } from '../app/actions';

export function* getUserInfo() {
  try {
    const useMock = process.env.REACT_APP_ENVIRONMENT === 'local' || false;

    if (useMock) {
      const candidateMock = require('../../server/mock/candidate.json');
      yield put(setUserData(candidateMock.data.data));
    } else {
      let token = yield select(makeSelectToken());

      if (token) {
        const user = yield call(request, '/candidate', 'get', null, token);
        if (user.status === 200 && user.data.data) {
          yield put(setUserData(user.data.data));
          yield put(getUserInfoSuccess());
        } else {
          yield put(getUserInfoError('Failed to get user info'));
        }
      } else {
        yield put(getUserInfoError('Token not provided'));
      }
    }
  } catch (err) {
    const { response } = err;
    yield call(unauthorizedHandler, response);
    yield put(getUserInfoError('Failed get user info'));
  }
}

export function* getProfile() {
  try {
    const useMock = process.env.GATSBY_USE_MOCK_DATA === 'true' || false;

    if (useMock) {
      const agencyMock = require('../../server/mock/agency.json');
      const leaderMock = require('../../server/mock/leader.json');
      yield put(getProfileSuccess(agencyMock.data, leaderMock.data));
    } else {
      let token = yield select(makeSelectToken());

      if (token) {
        const dataAgency = { type: PROFILE_TYPE.agency };
        const dataLeader = { type: PROFILE_TYPE.leader };
        let config = null;
        if (token) config = { baseURL: BASE_API };

        const [agency, leader] = yield all([
          call(
            request,
            '/presenter-kit/profiles',
            'get',
            dataAgency,
            token,
            config
          ),
          call(
            request,
            '/presenter-kit/profiles',
            'get',
            dataLeader,
            token,
            config
          ),
        ]);

        if (
          agency.status === 200 &&
          agency.data.data &&
          leader.status === 200 &&
          leader.data.data
        ) {
          yield put(getProfileSuccess(agency.data.data, leader.data.data));
        } else {
          yield put(getProfileError('Failed to fetch agency & leader profile'));
        }
      } else {
        yield put(getProfileError('Token not provided'));
      }
    }
  } catch (err) {
    const { response } = err;
    yield call(unauthorizedHandler, response);
    yield put(
      getProfileError(
        getResponseMessage(err, 'Failed to fetch agency & leader profile')
      )
    );
  }
}

export function* getThumbnails() {
  try {
    let token = yield select(makeSelectToken());

    if (token) {
      let config = null;
      if (token) config = { baseURL: BASE_API };

      const url = '/candidate/presenter-kit/thumbnails';
      const thumbnail = yield call(request, url, 'get', null, token, config);

      if (thumbnail.status === 200 && thumbnail.data.data) {
        yield put(getProfileThumbnailsSuccess(thumbnail.data.data));
      } else {
        yield put(
          getProfileThumbnailsError('Failed to fetch profile thubmnails')
        );
      }
    } else {
      yield put(getProfileThumbnailsError('Token not provided'));
    }
  } catch (err) {
    const { response } = err;
    yield call(unauthorizedHandler, response);
    yield put(getProfileThumbnailsError('Failed to fetch profile thubmnails'));
  }
}

export default function* rootSaga() {
  yield takeLatest(GET_USER_INFO_REQUEST, getUserInfo);
  yield takeLatest(GET_PROFILE_REQUEST, getProfile);
  yield takeLatest(GET_PROFILE_THUMBNAILS_REQUEST, getThumbnails);
}
