import { call, put, select, takeEvery, takeLatest } from 'redux-saga/effects';
import {
  proceedEducationBackgroundError,
  proceedEducationBackgroundSuccess,
  setEducationBackgroundData,
  setEvaluationData,
  setIsInitCompleted,
} from './action';
import {
  DELETE_EDUCATIONBACKGROUND_REQUEST,
  GET_EDUCATIONBACKGROUND_REQUEST,
  SAVE_EDUCATIONBACKGROUND_REQUEST,
} from './actionTypes';
import { callRequestWithToken, unauthorizedHandler } from '@utils/request';
import {
  deleteEducationBackgroundUrl,
  getEducationBackgroundUrl,
  saveEducationBackgroundUrl,
} from '@utils/apis';
import { setEmploymentGapData } from '../workExperiEmploymentHistory/action';
import { makeSelectRecruitmentProcessIds } from '@redux/fscApplication/eobCommonInfo/selectors';
import {
  makeSelectEducationBackgroundData,
  makeSelectIsInitCompleted,
} from './selectors';

export function* getEducationBackgroundAction({ data }) {
  try {
    const params = {
      userId: data.prospectId,
      recruitmentProcessId: data.id,
    };

    const res = yield callRequestWithToken({
      method: 'post',
      url: getEducationBackgroundUrl,
      params,
    });

    if (res.data.success.status) {
      res?.data?.data?.result?.personalInfoEducationBackground?.personalInfoEducationBackgroundItem?.map(
        (item) => {
          item.fileList = [];
          item.saveCount = 0;
        }
      );
      yield put(
        setEducationBackgroundData(
          res.data.data?.result?.personalInfoEducationBackground
            ?.personalInfoEducationBackgroundItem ?? []
        )
      );
      yield put(setEvaluationData(res.data.data.result.assessmentEducation));
      const isInitCompleted = yield select(makeSelectIsInitCompleted());
      if (!isInitCompleted) {
        yield put(setIsInitCompleted(true));
      }
      yield put(proceedEducationBackgroundSuccess());
    }
  } catch (err) {
    const { response } = err;
    yield call(unauthorizedHandler, response);
    yield put(
      proceedEducationBackgroundError(err.response?.data?.success?.message)
    );
  }
}

export function* saveEducationBackgroundAction({ data, index, setIsLoading }) {
  try {
    const recruitmentProcessIds = yield select(
      makeSelectRecruitmentProcessIds()
    );
    let params = {
      userId: recruitmentProcessIds.get('prospectId'),
      recruitmentProcessId: recruitmentProcessIds.get('id'),
      personalInfoEducationBackgroundItem: data[index],
    };

    data[index].saveCount++;
    yield put(setEducationBackgroundData(data));

    if (!data[index].id && data[index].saveCount != 1) {
      return;
    }

    // if there is no id, need loading immediately
    if (!data[index].id) {
      setIsLoading && setIsLoading({ isImmediate: true, isLoading: true });
    } else {
      setIsLoading && setIsLoading(true);
    }

    const res = yield callRequestWithToken({
      method: 'post',
      url: saveEducationBackgroundUrl,
      params,
    });

    if (res.data.success.status) {
      if (!data[index].id) {
        const eduBackgroundData = yield select(
          makeSelectEducationBackgroundData()
        );
        const copyData = eduBackgroundData.toJS();
        copyData[index].id = res.data.data.result.educationBackgroundItemId;
        yield put(setEducationBackgroundData(copyData));

        // resave the latest data with ID
        if (data[index].saveCount != copyData[index].saveCount) {
          params = {
            userId: recruitmentProcessIds.get('prospectId'),
            recruitmentProcessId: recruitmentProcessIds.get('id'),
            personalInfoEducationBackgroundItem: copyData[index],
          };
          const resaveRes = yield callRequestWithToken({
            method: 'post',
            url: saveEducationBackgroundUrl,
            params,
          });
          if (resaveRes.data.success.status) {
            yield put(
              setEmploymentGapData(resaveRes.data.data.result.employmentGapItem)
            );
          } else {
            yield put(
              proceedEducationBackgroundError(resaveRes.data?.success?.message)
            );
          }
          setIsLoading && setIsLoading(false);
        } else {
          yield put(
            setEmploymentGapData(res.data.data.result.employmentGapItem)
          );
          setIsLoading && setIsLoading(false);
        }
      } else {
        setIsLoading && setIsLoading(false);
        if (data[index].id === res.data.data.result.educationBackgroundItemId) {
          yield put(
            setEmploymentGapData(res.data.data.result.employmentGapItem)
          );
        } else {
          yield put(
            proceedEducationBackgroundError(
              'duplicate record in DB for the record in index ' + index
            )
          );
        }
      }
    } else {
      setIsLoading && setIsLoading(false);
      yield put(proceedEducationBackgroundError(res.data?.success?.message));
    }
  } catch (err) {
    const { response } = err;
    yield call(unauthorizedHandler, response);
    setIsLoading && setIsLoading(false);
    yield put(
      proceedEducationBackgroundError(err.response?.data?.success?.message)
    );
  }
}

export function* deleteEducationBackgroundAction({
  data,
  index,
  setIsLoading,
}) {
  try {
    const recruitmentProcessIds = yield select(
      makeSelectRecruitmentProcessIds()
    );
    const params = {
      userId: recruitmentProcessIds.get('prospectId'),
      recruitmentProcessId: recruitmentProcessIds.get('id'),
      personalInfoEducationBackgroundItem: data[index],
    };
    setIsLoading && setIsLoading(true);
    const res = yield callRequestWithToken({
      method: 'post',
      url: deleteEducationBackgroundUrl,
      params,
    });
    setIsLoading && setIsLoading(false);
    if (res.data.success.status) {
      yield put(proceedEducationBackgroundSuccess());
      yield put(setEmploymentGapData(res.data.data.employmentGapItem));
    }
  } catch (err) {
    const { response } = err;
    yield call(unauthorizedHandler, response);
    setIsLoading && setIsLoading(false);
    yield put(
      proceedEducationBackgroundError(err.response?.data?.success?.message)
    );
  }
}

export default function* rootSaga() {
  yield takeLatest(
    GET_EDUCATIONBACKGROUND_REQUEST,
    getEducationBackgroundAction
  );
  yield takeEvery(
    SAVE_EDUCATIONBACKGROUND_REQUEST,
    saveEducationBackgroundAction
  );
  yield takeLatest(
    DELETE_EDUCATIONBACKGROUND_REQUEST,
    deleteEducationBackgroundAction
  );
}
