import { ApplicationInsights } from '@microsoft/applicationinsights-web';

let appInsights = null;

if (process.env.REACT_APP_APP_INSIGHTS) {
  appInsights = new ApplicationInsights({
    config: {
      connectionString: process.env.REACT_APP_APP_INSIGHTS,
    },
  });

  appInsights.addTelemetryInitializer((envolope) => {
    envolope.tags['ai.cloud.role'] = process.env.REACT_APP_NAME;
  });

  appInsights.addDependencyInitializer((item) => {
    // Dependencies filterization.
    return !(
      `${item.item.name}`.includes(process.env.REACT_APP_APM_ELK_URL) ||
      `${item.item.name}`.includes('https://dpm.demdex.net')
    );
  });

  appInsights.loadAppInsights();
}

export default appInsights;
