import {
  GET_DRAFT_DATA_REQUEST,
  GET_DRAFT_DATA_ERROR,
  GET_DRAFT_DATA_SUCCESS,
  SAVE_DRAFT_DATA_REQUEST,
  SAVE_DRAFT_DATA_SUCCESS,
  SAVE_DRAFT_DATA_ERROR,
  SUBMIT_REEF_DATA_REQUEST,
  SUBMIT_REEF_DATA_REQUEST_SUCCESS,
  SUBMIT_REEF_DATA_REQUEST_ERROR,
} from './actionTypes';

export const getDraftData = () => ({
  type: GET_DRAFT_DATA_REQUEST,
});

export const getDraftDataSuccess = (reef, countries, educ, institution) => ({
  type: GET_DRAFT_DATA_SUCCESS,
  reef,
  countries,
  educ,
  institution,
});

export const getDraftDataError = (message) => ({
  type: GET_DRAFT_DATA_ERROR,
  message,
});

export const saveDraft = (data) => ({
  type: SAVE_DRAFT_DATA_REQUEST,
  data,
});

export const saveDraftSuccess = (message) => ({
  type: SAVE_DRAFT_DATA_SUCCESS,
  message,
});

export const saveDraftError = (message) => ({
  type: SAVE_DRAFT_DATA_ERROR,
  message,
});

export const submitForm = (data) => ({
  type: SUBMIT_REEF_DATA_REQUEST,
  data,
});

export const submitFormSuccess = (message) => ({
  type: SUBMIT_REEF_DATA_REQUEST_SUCCESS,
  message,
});

export const submitFormError = (message) => ({
  type: SUBMIT_REEF_DATA_REQUEST_ERROR,
  message,
});
