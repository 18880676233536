export const GET_UPCOMING_DATA_REQUEST =
  'irecruit/events/GET_UPCOMING_DATA_REQUEST';
export const GET_UPCOMING_DATA_SUCCESS =
  'irecruit/events/GET_UPCOMING_DATA_SUCCESS';
export const GET_UPCOMING_DATA_ERROR =
  'irecruit/events/GET_UPCOMING_DATA_ERROR';

export const GET_HAPPENING_DATA_REQUEST =
  'irecruit/events/GET_HAPPENING_DATA_REQUEST';
export const GET_HAPPENING_DATA_SUCCESS =
  'irecruit/events/GET_HAPPENING_DATA_SUCCESS';
export const GET_HAPPENING_DATA_ERROR =
  'irecruit/events/GET_HAPPENING_DATA_ERROR';

export const ACCEPT_EVENT_REQUEST = 'irecruit/events/ACCEPT_EVENT_REQUEST';
export const ACCEPT_EVENT_SUCCESS = 'irecruit/events/ACCEPT_EVENT_SUCCESS';
export const ACCEPT_EVENT_ERROR = 'irecruit/events/ACCEPT_EVENT_ERROR';

export const DECLINE_EVENT_REQUEST = 'irecruit/events/DECLINE_EVENT_REQUEST';
export const DECLINE_EVENT_SUCCESS = 'irecruit/events/DECLINE_EVENT_SUCCESS';
export const DECLINE_EVENT_ERROR = 'irecruit/events/DECLINE_EVENT_ERROR';
