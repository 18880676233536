import {
  VALIDATE_CONSENT_DATA_REQUEST,
  VALIDATE_CONSENT_DATA_SUCCESS,
  VALIDATE_CONSENT_DATA_ERROR,
  SEND_CONSENT_DATA_REQUEST,
  SEND_CONSENT_DATA_SUCCESS,
  SEND_CONSENT_DATA_ERROR,
} from './actionType';

export const validateConsentToken = (token) => ({
  type: VALIDATE_CONSENT_DATA_REQUEST,
  i: token,
});
export const validateConsentDataSuccess = (consent = {}) => ({
  type: VALIDATE_CONSENT_DATA_SUCCESS,
  consent,
});
export const validateConsentDataError = (error) => ({
  type: VALIDATE_CONSENT_DATA_ERROR,
  error,
});
export const sendConsentResponse = (body) => ({
  type: SEND_CONSENT_DATA_REQUEST,
  body,
});
export const sendConsentDataSuccess = (message, consent) => ({
  type: SEND_CONSENT_DATA_SUCCESS,
  message,
  consent,
});
export const sendConsentDataError = (error) => ({
  type: SEND_CONSENT_DATA_ERROR,
  error,
});
