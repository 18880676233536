import { call, put, takeLatest, select } from 'redux-saga/effects';
import request, { unauthorizedHandler } from '@utils/request';
import { makeSelectToken } from '@redux/app/selectors';
import {
  GET_MYINFO_INITIAL_DATA_REQUEST,
  PROCEED_MYINFO_REQUEST,
} from './actionTypes';
import {
  getMyinfoInitialDataSuccess,
  getMyinfoInitialDataError,
  proceedMyinfoSuccess,
  proceedMyinfoError,
} from './actions';
import { setUserData } from '../app/actions';

export function* getMyInfoInitialDataAction({ data }) {
  try {
    let token = yield select(makeSelectToken());

    if (token) {
      const res = yield call(
        request,
        `/my-info/profile/?code=${data}`,
        'get',
        null,
        token
      );
      if (res.data.success.status) {
        const user = yield call(request, '/candidate', 'get', null, token);
        yield put(getMyinfoInitialDataSuccess(res.data.data));
        if (user.data.success.status) {
          yield put(setUserData(user.data.data));
        } else {
          yield put(setUserData({}));
        }
      } else {
        yield put(
          getMyinfoInitialDataError('Token not provide to get MyInfo data')
        );
      }
    }
  } catch (err) {
    const { response } = err;
    yield call(unauthorizedHandler, response);
    yield put(getMyinfoInitialDataError(err.response?.data?.success?.message));
  }
}

export function* proceedMyinfoAction({ data }) {
  try {
    let token = yield select(makeSelectToken());

    if (token) {
      const res = yield call(request, '/my-info/store', 'post', data, token);
      if (res.data.success.status) {
        yield put(proceedMyinfoSuccess('Successfully proceed Myinfo data'));
      } else {
        yield put(proceedMyinfoError('Token not provide to get MyInfo data'));
      }
    }
  } catch (err) {
    const { response } = err;
    yield call(unauthorizedHandler, response);
    yield put(proceedMyinfoError(err.response?.data?.success?.message));
  }
}

export default function* rootSaga() {
  yield takeLatest(GET_MYINFO_INITIAL_DATA_REQUEST, getMyInfoInitialDataAction);
  yield takeLatest(PROCEED_MYINFO_REQUEST, proceedMyinfoAction);
}
