export const GET_SIGNING_URL_REQUEST =
  'irecruit/signEps/GET_SIGNING_URL_REQUEST';
export const GET_SIGNING_URL_SUCCESS =
  'irecruit/signEps/GET_SIGNING_URL_SUCCESS';
export const GET_SIGNING_URL_ERROR = 'irecruit/signEps/GET_SIGNING_URL_ERROR';
export const POST_SIGNER_STATUS_REQUEST =
  'irecruit/interview/POST_SIGNER_STATUS_REQUEST';
export const POST_SIGNER_STATUS_SUCCESS =
  'irecruit/interview/POST_SIGNER_STATUS_SUCCESS';
export const POST_SIGNER_STATUS_ERROR =
  'irecruit/interview/POST_SIGNER_STATUS_ERROR';
