export const SET_LOGIN_DATA = 'irecruit/app/SET_LOGIN_DATA';
export const SET_TOKEN = 'irecruit/app/SET_TOKEN';
export const SET_USER_DATA = 'irecruit/app/SET_USER_DATA';
export const RESET_STATE = 'irecruit/app/RESET_STATE';

export const AFTER_LOGIN_REQUEST = 'irecruit/app/AFTER_LOGIN_REQUEST';
export const AFTER_LOGIN_SUCCESS = 'irecruit/app/AFTER_LOGIN_SUCCESS';
export const AFTER_LOGIN_ERROR = 'irecruit/app/AFTER_LOGIN_ERROR';

export const GET_MAINTENANCE_ALERT_REQUEST =
  'irecruit/app/GET_MAINTENANCE_ALERT_REQUEST';
export const GET_MAINTENANCE_ALERT_SUCCESS =
  'irecruit/app/GET_MAINTENANCE_ALERT_SUCCESS';
export const GET_MAINTENANCE_ALERT_ERROR =
  'irecruit/app/GET_MAINTENANCE_ALERT_ERROR';
export const GET_MAINTENANCE_STATUS = 'irecruit/app/GET_MAINTENANCE_STATUS';
export const SET_UNAUTHORIZED = 'irecruit/app/SET_UNAUTHORIZED';
